import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { getDepositRecipes, getERC20 } from '../helpers/contracts';

export const useZapMint = () => {
  const [{ provider, chain_id }] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const zapMint = async (token0Address, token1Address, amountDesired, tokenAddress, tickLower, tickUpper, fee) => {
    try {
      if (
        tokenAddress.toLowerCase() !== token0Address.toLowerCase() &&
        tokenAddress.toLowerCase() !== token1Address.toLowerCase()
      )
        throw new Error('ZapIn token must be one of the two of the pool');

      const signer = await provider.getSigner();
      const DepositRecipes = getDepositRecipes(chain_id, signer);
      const isToken0In = token0Address.toLowerCase() === tokenAddress.toLowerCase();
      const decimals = await getERC20(tokenAddress, signer).decimals();
      const amountIn = ('0x' + (amountDesired * 10 ** decimals).toString(16)).split('.')[0];
      const feeInBasePoints = Number(fee) * 10000;

      const gasLimitEstimate = await DepositRecipes.estimateGas.zapInUniNft(
        token0Address,
        token1Address,
        isToken0In,
        amountIn,
        tickLower,
        tickUpper,
        feeInBasePoints,
      );

      setWalletActionRequired(true);
      const tx = await DepositRecipes.zapInUniNft(
        token0Address,
        token1Address,
        isToken0In,
        amountIn,
        tickLower,
        tickUpper,
        feeInBasePoints,
        { gasLimit: gasLimitEstimate.add(150000) },
      );
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Zapping your position');
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during zap deposit');
      console.error(err?.message);
    }
  };

  return { zapMint };
};
