import { useSharedState } from '../context/store';
import { getERC20 } from '../helpers/contracts';
import addresses from '../contracts/addresses';

export const useToken = () => {
  const [{ provider, chain_id }] = useSharedState();

  const getTokenInfo = async (tokenAddress) => {
    const signer = await provider.getSigner();
    const token = getERC20(tokenAddress, signer);
    const tokenDecimals = await token.decimals();
    const tokenSymbol = (await token.symbol()).toLowerCase();
    return { tokenDecimals, tokenSymbol, tokenAddress };
  };

  const getZapTokensInfo = async () => {
    try {
      const zapTokens = [];
      for (let zapTokenAddress of addresses[chain_id].zapTokens) zapTokens.push(await getTokenInfo(zapTokenAddress));
      return zapTokens;
    } catch (err) {
      console.error(err?.message);
    }
  };

  return { getZapTokensInfo };
};
