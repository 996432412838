import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSharedState } from '../../context/store';
import { usePositionManager } from '../../hooks/usePositionManager';
import { checkPositionManagerExists } from '../../helpers/contracts';
import { XIcon } from '@heroicons/react/outline';

export default function CreatePM() {
  const [{ account, chain_id, provider }] = useSharedState();
  const [open, setOpen] = useState(false);
  const { createPositionManager } = usePositionManager();

  useEffect(() => {
    (async () => {
      if (provider) {
        const signer = await provider.getSigner();
        if ((await checkPositionManagerExists(account, chain_id, signer)) === null) {
          setOpen(true);
        }
      }
    })();
  }, [account, chain_id, provider]);

  const handleCreate = async () => {
    await createPositionManager();
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0'>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative inline-block bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XIcon className='h-7 w-7' aria-hidden='true' />
                  </button>
                </div>
                <div>
                  <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-second'>
                    <div className='h-6 w-6 text-green-600' aria-hidden='true' /> {/* icon */}
                  </div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='work-sans-normal text-3xl leading-6'>
                      Create your Smart Vault
                    </Dialog.Title>
                    <div className='mt-3'>
                      <p className='text-sm text-gray-500 azeret'>
                        To get started with Orbit, you need to create your Smart Vault. This is done only <b>once</b>{' '}
                        and will be under your control.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <button
                    type='button'
                    className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-first text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-lg'
                    onClick={handleCreate}
                  >
                    Create now
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
