import { BigNumber, ethers } from 'ethers';
import { getPositionManager, getNonFungiblePositionManager, getERC20 } from '../helpers/contracts';
import { getAmountsForLiquidity, getPriceByTick, getV3PoolAddress, UniNftExplorerUrl } from '../utils/utils';
import { getPositionMetrics } from '../api/getPositionMetrics';
import { useSharedState } from '../context/store';
import { useTokenValue } from './useTokenValue';
import { usePools } from './usePools';
import addresses from '../contracts/addresses';
import actions from '../context/actions';

const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);
const parsePrice = (price) => {
  if (price < 1) return Number(price).toPrecision(3);
  if (price < 100) return Number(price).toFixed(3);
  return Number(price).toFixed(2);
};

export const usePositions = () => {
  const [{ account, provider, chain_id }, dispatch] = useSharedState();
  const { getEthUsdcValue } = useTokenValue();
  const { getUniPool } = usePools();

  const getAllPositions = async () => {
    try {
      dispatch({ type: actions.LOADING_STATE, payload: { loading_state: 'positions' } });
      dispatch({ type: actions.UPDATE_POSITIONS, payload: [] });

      const signer = await provider.getSigner();
      const NonfungiblePositionManager = getNonFungiblePositionManager(chain_id, signer);
      const NonfungiblePositionManagerWithoutSigner = getNonFungiblePositionManager(chain_id, provider);
      const PositionManager = await getPositionManager(account, chain_id, signer);

      if (!PositionManager) {
        dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: [] } });
        dispatch({ type: actions.LOADING_STATE, payload: { loading_state: false } });
        return [];
      }

      const positions = [];
      const positionsBalance = (await NonfungiblePositionManager.balanceOf(PositionManager.address)).toNumber();

      for (var i = 0; i < positionsBalance; i++) {
        try {
          const id = (await NonfungiblePositionManager.tokenOfOwnerByIndex(PositionManager.address, i)).toNumber();
          const position = await NonfungiblePositionManager.positions(id);

          const token0 = getERC20(position.token0, signer);
          const token1 = getERC20(position.token1, signer);
          const token0Decimals = await token0.decimals();
          const token1Decimals = await token1.decimals();
          const token0Name = (await token0.symbol()).toLowerCase();
          const token1Name = (await token1.symbol()).toLowerCase();

          const moduleState = {}; // true/false if the module is enabled or not
          const moduleData = {}; // number representing the fee threshold or distance from range

          for (const moduleName of Object.keys(addresses[chain_id].modules)) {
            const { isActive, data } = await PositionManager.getModuleInfo(id, addresses[chain_id].modules[moduleName]);
            moduleData[moduleName] = await ethers.utils.defaultAbiCoder.decode([`uint256`], data)[0].toNumber();
            moduleState[moduleName] = isActive;
          }

          const explorerLink = `${UniNftExplorerUrl}${Number(id)}?chain=${addresses[chain_id].ChainName}`;
          const diffDecimals = token0Decimals - token1Decimals;

          const lowerRange = `${parsePrice(getPriceByTick(position.tickLower, true, diffDecimals), 5)}`;
          const upperRange = `${parsePrice(getPriceByTick(position.tickUpper, true, diffDecimals), 5)}`;
          const positionRange = `${lowerRange}-${upperRange}`;

          let isOnAave = false;
          let aaveData = { amountToAave: null, tokenToAave: null };

          try {
            const [amountToAave, tokenToAave] = await PositionManager.getAaveDataFromTokenId(id);
            isOnAave = amountToAave.eq(0) ? false : true;
            aaveData = { amountToAave, tokenToAave };
          } catch (err) {
            console.log(`SDK: ${id} not on Aave`);
          }

          // Hide positions with 0 liquidity that aren't on Aave
          // (i.e. positions that have been fully withdrawn while they were on Aave)
          if (!isOnAave && Number(position.liquidity) === 0) continue;

          const poolAddress = getV3PoolAddress(
            chain_id,
            token0.address,
            token1.address,
            token0Decimals,
            token1Decimals,
            position.fee,
          );

          let token0Value, token1Value, token0Price, token1Price;

          try {
            const uniPool = await getUniPool(poolAddress);
            const ethUsdPrice = await getEthUsdcValue();
            const { token0EthValue, token1EthValue, tick } = uniPool;

            const { amount0: token0Amount, amount1: token1Amount } = getAmountsForLiquidity(
              tick,
              position.tickLower,
              position.tickUpper,
              position.liquidity,
            );

            token0Price = token0EthValue * ethUsdPrice;
            token1Price = token1EthValue * ethUsdPrice;

            if (typeof token0Amount === 'number') {
              const token0AmountWithDecimals = Number(
                ethers.utils.formatUnits(BigNumber.from(token0Amount.toFixed(0)), token0Decimals),
              );
              token0Value = token0AmountWithDecimals * token0Price;
            } else token0Value = 0;

            if (typeof token1Amount === 'number') {
              const token1AmountWithDecimals = Number(
                ethers.utils.formatUnits(BigNumber.from(token1Amount.toFixed(0)), token1Decimals),
              );
              token1Value = token1AmountWithDecimals * token1Price;
            } else token1Value = 0;
          } catch (err) {
            console.error(err);
          }

          positions.push({
            id: Number(id),
            isOnAave: isOnAave,
            aaveData: aaveData,
            link: explorerLink,
            token0: token0Name,
            token1: token1Name,
            range: positionRange,
            module_data: moduleData,
            module_states: moduleState,
            token0Decimals: token0Decimals,
            token1Decimals: token1Decimals,
            token0Address: position.token0,
            token1Address: position.token1,
            fee: Number(position.fee) / 10000,
            liquidity: Number(position.liquidity),
            tickLower: Number(position.tickLower),
            tickUpper: Number(position.tickUpper),
            token0Price: token0Price,
            token1Price: token1Price,
            token0Value: token0Value,
            token1Value: token1Value,
            value: token0Value + token1Value,
          });

          dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions } });
        } catch (e) {
          continue;
        }
      }

      for (const id in positions) {
        if (positions[id].isOnAave) {
          try {
            const data = await getPositionMetrics(chain_id, positions[id].id);
            positions[id].value = data.position.marketValueUSD;
            dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions } });
            continue;
          } catch (e) {
            continue;
          }
        }

        const { token0Price, token1Price } = positions[id];

        try {
          const uncollectedFees = await NonfungiblePositionManagerWithoutSigner.callStatic.collect(
            {
              tokenId: positions[id].id,
              recipient: PositionManager.address,
              amount0Max: MAX_UINT128,
              amount1Max: MAX_UINT128,
            },
            { from: PositionManager.address },
          );

          const feesValue =
            ethers.utils.formatUnits(uncollectedFees.amount0) * token0Price +
            ethers.utils.formatUnits(uncollectedFees.amount1) * token1Price;

          positions[id].uncollectedFee = feesValue;

          dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions } });
        } catch (e) {
          continue;
        }
      }

      // Final dispatch is needed if we don't have any positions by the end of the loop
      if (positions?.length === 0) dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: [] } });

      dispatch({ type: actions.LOADING_STATE, payload: { loading_state: false } });
    } catch (err) {
      console.error(err?.message);
      return [];
    }
  };

  return { getAllPositions };
};
