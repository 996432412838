import { Menu } from '@headlessui/react';
import infoDropdownData from './infoDropdownData.js';

export default function MobileDropdownMenu({ open }) {
  return (
    <Menu as='div' className=''>
      {open && (
        <Menu.Items static className=''>
          {infoDropdownData.map((item, index) => (
            <div key={index + 1}>
              {item.mobile && (
                <div className={`px-3 py-1 ${item.border ? 'border-t-2 border-gray-palette' : ''}`} key={index}>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-second primary' : 'text-gray'
                        } group flex w-full items-center rounded-md pl-2 py-2 text-sm`}
                        onClick={() => window.open(item.link, '_blank')}
                      >
                        <img src={item.icon} alt='token' className={`mr-2 h-${item.height} w-10`} aria-hidden='true' />
                        <span className='text-xl uppercase primary work-sans-bold pl-1'>{item.name}</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )}
            </div>
          ))}
        </Menu.Items>
      )}
    </Menu>
  );
}
