const OrbitLogoSecond = (props) => (
  <svg width={36} height={35} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M33.469 23.204c.242.146.533 0 .63-.242.049-.194 0-.437-.194-.534l-4.414-2.862a6.544 6.544 0 0 1-.727 1.262l4.705 2.376ZM25.174 11.66l.242-9.119c0-.728-.533-1.31-1.212-1.31-.68 0-1.213.582-1.213 1.31l.243 9.119c.29-.049.63-.097.921-.097.388 0 .68.048 1.019.097ZM29.927 28.491a.67.67 0 0 0 1.019.097c.145-.194.194-.485.048-.679l-3.25-6.014c-.436.34-.873.582-1.406.824l3.59 5.772ZM18.674 15.783 2.91 14.619C1.358 14.522 0 15.734 0 17.287v.097c0 1.552 1.358 2.813 2.91 2.667l15.764-1.164c-.145-.485-.194-1.018-.194-1.552s.049-1.067.194-1.552ZM34.73 16.995l-4.802-.34c.048.243.048.486.048.728 0 .243 0 .485-.048.68l4.802-.34c.194 0 .34-.146.34-.34-.05-.242-.195-.388-.34-.388ZM16.297.746a1.401 1.401 0 0 0-1.455-.728h-.048c-1.019.146-1.504 1.31-.97 2.183l6.645 10.817a5.496 5.496 0 0 1 1.94-1.116L16.297.746ZM33.905 12.242c.194-.097.242-.34.194-.534-.097-.242-.388-.388-.63-.242l-4.657 2.377c.29.388.533.824.727 1.26l4.366-2.861ZM30.994 6.809c.097-.243.097-.485-.048-.68-.291-.339-.776-.29-1.019.098l-3.59 5.82c.486.194.971.486 1.407.825l3.25-6.063ZM13.824 32.468c-.534.874 0 1.99.97 2.183h.049a1.401 1.401 0 0 0 1.455-.727l6.014-11.157c-.727-.242-1.358-.63-1.94-1.115l-6.548 10.816ZM5.19 25.872a2.082 2.082 0 0 0-.582 3.25l.048.048c.68.727 1.795.873 2.668.34l12.757-8.246c-.582-.63-1.067-1.407-1.31-2.28L5.19 25.872ZM23.283 23.01l-.243 9.119c0 .727.534 1.31 1.213 1.31s1.212-.583 1.212-1.31l-.242-9.12c-.291.05-.63.098-.97.098-.34-.049-.68-.049-.97-.097ZM7.276 5.111a2.084 2.084 0 0 0-2.668.34l-.048.048a2.082 2.082 0 0 0 .582 3.25l13.532 6.936c.243-.873.728-1.649 1.31-2.28L7.276 5.112Z'
      fill='#44FFD1'
    />
  </svg>
);

export default OrbitLogoSecond;
