import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import OrbitMobileLogo from '../icons/OrbitMobileLogo';

const linkToTitle = {
  'provide-liquidity': 'provide liquidity',
  dashboard: 'dashboard',
  analytics: 'analytics',
  import: 'uni v3 import',
  withdraw: 'withdraw',
};

export default function Navbar(props) {
  const { currentPage } = props;
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <>
      {/* DESKTOP NAVBAR */}
      <div className='row mt-5 bg-primary rounded-xl mx-auto relative d-none d-md-block'>
        <div className='md:col-3'></div>
        <div className='col-12 md:col-6 flex items-center'>
          <div className='grid gap-4 md:flex mx-auto'>
            {Object.keys(linkToTitle).map((link) => (
              <Link
                key={link}
                className={`${
                  currentPage === link ? 'selected-secondary' : ''
                } work-sans-bold text-light-gray uppercase md:py-6 xs:py-3 mx-4 text-xl text-center underline-secondary`}
                to={`/${link}`}
                rel='noopener noreferrer'
              >
                {linkToTitle[link]}
              </Link>
            ))}
          </div>
        </div>
        <div className='md:col-3'></div>
      </div>
      {/* MOBILE NAVBAR */}
      <div className=' d-md-none d-block row border-b-2 pb-3 border-primary'>
        <div className='col-12 px-4'>
          <div className='flex items-center justify-between'>
            <Link to='/'>
              <OrbitMobileLogo className='align-middle block w-full d-block d-lg-none' />
            </Link>
            <nav className='d-block d-md-none'>
              <section className='flex'>
                <div className='' onClick={() => setIsNavOpen((prev) => !prev)}>
                  <img src={require('../../images/burger.svg').default} alt='orbit burger menu' />
                </div>

                <div
                  className={
                    isNavOpen
                      ? 'showMenuNav background-light-gray col-12 d-block px-4 border-b-2 border-primary'
                      : 'd-none'
                  }
                >
                  <div className='flex items-center justify-between'>
                    <Link to='/'>
                      <OrbitMobileLogo className='align-middle block w-full d-block d-lg-none' />
                    </Link>
                    <div className='' onClick={() => setIsNavOpen(false)}>
                      <img src={require('../../images/cross.svg').default} alt='orbit close menu' />
                    </div>
                  </div>
                  <div className='flex flex-col items-center justify-between primary py-2'>
                    {Object.keys(linkToTitle).map((link) => (
                      <Link
                        key={link}
                        className={'work-sans-bold uppercase pb-2 pt-4 text-lg text-center no-underline hov-primary'}
                        to={`/${link}`}
                        rel='noopener noreferrer'
                        onClick={() => setIsNavOpen(false)}
                      >
                        {linkToTitle[link]}
                      </Link>
                    ))}
                  </div>
                </div>
              </section>
            </nav>
          </div>
        </div>
      </div>
      <p className='primary uppercase d-block d-md-none work-sans-bold text-center pt-4'>{linkToTitle[currentPage]}</p>
    </>
  );
}
