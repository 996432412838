import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSharedState } from '../../context/store';
import { useWallet } from '../../hooks/useWallet';
import { useWalletConnect } from '../../hooks/useWalletConnect';
import { shortenAddress } from '../../utils/utils';

import metamaskLogo from '../../images/metamask.svg';
import walletConnectLogo from '../../images/wallet-connect.svg';

export default function ConnectorModal() {
  const [showModal, setShowModal] = useState(false);
  const [{ account }] = useSharedState();
  const { loginWallet, logoutWallet } = useWallet();
  const { connect, killSession } = useWalletConnect();

  return (
    <>
      {!account ? (
        <>
          <button className='hidden md:inline-block rounded-xl btn-main text-md' onClick={() => setShowModal(true)}>
            CONNECT WALLET
          </button>
          <button
            className='inline-block md:hidden w-full rounded-xl btn-main text-base azeret-semibold h-12'
            onClick={() => setShowModal(true)}
          >
            CONNECT WALLET
          </button>

          {showModal ? (
            <>
              <Transition.Root show={showModal} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={() => setShowModal(false)}>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                  </Transition.Child>

                  <div className='fixed z-10 inset-0 overflow-y-auto'>
                    <div className='flex items-center justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0'>
                      {/* This element is to trick the browser into centering the modal contents. */}
                      <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
                        &#8203;
                      </span>
                      <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                      >
                        <Dialog.Panel className='relative inline-block bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                          <div>
                            <div className='mt-2 text-center sm:mt-5'>
                              <Dialog.Title as='h3' className='work-sans-bold text-3xl primary leading-6'>
                                Connect your wallet
                              </Dialog.Title>
                              <div className='mt-2'>
                                <p className='d-none d-md-block text-lg text-gray azeret w-2/3 mx-auto'>
                                  Connect your wallet to access your positions
                                </p>
                                <p className='d-block d-md-none text-lg text-gray azeret w-full mx-auto mb-5'>
                                  Connect your wallet to access your positions
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='mt-4 sm:mt-4'>
                            <button
                              type='button'
                              className='grid grid-cols-12 lg:w-2/3 mx-auto outline-none rounded-2xl text-sm btn-main focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-lg'
                              onClick={loginWallet}
                            >
                              <span className='flex col-span-8 justify-left my-auto'>METAMASK</span>
                              <div className='col-span-2'></div>
                              <img
                                src={metamaskLogo}
                                alt='wallet connect'
                                className='ml-2 col-span-2 py-1'
                                width='30'
                              />
                            </button>
                          </div>
                          <div className='mt-3 sm:mt-3'>
                            <button
                              type='button'
                              className='grid grid-cols-12 lg:w-2/3 mx-auto outline-none rounded-2xl text-sm btn-main focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-lg'
                              onClick={() => {
                                connect();
                              }}
                            >
                              <span className='flex col-span-8 justify-left my-auto'>WALLET CONNECT</span>
                              <div className='col-span-2'></div>
                              <img
                                src={walletConnectLogo}
                                alt='wallet connect'
                                className='ml-2 col-span-2 py-2'
                                width='30'
                              />
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          ) : null}
        </>
      ) : (
        <>
          <button
            onClick={() => {
              logoutWallet();
              killSession();
            }}
            className='hidden md:inline-block rounded-xl btn-main px-3 text-md'
          >
            {shortenAddress(account)}
          </button>
          <button
            onClick={() => {
              logoutWallet();
              killSession();
            }}
            className='inline-block md:hidden w-full rounded-xl btn-main px-3 text-md h-12'
          >
            {shortenAddress(account)}
          </button>
        </>
      )}
    </>
  );
}
