import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSharedState } from '../../../context/store';
import { usePools } from '../../../hooks/usePools';
import PoolCard from './PoolCard';

function Items({ currentItems }) {
  const [{ chain_id, provider }] = useSharedState();
  const [selected, setSelected] = useState(0);
  const { updateSelectedPool } = usePools();

  useEffect(() => {
    chain_id && provider && currentItems && updateSelectedPool(currentItems[selected]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setSelected(0);
    chain_id && provider && currentItems && updateSelectedPool(currentItems[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItems]);

  return (
    <div className='items'>
      {currentItems ? (
        <div
          id='box-scrollbar'
          className={`overflow-scroll scroll-smooth max-h-[40.67rem] 
          grid grid-cols-1 gap-4 pt-1 md:pr-2 pl-1 pb-1`}
        >
          {currentItems.map((pool, index) => (
            <PoolCard
              key={index}
              pool={pool}
              selected={selected === index ? true : false}
              onClick={() => setSelected(index)}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default function PoolsList({ itemsPerPage = 10, items }) {
  const [currentItems, setCurrentItems] = useState(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const pages = itemsPerPage !== 0 ? items.length / itemsPerPage : 0;

  const [pageCount, setPageCount] = useState(pages);

  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(items.slice(itemOffset, endOffset));

    if (itemsPerPage !== 0) {
      setPageCount(Math.ceil(items.length / itemsPerPage));
      setSelectedPage(itemOffset / itemsPerPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage]);

  useEffect(() => {
    setItemOffset(0);
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));

    if (itemsPerPage !== 0) {
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const goPreviousPage = () => {
    if (itemOffset > 0) {
      const newOffset = itemOffset - itemsPerPage;
      setItemOffset(newOffset);

      if (itemsPerPage !== 0 && itemsPerPage !== 1) {
        setSelectedPage(itemOffset / itemsPerPage - 1);
      }
    }
  };

  const goNextPage = () => {
    if (itemOffset + itemsPerPage < items.length) {
      const newOffset = itemOffset + itemsPerPage;
      setItemOffset(newOffset);
      setSelectedPage(itemOffset / itemsPerPage + 1);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);

    if (itemsPerPage !== 0) {
      setSelectedPage(newOffset / itemsPerPage);
    }
  };

  return (
    <>
      {currentItems && currentItems.length > 0 ? (
        <>
          <Items currentItems={currentItems} />
          <div className='row mx-1 bg-white px-3 rounded-xl mt-4 py-3 card-shadow'>
            <img
              src={require('../../../images/arrow-left.svg').default}
              alt='previous'
              onClick={() => goPreviousPage()}
              className='mr-auto hover:cursor-pointer'
            />
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={<img src={require('../../../images/arrow-left.svg').default} alt='previous' />}
              nextLabel={<img src={require('../../../images/arrow-right.svg').default} alt='next' />}
              pageClassName='page-item-custom rounded-xl mx-1 p-1'
              pageLinkClassName='page-link azeret-semibold text-base py-0 px-1 mx-1'
              previousClassName='d-none'
              nextClassName='d-none'
              previousLinkClassName='page-link'
              nextLinkClassName='page-link'
              forcePage={selectedPage}
              breakLabel='...'
              breakClassName='page-item-custom azeret-semibold text-base'
              breakLinkClassName='page-link py-0 px-1 mx-2'
              containerClassName='pagination-container items-center'
              activeClassName='selected'
              renderOnZeroPageCount={null}
            />
            <img
              src={require('../../../images/arrow-right.svg').default}
              alt='next'
              onClick={() => goNextPage()}
              className='ml-auto hover:cursor-pointer'
            />
          </div>
        </>
      ) : (
        <>
          <div className='w-full bg-white rounded-xl shadow-md p-[24px] my-4 justify-center'>
            <img
              src={require('../../../images/question-mark.svg').default}
              className='mx-auto mb-2'
              alt='question mark'
            />
            <p className='azeret text-gray-500 font-medium text-lg text-center'>
              No results: the pool might not exists or it may not be available.
            </p>
          </div>
        </>
      )}
    </>
  );
}
