import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import MoreSvg from '../../components/icons/MoreSvg.js';
import infoDropdownData from './infoDropdownData.js';
import '../dapp/home.scss';

export default function InfoDropdown() {
  return (
    <Menu
      as='div'
      className='grid place-content-center md:ml-3 no-outline azeret-md md:float-right relative w-mobile-100'
    >
      <Menu.Button className='no-outline h-12 md:h-10 btn-more rounded-xl'>
        <MoreSvg />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className='py-2 absolute z-10 right-0 md:right-1 top-0 md:top-11 w-64 origin-top-right rounded-md
         bg-white border-secondary border-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-1'
        >
          {infoDropdownData.map((item, index) => (
            <div key={index} className={`${item.border ? 'mt-2' : ''}`}>
              {item.desktop && (
                <div className={`px-1 ${item.border ? 'border-t border-color-gray py-2' : ''}`} key={index}>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-second primary' : 'text-gray'
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => window.open(item.link, '_blank')}
                      >
                        <img src={item.icon} alt='token' className='mr-2 h-5 w-5' aria-hidden='true' />
                        <span className='text-sm'>{item.name}</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
