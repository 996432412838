import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import Config from '../Config';

// Possible source: uniswapSubGraph, orbitSubGraph
const httpLink = (chain_id, source) => {
  if (!chain_id || !source) throw new Error('Missing chain_id or source');
  if (!Object.keys(Config[chain_id]).some((src) => src === source)) throw new Error('Invalid GraphQL source');

  return createHttpLink({
    uri: Config[chain_id][source],
  });
};

// This is the Apollo graphQL client config object
const Client = (chain_id, source) =>
  new ApolloClient({
    link: httpLink(chain_id, source),
    cache: new InMemoryCache(),
  });

export default Client;
