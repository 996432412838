import { gql } from '@apollo/client';
import ApolloClient from './ApolloClient';

const GET_POSITION_METRICS = (position) => {
  const queryString = `
  query positionMetrics {
    position(id: "${position}") {
      marketValueUSD,
      HODLValueUSD,
      feesGeneratedUSD
    }
    positionDailySnapshots(where: { position: "${position}"}) {
      id,
      date,
      marketValueUSD,
      HODLValueUSD,
      feesGeneratedUSD
    }
  }
  `;
  return queryString;
};

export const getPositionMetrics = async (chain_id, position) => {
  const source = 'orbitSubGraph';
  const result = await ApolloClient(chain_id, source).query({
    query: gql(GET_POSITION_METRICS(position)),
  });

  return result.data;
};
