import companyIcon from '../../images/company-purple.svg';
import contactsIcon from '../../images/contacts-purple.svg';
import docsIcon from '../../images/docs-purple.svg';
import discordIcon from '../../images/discord-purple.svg';
import twitterIcon from '../../images/twitter-purple.svg';
import withdrawIcon from '../../images/withdraw.svg';
import externalLinkIcon from '../../images/link.svg';

export const infoDropdownData = [
  {
    icon: docsIcon,
    link: 'https://fiveelementslabs.gitbook.io/orbit/',
    name: 'Docs',
    height: '6',
    border: false,
    mobile: true,
    desktop: true,
  },
  {
    icon: companyIcon,
    link: 'https://fiveelementslabs.com/',
    name: 'Five Elements Labs',
    height: '8',
    border: false,
    mobile: true,
    desktop: true,
  },
  {
    icon: contactsIcon,
    link: 'https://fiveelementslabs.com/',
    name: 'Contact us',
    height: '7',
    border: false,
    mobile: true,
    desktop: true,
  },
  {
    icon: twitterIcon,
    link: 'https://twitter.com/OrbitFi',
    name: 'Twitter',
    height: '7',
    border: false,
    mobile: true,
    desktop: true,
  },
  {
    icon: discordIcon,
    link: 'https://discord.gg/j8RgSsdFh9',
    name: 'Discord',
    height: '7',
    border: false,
    mobile: true,
    desktop: true,
  },
  {
    icon: docsIcon,
    link: 'https://byterocket.com/audit/orbit-defi',
    name: 'Audit',
    height: '7',
    border: false,
    mobile: true,
    desktop: true,
  },
  {
    icon: withdrawIcon,
    link: '/withdraw-erc20',
    name: 'Withdraw ERC20',
    height: '8',
    border: true,
    mobile: false,
    desktop: true,
  },
  {
    icon: externalLinkIcon,
    link: 'https://beta.orbitdefi.finance',
    name: 'Beta version',
    height: '7',
    border: false,
    mobile: true,
    desktop: true,
  },
];

export default infoDropdownData;
