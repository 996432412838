import { useSharedState } from '../context/store';
import networks from '../helpers/networks';
import actions from '../context/actions';

const DEFAULT_NOTIFICATION_TIMEOUT = 6000;
const MAX_NOTIFICATION_NUMBER = 5;

export const useNotifications = () => {
  const [{ notifications, chain_id }, dispatch] = useSharedState();

  const addNotification = (type, title, message) => {
    const id = `${Math.random().toString()}-${Date.now()}`;
    const notification = { id, type, title, message };
    dispatch({ type: actions.ADD_NOTIFICATION, payload: { notification } });
    return id;
  };

  const updateNotification = (id, type, title, message) => {
    const notification = { id, type, title, message };
    dispatch({ type: actions.UPDATE_NOTIFICATION, payload: { notification } });
    return id;
  };

  const removeNotification = (id) => {
    dispatch({ type: actions.REMOVE_NOTIFICATION, payload: { id } });
  };

  const notify = (type = 'warning', title = 'Warning', message = '', duration = DEFAULT_NOTIFICATION_TIMEOUT) => {
    if (notifications.length > MAX_NOTIFICATION_NUMBER) return null;
    const id = addNotification(type, title, message);
    setTimeout(() => removeNotification(id), duration);
  };

  const notifyLoadingAsyncTx = async (tx, title) => {
    const id = `${Math.random().toString()}-${Date.now()}`;
    const type = 'loading';
    const notification = { id, type, title };
    var success = false;

    dispatch({ type: actions.ADD_NOTIFICATION, payload: { notification } });

    tx.wait()
      .then((data) => {
        dispatch({ type: actions.REMOVE_NOTIFICATION, payload: { id: notification.id } });
        notify('success', 'Transaction confirmed.', ViewTxLink(data));
        success = true;
      })
      .catch((data) => {
        dispatch({ type: actions.REMOVE_NOTIFICATION, payload: { id: notification.id } });
        notify('error', 'Transaction failed.', ViewTxLink(data));
        success = false;
      });

    return success;
  };

  const setWalletActionRequired = (loading_state) => {
    dispatch({ type: actions.LOADING_STATE, payload: { loading_state } });
  };

  const ViewTxLink = (data) => {
    try {
      const { transactionHash } = data;
      const url = networks[chain_id].blockExplorerUrls[0] + 'tx/' + transactionHash;
      return (
        <a href={url} target='_blank' rel='noopener noreferrer' className='text-blue-600 work-sans-normal text-sm'>
          View on Block Explorer
        </a>
      );
    } catch (err) {
      console.error(err?.message);
    }
  };

  return {
    notify,
    notifyLoadingAsyncTx,
    setWalletActionRequired,
    addNotification,
    updateNotification,
    removeNotification,
  };
};
