const addresses = {
  137: {
    ChainName: 'polygon',
    ChainExplorerUrl: 'https://polygonscan.com',
    NonFungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
    PositionManagerFactory: '0x6B8Bc2BF3DCc979082E70D4A9D3854A1FF323dE7',
    DepositRecipes: '0x06aBC73d84eF76EF251342420A7b395531f1527E',
    WithdrawRecipes: '0xd77FAF24F2d7813d0EA04e9578cA6f486B3350c4',
    LendingPoolV2: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
    modules: {
      AutoCompoundModule: '0x67c02360Aa7DaadC80491B3e60b6802F550A76B7',
      IdleLiquidityModule: '0xba87D279Dcf584cB571beb543B1FA7aAC14D215e',
      AaveModule: '0x9bC1C995662369f7F0E4083e8c3Dbb59B5cB10Ca',
    },
    zapTokens: [],
    image: 'polygon-logo.svg',
    shortName: 'Polygon',
  },
};

export default addresses;
