import TokenImage from './TokenImage';

export default function PoolImage({ token0, token1, size, offsetMarginLeft, className }) {
  return (
    <div className={`flex w-fit ${className ?? ''}`}>
      <TokenImage token={token0} offsetMarginLeft='0' offsetSize={size} />
      <TokenImage token={token1} offsetMarginLeft={offsetMarginLeft || '-25px'} offsetSize={size} />
    </div>
  );
}
