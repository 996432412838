import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { getWithdrawRecipes } from '../helpers/contracts';
import actions from '../context/actions';

export const useZapWithdraw = () => {
  const [{ account, provider, chain_id, positions, selected_position }, dispatch] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const zapOutFromUniswap = async (tokenAddressOut) => {
    try {
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);

      const gasLimitEstimate = await WithdrawRecipes.estimateGas.zapOutUniNft(selected_position.id, tokenAddressOut);

      setWalletActionRequired(true);
      const tx = await WithdrawRecipes.zapOutUniNft(selected_position.id, tokenAddressOut, {
        gasLimit: gasLimitEstimate.add(150000),
      });
      setWalletActionRequired(false);

      const success = await notifyLoadingAsyncTx(tx, 'Withdrawing your position from Uniswap');

      const positionsDeepCopy = JSON.parse(JSON.stringify(positions));
      const positionsLeft = positionsDeepCopy.filter((position) => position.id !== selected_position.id);
      if (success) dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: positionsLeft } });
    } catch (err) {
      notify('error', 'Error during withdraw');
      setWalletActionRequired(false);
      console.error(err?.message);
    }
  };

  const zapOutFromAave = async (tokenAddressOut) => {
    try {
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);

      const gasLimitEstimate = await WithdrawRecipes.estimateGas.zapOutFromAave(selected_position.id, tokenAddressOut);

      setWalletActionRequired(true);
      const tx = await WithdrawRecipes.zapOutFromAave(selected_position.id, tokenAddressOut, {
        gasLimit: gasLimitEstimate.add(150000),
      });
      setWalletActionRequired(false);

      const success = await notifyLoadingAsyncTx(tx, 'Withdrawing your position from Aave');

      const positionsDeepCopy = JSON.parse(JSON.stringify(positions));
      const positionsLeft = positionsDeepCopy.filter((position) => position.id !== selected_position.id);
      if (success) dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: positionsLeft } });
    } catch (err) {
      notify('error', 'Error during withdraw');
      setWalletActionRequired(false);
      console.error(err?.message);
    }
  };

  const getAaveZapOutTokenAmount = async (tokenAddressOut) => {
    try {
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);

      if (!selected_position?.isOnAave) throw new Error('Selected position is not on Aave');

      const amountWithdrawn = await WithdrawRecipes.callStatic.zapOutFromAave(selected_position.id, tokenAddressOut, {
        gasLimit: 3e6,
        from: account,
      });

      return Number(amountWithdrawn);
    } catch (err) {
      console.warn('Amounts preview error: ', err?.message);
    }
  };

  return { zapOutFromUniswap, zapOutFromAave, getAaveZapOutTokenAmount };
};
