const MoreSvg = (props) => (
  <svg id='more' viewBox={'0 0 40 34'} width={42} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0 10C0 4.477 4.477 0 10 0h20c5.523 0 10 4.477 10 10v13c0 5.523-4.477 10-10 10H10C4.477 33 0 28.523 0 23V10Z'
      fill='#44FFD1'
    />
    <circle cx={28.5} cy={16.5} r={2.5} fill='#520FEC' />
    <circle cx={20.5} cy={16.5} r={2.5} fill='#520FEC' />
    <circle cx={12.5} cy={16.5} r={2.5} fill='#520FEC' />
  </svg>
);

export default MoreSvg;
