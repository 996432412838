import { useEffect, useState, useMemo } from 'react';
import { useSharedState } from '../../../context/store';
import { useWithdraw } from '../../../hooks/useWithdraw';
import { getAmountsForLiquidity, getV3PoolAddress, getSlot0 } from '../../../utils/utils';
import TokenImage from '../../utils/TokenImage';
import PoolImage from '../../utils/PoolImage';

export default function RegularSection() {
  const [{ chain_id, provider, selected_position }] = useSharedState();
  const { withdrawFromUniswap, withdrawFromAave, getAaveWithdrawTokenAmount } = useWithdraw();

  const [tick, setTick] = useState(0);
  const [amount0, setAmount0] = useState(0);
  const [amount1, setAmount1] = useState(0);
  const [partToWithdraw, setPartToWithdraw] = useState('');

  const poolAddress = useMemo(() => {
    if (chain_id && selected_position)
      return getV3PoolAddress(
        chain_id,
        selected_position.token0Address,
        selected_position.token1Address,
        selected_position.token0Decimals,
        selected_position.token1Decimals,
        selected_position.fee * 10000,
      );
  }, [chain_id, selected_position]);

  useEffect(() => {
    var isMounted = true;

    const getPoolTick = async () => {
      const slot0 = await getSlot0(poolAddress, await provider.getSigner());
      if (!isMounted) return;
      setTick(slot0.tick);
    };

    if (poolAddress) getPoolTick();
    return () => (isMounted = false);
  }, [provider, poolAddress]);

  const onChangePartToWithdraw = (e) => {
    const value = e.target.value.replace('%', '');
    if (isNaN(value) || Number(value) > 100) return;
    e.target.value = value + '%';
    setPartToWithdraw(e.target.value);
  };

  useEffect(() => {
    setAmount0(0);
    setAmount1(0);
    setPartToWithdraw('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selected_position)]);

  const shouldWithdrawFromAave = useMemo(() => {
    if (selected_position?.isOnAave) return true;
    else return false;
  }, [selected_position]);

  const tokenToAave = useMemo(() => {
    if (shouldWithdrawFromAave) {
      if (selected_position?.tickLower > tick || tick > selected_position?.tickUpper) {
        const outOfRangeDirection = tick > selected_position?.tickUpper ? 'up' : 'down';
        if (outOfRangeDirection === 'up') return selected_position?.token1Address;
        else if (outOfRangeDirection === 'down') return selected_position?.token0Address;
        else throw new Error('Expected an OOR direction');
      }
    }
    return null;
  }, [shouldWithdrawFromAave, selected_position, tick]);

  useEffect(() => {
    var isMounted = true;

    const showAmountToWithdrawn = async () => {
      const shares = partToWithdraw.replace('%', '');
      if (shares > 0) {
        if (shouldWithdrawFromAave && tokenToAave) {
          // If withdrawing from Aave, get the token amounts with the withdrawFromAave staticCall method
          const amountWithdrawn = await getAaveWithdrawTokenAmount(shares);
          if (!isMounted) return;
          if (tokenToAave === selected_position?.token0Address) {
            setAmount0(amountWithdrawn / Math.pow(10, selected_position?.token0Decimals));
            setAmount1(0);
          } else if (tokenToAave === selected_position?.token1Address) {
            setAmount0(0);
            setAmount1(amountWithdrawn / Math.pow(10, selected_position?.token1Decimals));
          } else throw new Error('Expected tokenToAave to be a pool token');
        } else if (!shouldWithdrawFromAave) {
          // If withdawing from Uniswap, get the token amounts from the liquidity of the position
          const liqToWithdraw = (shares / 100) * selected_position.liquidity;
          const { amount0: amount0ForLiq, amount1: amount1ForLiq } = getAmountsForLiquidity(
            tick,
            Number(selected_position.tickLower),
            Number(selected_position.tickUpper),
            liqToWithdraw,
          );
          setAmount0(amount0ForLiq / Math.pow(10, selected_position.token0Decimals));
          setAmount1(amount1ForLiq / Math.pow(10, selected_position.token1Decimals));
        }
      } else {
        setAmount0(0);
        setAmount1(0);
      }
    };

    showAmountToWithdrawn();
    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partToWithdraw, shouldWithdrawFromAave, provider, selected_position]);

  return (
    <>
      {selected_position && (
        <div className='py-2 px-4 mt-4'>
          <div className='text-center md:text-left background-light-gray rounded-xl'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
              You withdraw
            </h4>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='col-span-2 items-center'>
              <PoolImage
                token0={selected_position.token0.toLowerCase()}
                token1={selected_position.token1.toLowerCase()}
                offsetMarginLeft='-18px'
                size='45px'
                className='mx-auto'
              />
              <p className='text-md azeret-md text-gray self-center ml-2'>
                {selected_position.token0.toUpperCase()}-{selected_position.token1.toUpperCase()}
              </p>
            </div>
            <div
              className='text-center md:text-left background-light-gray rounded-xl border-primary 
            border-2 flex flex-col justify-between py-2 px-3 col-span-3'
            >
              <button
                className='text-sm btn-main px-2 py-0 mr-0 ml-auto rounded-xl pointer leading-0 uppercase'
                onClick={() => setPartToWithdraw('100%')}
              >
                Max
              </button>
              <input
                type='text'
                placeholder='0%'
                value={partToWithdraw || ''}
                className='text-gray text-xl text-right azeret hidden-input'
                onChange={onChangePartToWithdraw}
              />
            </div>
          </div>
          <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
              You receive
            </h4>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='flex col-span-2 items-center'>
              <TokenImage token={selected_position.token0.toLowerCase()} offsetMarginLeft='0' offsetSize='40px' />
              <p className='text-lg azeret-md text-gray self-center ml-2'>{selected_position.token0.toUpperCase()}</p>
            </div>
            <div className='text-center background-light-gray rounded-xl grid grid-cols-1 ml-2 mr-0 py-2 px-3 col-span-3'>
              <input
                type='text'
                value={amount0 ? amount0.toPrecision(6) : ''}
                placeholder='0'
                className='text-gray text-xl text-right azeret hidden-input'
                disabled
              />
            </div>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='flex col-span-2 items-center'>
              <TokenImage token={selected_position.token1.toLowerCase()} offsetMarginLeft='0' offsetSize='40px' />

              <p className='text-lg azeret-md text-gray self-center ml-2'>{selected_position.token1.toUpperCase()}</p>
            </div>
            <div className='text-center background-light-gray rounded-xl grid grid-cols-1 ml-2 mr-0 py-2 px-3 col-span-3'>
              <input
                type='text'
                value={amount1 ? amount1.toPrecision(6) : ''}
                placeholder='0'
                className='text-gray text-xl text-right azeret hidden-input'
                disabled
              />
            </div>
          </div>
          <div className='row mx-auto'>
            <button
              className='rounded-xl btn-main font-medium px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto uppercase'
              data-aos='fade-left'
              onClick={() =>
                shouldWithdrawFromAave ? withdrawFromAave(partToWithdraw) : withdrawFromUniswap(partToWithdraw)
              }
            >
              Withdraw
            </button>
          </div>
        </div>
      )}
    </>
  );
}
