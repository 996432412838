const ChevronDown = ({ props }) => (
  <svg width={11} height={7} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.451.23 1.07 5.73a.804.804 0 0 0-.168.381.891.891 0 0 0 .035.43.732.732 0 0 0 .226.333c.1.082.219.126.34.126h8.763a.538.538 0 0 0 .345-.12.727.727 0 0 0 .23-.333.891.891 0 0 0 .039-.432.805.805 0 0 0-.169-.384L6.33.23a.617.617 0 0 0-.2-.17.513.513 0 0 0-.479 0 .617.617 0 0 0-.2.17Z'
      fill='#520FEC'
    />
  </svg>
);

export default ChevronDown;
