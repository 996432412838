import { useState } from 'react';
import ChevronUp from '../../icons/ChevronUp';
import ChevronDown from '../../icons/ChevronDown';

export default function SearchBar({ pools, setFilteredPools, filteredPools }) {
  const [ordering, setOrdering] = useState({ property: 'tvl', direction: '' });

  const filterPools = (filter) => {
    const filteredPools = pools.filter((pool) =>
      `${pool.token0.toLowerCase()}/${pool.token1.toLowerCase()}`.includes(filter.toLowerCase()),
    );
    setFilteredPools(filteredPools.slice(0, filteredPools.length));
  };

  const orderPools = (property, direction) => {
    setOrdering({ property, direction });

    // Workaround to avoid creating a shallow copy of pools in memory
    const orderedPools = JSON.parse(JSON.stringify(filteredPools));

    if (direction === 'asc') orderedPools.sort((a, b) => a[property] - b[property]);
    else orderedPools.sort((a, b) => b[property] - a[property]);

    setFilteredPools(orderedPools);
  };

  return (
    <div className='text-left mb-4'>
      <div className='bg-white p-2 rounded-xl card-shadow'>
        <div className='grid rounded-xl p-1 grid-cols-7'>
          <input
            type='text'
            placeholder='search pool_'
            className='search flex no-outline text-gray-500 text-xl text-left azeret rounded-xl pl-3 p-1 mr-4 md:col-span-3 col-span-5'
            style={{ border: '2px solid #f2f2f2' }}
            onChange={(e) => filterPools(e.target.value)}
          />
          <button
            className={`flex col-span-2 items-center primary azeret-md text-base md:text-lg p-1 md:p-2 mr-2 rounded-xl hover:bg-gray-100 focus:outline-none ${
              ordering.direction && ordering.property === 'tvl' && 'ring-2 ring-blue-700 ring-inset'
            }`}
            onClick={() =>
              orderPools(
                'tvl',
                ordering.direction === ''
                  ? 'desc'
                  : ordering.direction === 'desc'
                  ? 'asc'
                  : ordering.direction === 'asc' && '',
              )
            }
          >
            <div className='w-fit m-auto flex items-center azeret-bold'>
              TVL{' '}
              {ordering.property === 'tvl' && ordering.direction === 'desc' && (
                <div className='ml-2'>
                  <ChevronUp />
                </div>
              )}
              {ordering.property === 'tvl' && ordering.direction === 'asc' && (
                <div className='ml-2'>
                  <ChevronDown />
                </div>
              )}
            </div>
          </button>
          <button
            className={`flex col-span-2 d-none d-md-block items-center text-center primary azeret-md text-lg mr-2 hover:bg-gray-100 rounded-xl focus:outline-none ${
              ordering.direction && ordering.property === 'volume' && 'ring-2 ring-blue-700 ring-inset'
            }`}
            onClick={() =>
              orderPools(
                'volume',
                ordering.direction === ''
                  ? 'desc'
                  : ordering.direction === 'desc'
                  ? 'asc'
                  : ordering.direction === 'asc' && '',
              )
            }
          >
            <div className='w-fit m-auto flex items-center azeret-bold'>
              VOLUME
              {ordering.property === 'volume' && ordering.direction === 'desc' && (
                <div className='ml-2'>
                  <ChevronUp />
                </div>
              )}
              {ordering.property === 'volume' && ordering.direction === 'asc' && (
                <div className='ml-2'>
                  <ChevronDown />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
