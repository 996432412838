const MAINNET_API_KEY = process.env.REACT_APP_ETHERSCAN_KEY;
const POLYGON_API_KEY = process.env.REACT_APP_POLYGONSCAN_KEY;

const ETHUSD_PRICE_ENDPOINT = () =>
  `https://api.etherscan.com/api?module=stats&action=ethprice&apiKey=${MAINNET_API_KEY}`;
const BLOCK_NUMBER_BY_TIMESTAMP_ENDPOINT = (timestamp) =>
  `https://api.polygonscan.com/api?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apikey=${POLYGON_API_KEY}`;

export const useEtherscanApi = () => {
  const getEthUsdPrice = async () => {
    try {
      const response = await (await fetch(ETHUSD_PRICE_ENDPOINT())).json();
      return Number(response.result.ethusd);
    } catch (error) {
      console.error(`Failed to fetch ethusd price: ${error?.message}`);
      return 0;
    }
  };

  const getBlockNumberByTimestamp = async (timestamp) => {
    try {
      const response = await (await fetch(BLOCK_NUMBER_BY_TIMESTAMP_ENDPOINT(timestamp))).json();
      return response.result;
    } catch (error) {
      console.error(`Failed to fetch block number by timestamp: ${error?.message}`);
      return 0;
    }
  };

  return { getEthUsdPrice, getBlockNumberByTimestamp };
};
