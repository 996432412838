import { useMemo } from 'react';
import { getDollarFormat } from '../../../utils/utils';

export default function DetailCards({ positions }) {
  const feesAccrued24h = '–';
  const feesAccrued7d = '–';

  const total = useMemo(() => {
    const values = [];
    for (const position of positions ?? []) position.value && values.push(Number(position.value));
    return values.reduce((a, c) => a + c, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(positions)]);

  const totalValueFormatted = useMemo(() => (total ? getDollarFormat(total) : null), [total]);

  return (
    <>
      <div className='w-full'>
        <div className='grid place-items-center md:grid-cols-3 h-full gap-3 mb-2'>
          <div id='detail-card' className='bg-white h-full w-full px-4 py-3 rounded-2xl card-shadow'>
            <h2 className='text-2xl work-sans-bold text-gray leading-5'>Positions total value</h2>
            {totalValueFormatted ? (
              <h3 className='mt-3 azeret primary text-2xl font-medium'>{totalValueFormatted}</h3>
            ) : (
              <div className='mt-3 w-full h-8 bg-gray-300 animate-pulse rounded-xl' />
            )}
          </div>
          <div id='detail-card' className='bg-white h-full w-full px-4 py-3 rounded-2xl card-shadow'>
            <h2 className='text-2xl work-sans-bold text-gray leading-5'>Fees accrued</h2>
            <h4 className='text-lg work-sans-normal text-gray-500'>24h</h4>
            <h3 className='mt-2 azeret primary text-2xl font-medium'>{feesAccrued24h}</h3>
          </div>
          <div id='detail-card' className='bg-white h-full w-full px-4 py-3 rounded-2xl card-shadow'>
            <h2 className='text-2xl work-sans-bold text-gray leading-5'>Fees accrued</h2>
            <h4 className='text-lg work-sans-normal text-gray-500'>7d</h4>
            <h3 className='mt-2 azeret primary text-2xl font-medium'>{feesAccrued7d}</h3>
          </div>
        </div>
      </div>
    </>
  );
}
