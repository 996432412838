import { getDollarFormat } from '../../../utils/utils';
import PoolImage from '../../utils/PoolImage';

export default function PoolCard(props) {
  const { pool, selected, onClick } = props;
  const { token0, token1, feeTier, tvl, volume } = pool;

  return (
    <>
      {pool ? (
        <>
          <div onClick={onClick} className={`pool-card ` + (selected ? 'selected' : '')}>
            <div className='grid grid-cols-12 gap-2 md:gap-0'>
              <div className='flex flex-row text-center md:mr-0 col-span-8 md:col-span-7 items-center'>
                <PoolImage
                  token0={token0.toLowerCase()}
                  token1={token1.toLowerCase()}
                  size='40px'
                  width='40px'
                  offsetMarginLeft='-10px'
                  className={'md:ml-1 md:mr-3'}
                />
                <p className='text-gray azeret-bold text-lg mr-0 ml-4 md:mx-auto md:text-base'>
                  {`${token0.toUpperCase()}`}/{`${token1.toUpperCase()}`}
                </p>
                <p className='primary azeret-bold text-base px-3 d-none d-md-block'>{`${feeTier}%`}</p>
              </div>
              <div className='flex md:col-span-2 items-center col-span-4 mr-0 ml-auto md:m-auto'>
                <p className='text-gray azeret text-base md:m-auto'>{getDollarFormat(tvl)}</p>
              </div>
              <div className='flex md:col-span-3 items-center ml-auto mr-6 md:m-auto d-none d-md-block'>
                <p className='text-gray azeret text-base m-auto'>{getDollarFormat(volume)}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
