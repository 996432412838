/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Pool, TickMath } from '@uniswap/v3-sdk';
import { Token } from '@uniswap/sdk-core';
import Chart from './Chart';
import { useMint } from '../../../hooks/useMint';
import { useApprove } from '../../../hooks/useApprove';
import { useSharedState } from '../../../context/store';
import JSBI from 'jsbi';
import addresses from '../../../contracts/addresses';
import TokenImage from '../../utils/TokenImage';
import BoundsSection from './BoundsSection';
import {
  getPriceByTick,
  getTokenAmountsFromInput,
  maxAmount,
  getSuggestedUpperTick,
  getSuggestedLowerTick,
  checkTokenBalance,
  parseUnnecessaryZeros,
  decimalsDiff,
  getClosestAvailableTick,
  checkIsValidTick,
} from '../../../utils/utils';

export default function RegularSection() {
  const [{ chain_id, provider, selected_pool }] = useSharedState();
  const { checkAllowance, approveToken } = useApprove();
  const { mintAndDeposit } = useMint();

  const [tickData, setTickData] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [isToken0Available, setIsToken0Available] = useState(true);
  const [isToken1Available, setIsToken1Available] = useState(true);
  const [lastAmountChanged, setLastAmountChanged] = useState(null);
  const [needToChangeChart, setNeedToChangeChart] = useState(false);
  const [tokenInWhichToShowPrices, setTokenInWhichToShowPrices] = useState('token0');

  // Default mount of tick spacings to use
  // for the lower range (negative delta) and upper range (positive delta)
  const TICK_SPACING_WIDTH = 10;

  // Given the amount of a token of the pool, return the amount of the other one in the pool
  // "amount" is the amount of the input token
  // "zeroForOne" is a flag to indicate whether to calculate the amount from token0 to token1 or vice versa
  const getOtherTokenAmount = (amount, zeroForOne, tickLower, tickUpper) => {
    let { feeTier, tick, token0Decimals, token1Decimals } = selected_pool;

    if (tickLower > tickUpper) [tickLower, tickUpper] = [tickUpper, tickLower];

    if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(Number(tick), feeTier, TICK_SPACING_WIDTH);
    if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(Number(tick), feeTier, TICK_SPACING_WIDTH);

    const currentSqrt = TickMath.getSqrtRatioAtTick(Number(tick));
    const tokenDecimals = zeroForOne ? token0Decimals : token1Decimals;
    const tokenA = new Token(chain_id, selected_pool.token0Address, Number(selected_pool.token0Decimals));
    const tokenB = new Token(chain_id, selected_pool.token1Address, Number(selected_pool.token1Decimals));
    const pool = new Pool(tokenA, tokenB, Number(feeTier) * 10000, currentSqrt, JSBI.BigInt(0), Number(tick), []);
    const tokens = getTokenAmountsFromInput(amount, tokenDecimals, zeroForOne, tick, tickLower, tickUpper, pool);

    return zeroForOne
      ? (tokens.amount1 / Math.pow(10, token1Decimals)).toFixed(token1Decimals)
      : (tokens.amount0 / Math.pow(10, token0Decimals)).toFixed(token0Decimals);
  };

  const handleChangeAmount = (e, tokenNumber) => {
    var amount = e.target?.value;
    var otherAmount = 0;
    const { tickLower, tickUpper } = positionData;
    const amountSet = `amount${tokenNumber}Desired`;
    const otherAmountSet = `amount${tokenNumber ? 0 : 1}Desired`;

    if (isNaN(amount)) amount = 0;
    if (!(outOfRangeBlockedToken === tokenNumber))
      otherAmount = getOtherTokenAmount(amount, !tokenNumber, tickLower, tickUpper);
    if (isNaN(otherAmount)) otherAmount = 0;

    setLastAmountChanged(tokenNumber);
    setPositionData({ ...positionData, [amountSet]: amount, [otherAmountSet]: otherAmount });
  };

  // Automatically update amounts when ticks change
  const handleChangeTick = (tick, tickType) => {
    const tickToChange = tickType === 'Lower' ? 'tickLower' : 'tickUpper';
    // Perform some simple validation on the input (only numbers + decimals allowed)
    if (!checkIsValidTick(tick)) return;

    // Update the tick to the new value visually
    setTickData({ ...tickData, [tickToChange]: tick });

    try {
      // Get the closest tick spacing to the new tick
      const newTick = getClosestAvailableTick(
        tokenInWhichToShowPrices === 'token0' ? tick : 1 / tick,
        selected_pool.feeTier,
        decimalsDiff(selected_pool),
      );

      // Only update the amounts if we have already selected one of them
      if (lastAmountChanged !== null) {
        const amountSet = lastAmountChanged === 0 ? 'amount0Desired' : 'amount1Desired';
        const amountToChange = lastAmountChanged === 0 ? 'amount1Desired' : 'amount0Desired';

        // Get the amount of the other token in the pool based on the new tick
        var newAmount = getOtherTokenAmount(
          positionData[amountSet],
          !lastAmountChanged,
          tickType === 'Lower' ? newTick : positionData.tickLower,
          tickType === 'Upper' ? newTick : positionData.tickUpper,
        );

        // Validate the new amount (e.g. set to 0 if out of range or undefined)
        if (newAmount < 0 || isNaN(newAmount)) newAmount = 0;
        else if (outOfRangeBlockedToken === (lastAmountChanged === 0 ? 1 : 0)) newAmount = 0;

        setPositionData({ ...positionData, [tickToChange]: newTick, [amountToChange]: newAmount });
      } else {
        setPositionData({ ...positionData, [tickToChange]: newTick });
      }
    } catch (err) {
      console.warn(err?.message);
      setPositionData({ ...positionData, [tickToChange]: '0' });
    }
  };

  const selectMaxAmount = async (tokenAddress, tokenNumber) => {
    var max = await maxAmount(await provider.getSigner(), tokenAddress);
    var maxOtherToken = 0;

    if (typeof outOfRangeBlockedToken === 'undefined' || outOfRangeBlockedToken !== (tokenNumber === 0 ? 1 : 0))
      maxOtherToken = getOtherTokenAmount(max, !tokenNumber, positionData.tickLower, positionData.tickUpper);

    if (maxOtherToken < 0 || isNaN(maxOtherToken)) maxOtherToken = 0;

    setLastAmountChanged(tokenNumber);

    max = Number(max).toFixed(selected_pool[`token${tokenNumber}Decimals`]);
    maxOtherToken = Number(maxOtherToken).toFixed(selected_pool[`token${tokenNumber ? 0 : 1}Decimals`]);

    setPositionData({
      ...positionData,
      [`amount${tokenNumber}Desired`]: parseUnnecessaryZeros(max),
      [`amount${tokenNumber ? 0 : 1}Desired`]: parseUnnecessaryZeros(maxOtherToken),
    });
  };

  const mint = async () => {
    try {
      let { tickLower, tickUpper, amount0Desired, amount1Desired } = positionData;
      let { token0Address, token1Address, feeTier, tick } = selected_pool;

      if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(tick, feeTier, TICK_SPACING_WIDTH);
      if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(tick, feeTier, TICK_SPACING_WIDTH);

      if (tokenInWhichToShowPrices === 'token1') [tickUpper, tickLower] = [tickLower, tickUpper];

      await mintAndDeposit(token0Address, token1Address, amount0Desired, amount1Desired, tickLower, tickUpper, feeTier);
    } catch (err) {
      console.error(err?.message);
    }
  };

  // Check if the user balance is enough for the amount desired
  const checkAvailableBalances = async () => {
    if (positionData?.amount0Desired !== null && !isNaN(positionData?.amount0Desired)) {
      try {
        setIsToken0Available(
          await checkTokenBalance(positionData.amount0Desired, selected_pool.token0Address, await provider.getSigner()),
        );
      } catch (err) {
        console.warn(err?.message);
        setIsToken0Available(false);
      }
    }
    if (positionData?.amount1Desired !== null && !isNaN(positionData?.amount1Desired)) {
      try {
        setIsToken1Available(
          await checkTokenBalance(positionData.amount1Desired, selected_pool.token1Address, await provider.getSigner()),
        );
      } catch (err) {
        console.warn(err?.message);
        setIsToken1Available(false);
      }
    }
  };

  // Check for illegal range configurations
  const isIllegalRange = useMemo(() => {
    if (selected_pool && tickData?.tickLower && tickData?.tickUpper) {
      var { tickLower, tickUpper } = tickData;
      const { tick } = selected_pool;

      const isRangeWrongDirection = Number(tickLower) > Number(tickUpper);
      const isLowerIllegal = Number(tickLower) > Number(tick) && Number(tickUpper) < Number(tick);
      const isUpperIllegal = Number(tickUpper) < Number(tick) && Number(tickLower) > Number(tick);

      if (isRangeWrongDirection || isLowerIllegal || isUpperIllegal) return true;
    }
    return false;
  }, [selected_pool, tickData]);

  // If out of range, block the input of the token that is not needed
  const outOfRangeBlockedToken = useMemo(() => {
    if (selected_pool && tickData?.tickLower && tickData?.tickUpper) {
      const { tickLower: rawTickLower, tickUpper: rawTickUpper } = tickData;
      const { tick: currentTick } = selected_pool;

      const tickLower = getClosestAvailableTick(
        tokenInWhichToShowPrices === 'token0' ? rawTickLower : 1 / rawTickLower,
        selected_pool.feeTier,
        decimalsDiff(selected_pool),
      );
      const tickUpper = getClosestAvailableTick(
        tokenInWhichToShowPrices === 'token0' ? rawTickUpper : 1 / rawTickUpper,
        selected_pool.feeTier,
        decimalsDiff(selected_pool),
      );

      if (tokenInWhichToShowPrices === 'token0') {
        if (tickLower > currentTick) return 1;
        else if (tickUpper < currentTick) return 0;
      } else {
        if (tickLower < currentTick) return 0;
        else if (tickUpper > currentTick) return 1;
      }
    }
  }, [selected_pool, tickData, tokenInWhichToShowPrices]);

  // Check that the DepositRecipes contract is approved as token spender
  const checkIsApproved = async () => {
    try {
      const _p1 = checkAllowance(selected_pool.token0Address, addresses[chain_id].DepositRecipes);
      const _p2 = checkAllowance(selected_pool.token1Address, addresses[chain_id].DepositRecipes);
      const [approved0, approved1] = await Promise.all([_p1, _p2]);

      if (outOfRangeBlockedToken === 0) setIsApproved(approved1 ? true : false);
      else if (outOfRangeBlockedToken === 1) setIsApproved(approved0 ? true : false);
      else setIsApproved(approved0 && approved1 ? true : false);
    } catch (err) {
      console.error(err?.message);
    }
  };

  const approveTokens = async () => {
    try {
      var _p1;
      var _p2;

      if (outOfRangeBlockedToken !== 0)
        _p1 = approveToken(selected_pool?.token0Address, addresses[chain_id].DepositRecipes);
      if (outOfRangeBlockedToken !== 1)
        _p2 = approveToken(selected_pool?.token1Address, addresses[chain_id].DepositRecipes);

      await Promise.all([_p1, _p2]);
      setIsApproved(true);
    } catch (err) {
      console.error(err?.message);
    }
  };

  const currentPrice = useMemo(() => {
    if (selected_pool)
      return getPriceByTick(
        Number(selected_pool.tick),
        tokenInWhichToShowPrices === 'token0',
        decimalsDiff(selected_pool, tokenInWhichToShowPrices === 'token0'),
      ).toPrecision(5);
  }, [selected_pool?.tick, tokenInWhichToShowPrices]);

  useEffect(() => {
    setLastAmountChanged(null);
    setPositionData({ ...positionData, amount0Desired: '0', amount1Desired: '0' });
    if (selected_pool?.token0Address && selected_pool?.token1Address) checkIsApproved();
  }, [selected_pool, outOfRangeBlockedToken]);

  useEffect(() => {
    if (provider && positionData && selected_pool) checkAvailableBalances();
  }, [provider, positionData?.amount0Desired, positionData?.amount1Desired, selected_pool]);

  return (
    <>
      <div className='pb-2 px-4 mt-4'>
        <div className='mb-3 text-center md:text-left background-light-gray rounded-xl'>
          <div className='flex px-4'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary pt-2.5 pb-1 uppercase'>
              Set price range
            </h4>
            {selected_pool && (
              <div className='relative w-fit ml-auto pt-3'>
                <div className='absolute top-3 right-[4px] w-full flex justify-end items-center'>
                  <button
                    className={`rounded-[0.45rem] azeret font-medium text-sm uppercase focus:outline-none ${
                      tokenInWhichToShowPrices === 'token0'
                        ? 'px-2 bg-white z-10 -mr-3 border-2 border-secondary primary'
                        : 'pl-2 pr-3 border-none bg-gray-600 z-0 text-white py-[1px]'
                    }`}
                    onClick={() => setTokenInWhichToShowPrices('token0')}
                  >
                    {selected_pool.token0}
                  </button>
                  <button
                    className={`px-2 rounded-[0.45rem] azeret font-medium text-sm uppercase focus:outline-none ${
                      tokenInWhichToShowPrices === 'token1'
                        ? 'px-2 bg-white z-10 -ml-3 border-2 border-secondary primary'
                        : 'pl-3 pr-2 border-none bg-gray-600 z-0 text-white py-[1px]'
                    }`}
                    onClick={() => setTokenInWhichToShowPrices('token1')}
                  >
                    {selected_pool.token1}
                  </button>
                </div>
              </div>
            )}
          </div>
          {selected_pool ? (
            <div className='flex px-4'>
              <p className='text-gray text-base azeret pb-2'>
                Current Price:{' '}
                <span className='primary font-bold'>
                  {currentPrice}
                  &nbsp;
                  {tokenInWhichToShowPrices === 'token0'
                    ? selected_pool?.token1?.toUpperCase()
                    : selected_pool?.token0?.toUpperCase()}
                </span>{' '}
                per
                <span className='primary font-bold'>
                  {' '}
                  {tokenInWhichToShowPrices === 'token0'
                    ? selected_pool?.token0?.toUpperCase()
                    : selected_pool?.token1?.toUpperCase()}
                </span>
              </p>
            </div>
          ) : (
            <div className='py-[26px]' />
          )}
        </div>
        {tickData ? (
          <Chart
            tokenInWhichToShowPrices={tokenInWhichToShowPrices}
            handleChangeTick={handleChangeTick}
            tickData={tickData}
            needToChangeChart={needToChangeChart}
            setNeedToChangeChart={setNeedToChangeChart}
          />
        ) : (
          <></>
        )}
        {isIllegalRange ? (
          <div className='my-4 border-2 grid place-content-center px-4 py-2 border-custom-red rounded-xl'>
            <p className='azeret text-custom-red text-lg'>Please select a valid range!</p>
          </div>
        ) : (
          <></>
        )}

        {positionData && (
          <BoundsSection
            tokenInWhichToShowPrices={tokenInWhichToShowPrices}
            positionData={positionData}
            setPositionData={setPositionData}
            lastAmountChanged={lastAmountChanged}
            useSingleToken={false}
            setTickData={setTickData}
            tickData={tickData}
            setNeedToChangeChart={setNeedToChangeChart}
            handleChangeTick={handleChangeTick}
          />
        )}
        {/* setNeedToChangeChart(true);*/}
        <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
          <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
            Deposit amounts
          </h4>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div className='gap-y-1 grid grid-cols-12 text-center md:text-left mt-3 border-primary mx-auto py-1'>
            <div
              className={`h-18 col-span-12 grid grid-cols-12 rounded-xl background-light-gray 
              ${
                outOfRangeBlockedToken === 0
                  ? 'border-disabled opacity-50'
                  : isToken0Available
                  ? 'border-primary'
                  : 'border-custom-red'
              } border-2 p-2`}
            >
              <div className='flex col-span-4 items-center place-self-start'>
                <TokenImage token={selected_pool?.token0?.toLowerCase()} offsetMarginLeft='0' offsetSize='23px' />
                <p
                  className={`text-lg azeret-md self-center ml-1 ${
                    isToken0Available ? 'text-gray' : outOfRangeBlockedToken === 0 ? 'text-disabled' : 'text-custom-red'
                  }`}
                >
                  {selected_pool?.token0?.toUpperCase()}
                </p>
              </div>
              <button
                className={`col-span-8 text-xs azeret-md text-white px-2 py-0-5 mr-0 ml-auto 
                rounded-lg h-6 hover:opacity-90 pointer uppercase ${
                  !selected_pool ? 'bg-gray-400' : outOfRangeBlockedToken === 0 ? 'bg-disabled' : 'bg-first'
                }`}
                onClick={() => selectMaxAmount(selected_pool?.token0Address, 0)}
                disabled={outOfRangeBlockedToken === 0}
              >
                max
              </button>
              <input
                type='text'
                value={outOfRangeBlockedToken === 0 ? '0' : positionData?.amount0Desired?.toString() || '0'}
                disabled={outOfRangeBlockedToken === 0}
                onChange={(e) => handleChangeAmount(e, 0)}
                placeholder='0'
                className={`col-span-12 text-xl text-right azeret hidden-input ${
                  outOfRangeBlockedToken === 0 ? 'text-disabled' : isToken0Available ? 'text-gray' : 'text-custom-red'
                }`}
              />
            </div>
          </div>
          <div className='gap-y-1 grid grid-cols-12 text-center md:text-left mt-3 border-primary mx-auto py-1'>
            <div
              className={`h-18 col-span-12 grid grid-cols-12 rounded-xl background-light-gray 
              ${
                outOfRangeBlockedToken === 1
                  ? 'border-disabled opacity-50'
                  : isToken1Available
                  ? 'border-primary'
                  : 'border-custom-red'
              } border-2 p-2`}
            >
              <div className='flex col-span-4 items-center place-self-start'>
                <TokenImage token={selected_pool?.token1?.toLowerCase()} offsetMarginLeft='0' offsetSize='23px' />
                <p
                  className={`text-lg azeret-md self-center ml-1 ${
                    isToken1Available ? 'text-gray' : outOfRangeBlockedToken === 1 ? 'text-disabled' : 'text-custom-red'
                  }`}
                >
                  {selected_pool?.token1?.toUpperCase()}
                </p>
              </div>
              <button
                className={`col-span-8 text-xs azeret-md text-white px-2 py-0-5 mr-0 ml-auto 
                rounded-lg h-6 hover:opacity-90 pointer uppercase ${
                  !selected_pool ? 'bg-gray-400' : outOfRangeBlockedToken === 1 ? 'bg-disabled' : 'bg-first'
                }`}
                onClick={() => selectMaxAmount(selected_pool?.token1Address, 1)}
                disabled={outOfRangeBlockedToken === 1}
              >
                max
              </button>
              <input
                type='text'
                value={outOfRangeBlockedToken === 1 ? '0' : positionData?.amount1Desired?.toString() || '0'}
                disabled={outOfRangeBlockedToken === 1}
                onChange={(e) => handleChangeAmount(e, 1)}
                placeholder='0'
                className={`col-span-12 text-xl text-right azeret hidden-input ${
                  outOfRangeBlockedToken === 1 ? 'text-disabled' : isToken1Available ? 'text-gray' : 'text-custom-red'
                }`}
              />
            </div>
          </div>
        </div>
        <div className='row mx-auto pb-2'>
          {isApproved ? (
            <button
              disabled={isToken0Available && isToken1Available ? false : true}
              className={`uppercase text-lg azeret mt-2 ml-auto mr-0 hover:opacity-90 rounded-xl px-9 py-2 ${
                !selected_pool || !(isToken0Available && isToken1Available)
                  ? 'bg-gray-400 text-white'
                  : 'bg-first text-white'
              }`}
              onClick={mint}
            >
              {isToken0Available && isToken1Available
                ? 'Deposit'
                : !isToken0Available && isToken1Available
                ? `Not enough ${selected_pool?.token0}`
                : isToken0Available && !isToken1Available
                ? `Not enough ${selected_pool?.token1}`
                : !isToken0Available && !isToken1Available && `Not enough tokens`}
            </button>
          ) : (
            <button
              className={`uppercase text-lg azeret mt-2 ml-auto mr-0 hover:opacity-90 rounded-xl px-9 py-2 ${
                !selected_pool ? 'bg-gray-400 text-white' : 'bg-first text-white'
              }`}
              onClick={approveTokens}
            >
              approve
            </button>
          )}
        </div>
      </div>
    </>
  );
}
