const AddSvg = (props) => {
  return (
    <svg width={288} height={288} xmlns='http://www.w3.org/2000/svg' {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={288}
        height={288}
        fill='none'
        viewBox='0 0 21 21'
        {...props}
        className='fill-btn'
      >
        <rect width={4.625} height={20.813} x={8.188} y={0.094} rx={2.313} className='colorfff svgShape' />
        <rect
          width={4.625}
          height={20.813}
          x={20.906}
          y={8.188}
          rx={2.313}
          transform='rotate(90 20.906 8.188)'
          className='colorfff svgShape'
        />
      </svg>
    </svg>
  );
};

export default AddSvg;
