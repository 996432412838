const AaveLogoBtn = (props) => (
  <svg width={37} height={37} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.5 36.445c9.91 0 17.945-8.034 17.945-17.945C36.445 8.59 28.41.555 18.5.555 8.59.555.555 8.589.555 18.5c0 9.91 8.034 17.945 17.945 17.945Z'
      fill='url(#a)'
    />
    <path
      d='m26.353 25.65-6.068-14.67c-.342-.759-.85-1.129-1.521-1.129h-.537c-.67 0-1.18.37-1.522 1.129l-2.64 6.392h-1.998a1.094 1.094 0 0 0-1.087 1.086v.014c.004.597.49 1.082 1.086 1.087h1.073l-2.52 6.091a1.274 1.274 0 0 0-.074.417c0 .342.106.61.296.818.19.208.462.31.805.31.226-.005.444-.074.624-.208.194-.134.328-.328.435-.55l2.775-6.883h1.924a1.095 1.095 0 0 0 1.087-1.086v-.028a1.098 1.098 0 0 0-1.087-1.087h-1.027l2.118-5.277 5.772 14.356c.107.222.24.416.435.55.18.134.402.204.624.208.343 0 .61-.101.805-.31.194-.208.296-.476.296-.818a.97.97 0 0 0-.074-.412Z'
      fill='#fff'
    />
    <defs>
      <linearGradient id='a' x1={31.88} y1={7.242} x2={5.177} y2={29.709} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B6509E' />
        <stop offset={1} stopColor='#2EBAC6' />
      </linearGradient>
    </defs>
  </svg>
);

export default AaveLogoBtn;
