import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSharedState } from '../../context/store';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useWallet } from '../../hooks/useWallet';
import { getDollarFormat } from '../../utils/utils';
import networks from '../../helpers/networks';
import OrbitLogoSvg from '../icons/OrbitLogoSvg';
import ConnectorModal from './ConnectorModal';
import InfoDropdown from './InfoDropdown';

export default function WalletHeader() {
  const [{ chain_id, provider }] = useSharedState();
  const { changeNetwork, checkValidNetwork } = useWallet();
  const { getTotalTVL } = useAnalytics();
  const [tvl, setTvl] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    chain_id && (async () => setTvl(await getTotalTVL()))();
    const id = setInterval(async () => setTvl(await getTotalTVL()), 15000);
    return () => clearInterval(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain_id, pathname]);

  return (
    <>
      <header className='row pt-3 md:pt-8 gap-0'>
        {/* Desktop header */}
        <div className='col-2 sm:col-2 d-lg-block d-none mx-auto'>
          <div className='flex items-center justify-between'>
            <Link to='/'>
              <OrbitLogoSvg />
            </Link>
          </div>
        </div>
        <div className='col-10 md:mt-4 d-lg-block d-none t-align-right'>
          {tvl ? (
            <div className='inline-block border-2 border-primary rounded-2xl tvl-pill bg-white'>
              <h2 className='azeret primary font-semibold'>
                <span className='work-sans-bold text-gray'>TVL</span> {getDollarFormat(tvl)}
              </h2>
            </div>
          ) : (
            ''
          )}

          <button
            className='text-lg p-0 text-red-600 font-bold border-b-red-500 border-b-2 mx-3 uppercase'
            style={{ display: !checkValidNetwork(chain_id) && provider ? 'inline-block' : 'none' }}
            onClick={() => changeNetwork(137)}
          >
            Wrong network
          </button>
          <select
            value={checkValidNetwork(chain_id) && chain_id}
            onChange={(e) => changeNetwork(e.target.value)}
            className='select-network rounded-xl btn-main uppercase mx-3 pr-8 t-align-center text-md focus:outline-none'
          >
            {Object.keys(networks).map((key, idx) => (
              <option key={idx} value={key}>
                {networks[key].chainName}
              </option>
            ))}
          </select>
          <ConnectorModal />
          <InfoDropdown />
        </div>
      </header>
    </>
  );
}
