import { getDepositRecipes, getERC20 } from '../helpers/contracts';
import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';

export const useMint = () => {
  const [{ provider, chain_id }] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const mintAndDeposit = async (
    token0Address,
    token1Address,
    amount0Desired,
    amount1Desired,
    tickLower,
    tickUpper,
    fee,
  ) => {
    try {
      const signer = await provider.getSigner();
      const DepositRecipes = getDepositRecipes(chain_id, signer);

      const token0 = getERC20(token0Address, signer);
      const token1 = getERC20(token1Address, signer);

      const token0Decimals = await token0.decimals();
      const token1Decimals = await token1.decimals();

      const amount0 = ('0x' + (amount0Desired * 10 ** token0Decimals).toString(16)).split('.')[0];
      const amount1 = ('0x' + (amount1Desired * 10 ** token1Decimals).toString(16)).split('.')[0];

      const feeInBasePoints = Number(fee) * 10000;

      const gasLimitEstimate = await DepositRecipes.estimateGas.mintAndDeposit(
        token0Address,
        token1Address,
        feeInBasePoints,
        tickLower,
        tickUpper,
        amount0,
        amount1,
      );

      setWalletActionRequired(true);
      const tx = await DepositRecipes.mintAndDeposit(
        token0Address,
        token1Address,
        feeInBasePoints,
        tickLower,
        tickUpper,
        amount0,
        amount1,
        { gasLimit: gasLimitEstimate.add(150000) },
      );
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Minting your position');
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during mint. Try again later.');
      console.error(err?.message);
    }
  };

  return { mintAndDeposit };
};
