import road_point from '../../images/road_point.svg';
import last_road_point from '../../images/last_road_desk.svg';
import road_point_mobile from '../../images/road_point_mobile.svg';
import last_road_point_mobile from '../../images/last_road_mob.svg';

export default function RoadMap() {
  return (
    <>
      <div className='md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center lg:mt-10 md:mt-8 mt-12'>
        <div className='md:col-span-8 lg:col-span-8 md:mb-0 max-w-6xl'>
          <h3 className='secondary lg:text-7xl text-5xl mb-4 work-sans-bold'>RoadMap</h3>
        </div>
        <div className='md:col-span-4 lg:col-span-4 md:mb-0 text-center md:text-left'></div>
      </div>
      {/* desktop */}
      <div className='desktop'>
        <div className='md:grid md:grid-cols-12 items-center mt-8 gap-2 '>
          <div className='md:col-span-3 lg:col-span-3 mb-8 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-4 grid-flow-col gap-4'>
              <h4 className='text-2xl text-left azeret secondary work-sans-bold'>2021 Q4</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>Winning Uni hackathon</h4>
              <img src={road_point} alt='2021 Q4' />
            </div>
          </div>
          <div className='md:col-span-3 lg:col-span-3 mb-8 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-4 grid-flow-col gap-4'>
              <h4 className='text-2xl text-left azeret secondary work-sans-bold'>2022 Q1</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>First prototype</h4>
              <img src={road_point} alt='2022 Q1' />
            </div>
          </div>
          <div className='md:col-span-3 lg:col-span-3 mb-8 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-4 grid-flow-col gap-4'>
              <h4 className='text-2xl text-left azeret secondary work-sans-bold'>2022 Q2</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>Live to polygon</h4>
              <img src={road_point} alt='2022 Q2' />
            </div>
          </div>
          <div className='md:col-span-3 lg:col-span-3 mb-8 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-4 grid-flow-col gap-4'>
              <h4 className='text-2xl text-left azeret secondary work-sans-bold'>2022 Q4</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>Idle liquidity v2</h4>
              <img src={last_road_point} className='last-point' alt='2022 Q2' />
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className='mobile'>
        <div className='grid grid-cols-12 gap-2 '>
          <div className='col-span-2 mb-3'>
            <img className='col-span-2' src={road_point_mobile} alt='roadmap point' />
          </div>
          <div className='col-span-10 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-2 grid-flow-col'>
              <h4 className='text-3xl text-left azeret secondary work-sans-bold'>2021 Q4</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>Winning Uni hackton</h4>
            </div>
          </div>
          <div className='col-span-2 mb-3'>
            <img src={road_point_mobile} alt='2021 Q4' />
          </div>
          <div className='col-span-10 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-2 grid-flow-col'>
              <h4 className='text-3xl text-left azeret secondary work-sans-bold'>2022 Q1</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>First prototype</h4>
            </div>
          </div>
          <div className='col-span-2 mb-3'>
            <img src={road_point_mobile} alt='2022 Q1' />
          </div>
          <div className='col-span-10 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-2 grid-flow-col'>
              <h4 className='text-3xl text-left azeret secondary work-sans-bold'>2022 Q2</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>Live to polygon</h4>
            </div>
          </div>
          <div className='col-span-2 mb-3'>
            <img src={last_road_point_mobile} alt='2022 Q2' />
          </div>
          <div className='col-span-10 md:mb-0 max-w-6xl'>
            <div className='grid grid-rows-2 grid-flow-col'>
              <h4 className='text-3xl text-left azeret secondary work-sans-bold'>2022 Q3</h4>
              <h4 className='text-lg text-left azeret-bold text-light-gray'>Live to other chains</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
