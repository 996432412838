import { useEffect, useState } from 'react';
import { useSharedState } from '../../../context/store';
import PositionCard from './PositionCard';
import actions from '../../../context/actions';
import OrbitLoader from '../../icons/OrbitLoader';

export default function PositionsList(props) {
  const { colsXs, colsMd, page, positions, setSelected } = props;
  const [, dispatch] = useSharedState();
  const [currentlySelected, setCurrentlySelected] = useState(0);

  useEffect(() => {
    if (page !== 'import' && page !== 'vault' && positions) {
      setSelected(positions[currentlySelected]);
      dispatch({ type: actions.SELECT_POSITION, payload: { selected_position: positions[currentlySelected] } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelected, positions]);

  return (
    <>
      {positions ? (
        <div
          id={page !== 'vault' ? 'box-scrollbar' : ''}
          className={`${
            page === 'analytics'
              ? 'overflow-y-hidden h-fixed'
              : page === 'import'
              ? 'overflow-y-scroll max-h-[33.5rem]'
              : page === 'dashboard'
              ? 'overflow-y-scroll max-h-[32rem]'
              : 'overflow-y-scroll max-h-[34.8rem]'
          } overflow-x-hidden scroll-smooth grid ${colsMd === 1 ? 'md:grid-cols-1' : 'md:grid-cols-2'} ${
            colsXs === 1 ? 'xs:grid-cols-1' : 'xs:grid-cols-2'
          }  gap-4 py-1 px-2`}
        >
          {positions.map((position, index) => {
            if (page !== 'import' && page !== 'vault') {
              return (
                <PositionCard
                  key={index}
                  onClick={() => setCurrentlySelected(index)}
                  selected={currentlySelected === index}
                  page={page}
                  position={position}
                />
              );
            }
            return <PositionCard key={index} page={page} position={position} />;
          })}
        </div>
      ) : (
        <div
          id='loading-card'
          className={`overflow-hidden w-full ${
            page === 'dashboard' ? 'my-4 h-[466px]' : page === 'withdraw' && 'h-[550px]'
          } bg-white grid place-items-center shadow-md rounded-xl `}
        >
          <div className='w-36 mb-3'>
            <OrbitLoader />
          </div>
        </div>
      )}
    </>
  );
}
