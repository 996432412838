/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo, useRef } from 'react';
import { useSharedState } from '../../../context/store';
import { usePools } from '../../../hooks/usePools';
import { getPriceByTick, getClosestAvailableTick, decimalsDiff } from '../../../utils/utils';

const CHART_HEIGHT = 200;

export default function Chart({
  tokenInWhichToShowPrices,
  handleChangeTick,
  tickData,
  needToChangeChart,
  setNeedToChangeChart,
}) {
  const [{ chain_id, provider, selected_pool }] = useSharedState();
  const { getUniswapPoolLiquidity } = usePools();
  const [chartData, setChartData] = useState(null);
  const [tickWidth, setTickWidth] = useState(0);
  const [tickIndex, setTickIndex] = useState(0);
  const refChart = useRef();

  const [startingXLower, setStartingXLower] = useState(0);
  const [startingXUpper, setStartingXUpper] = useState(0);
  const [mouseDownLower, setMouseDownLower] = useState(false);
  const [mouseDownUpper, setMouseDownUpper] = useState(false);
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });
  const [coordinateTickLower, setCoordinateTickLower] = useState(null);
  const [coordinateTickUpper, setCoordinateTickUpper] = useState(null);

  useEffect(() => {
    const handleWindowMouseMove = (e) => setGlobalCoords({ x: e.clientX, y: e.clientY });
    window.addEventListener('mousemove', handleWindowMouseMove);
    return () => window.removeEventListener('mousemove', handleWindowMouseMove);
  }, []);

  useEffect(() => {
    const getLiquidityBins = async () => {
      setChartData(null);

      const tickSpacing = selected_pool.feeTier * 200;
      const chartDataBuffer = await getUniswapPoolLiquidity(
        selected_pool.poolAddress,
        Number(selected_pool.tick) - Number(500 * tickSpacing),
        Number(selected_pool.tick) + Number(500 * tickSpacing),
      );

      setChartData(tokenInWhichToShowPrices === 'token0' ? chartDataBuffer : [...chartDataBuffer].reverse());
    };
    if (chain_id && provider && selected_pool) getLiquidityBins();
  }, [chain_id, provider, selected_pool, tokenInWhichToShowPrices]);

  const binWidth = useMemo(() => {
    if (refChart?.current?.offsetWidth && chartData?.length) {
      return refChart.current.offsetWidth / chartData.length;
    }
  }, [chartData?.length, refChart?.current?.offsetWidth]);

  const cumulativeLiquidityBins = useMemo(() => {
    if (chartData) {
      const cumulativeLiq = [];

      let previousLiquidity = 0;
      const tickSpacing = selected_pool.feeTier * 200;

      for (const singleTickLiquidity of chartData) {
        if (singleTickLiquidity.tickIdx === (Math.floor(selected_pool.tick / tickSpacing) * tickSpacing).toString()) {
          setTickWidth(binWidth * chartData.indexOf(singleTickLiquidity) + 1);
          setTickIndex(chartData.indexOf(singleTickLiquidity));
        }
        const nextTickLiquidity =
          tokenInWhichToShowPrices === 'token0'
            ? Number(previousLiquidity) + Number(singleTickLiquidity.liquidityNet)
            : Number(previousLiquidity) - Number(singleTickLiquidity.liquidityNet);
        cumulativeLiq.push(nextTickLiquidity);
        previousLiquidity = nextTickLiquidity;
      }

      return cumulativeLiq;
    } else return null;
  }, [chartData, selected_pool]);

  const maxLiquidityBin = useMemo(() => {
    if (cumulativeLiquidityBins) return Math.max(...[...new Set(cumulativeLiquidityBins)]);
    else return null;
  }, [cumulativeLiquidityBins]);

  useEffect(() => {
    /// ***** check coordinate overflow *****
    if (
      refChart?.current?.offsetWidth &&
      (coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0) < 0 ||
        coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0) > refChart.current.offsetWidth)
    ) {
      setCoordinateTickLower(
        Math.min(
          Math.max(coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0), 0),
          refChart.current.offsetWidth - 12,
        ),
      );
      setMouseDownLower(false);
    }
  }, [coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0)]);

  useEffect(() => {
    /// ***** check coordinate overflow *****
    if (
      refChart?.current?.offsetWidth &&
      (coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0) < 0 ||
        coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0) > refChart.current.offsetWidth)
    ) {
      setCoordinateTickUpper(
        Math.min(
          Math.max(coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 0),
          refChart.current.offsetWidth - 12,
        ),
      );
      setMouseDownUpper(false);
    }
  }, [coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0)]);

  const canShowChart = useMemo(
    () => selected_pool && tickWidth && binWidth && refChart?.current?.offsetWidth && chartData && true,
    [selected_pool, tickWidth, coordinateTickLower, coordinateTickUpper, binWidth, refChart],
  );

  // CHANGE TICK DATA
  const changeTick = (tickType, changeChartData = false) => {
    if (
      selected_pool?.feeTier &&
      chartData &&
      tickData?.tickUpper &&
      canShowChart &&
      (changeChartData || needToChangeChart)
    ) {
      try {
        const tickToCalc = tickType === 'tickUpper' ? tickData?.tickUpper : tickData?.tickLower;
        const tick = Math.abs(
          getClosestAvailableTick(
            tokenInWhichToShowPrices === 'token0' ? tickToCalc : 1 / tickToCalc,
            selected_pool.feeTier,
            decimalsDiff(selected_pool),
          ),
        );

        const arrayOfTicks = chartData.map((item) => Math.abs(item.tickIdx));
        const tickFound = arrayOfTicks.reduce((a, b) =>
          Math.abs(Number(b) - tick) < Math.abs(Number(a) - tick) ? b : a,
        );
        const diffArray = arrayOfTicks.map((item) => Math.abs(Number(tickFound) - Number(item)));
        const minDiff = Math.min(...diffArray);
        const minDiffIndex = diffArray.indexOf(minDiff);

        if (tickType === 'tickUpper') setCoordinateTickUpper(binWidth * minDiffIndex);
        else setCoordinateTickLower(binWidth * minDiffIndex);

        setNeedToChangeChart(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const lowestPrice = useMemo(() => {
    if (selected_pool?.tick && tickWidth && binWidth) {
      if (tokenInWhichToShowPrices === 'token0')
        return getPriceByTick(
          Number(selected_pool.tick) -
            Number(Math.floor((Math.abs(tickWidth - tickWidth / 12) / binWidth) * selected_pool.feeTier * 200)),
          tokenInWhichToShowPrices === 'token0' ? true : false,
          tokenInWhichToShowPrices === 'token0'
            ? selected_pool.token0Decimals - selected_pool.token1Decimals
            : selected_pool.token1Decimals - selected_pool.token0Decimals,
        ).toPrecision(5);
      else if (tokenInWhichToShowPrices === 'token1')
        return getPriceByTick(
          Number(selected_pool.tick) +
            Number(Math.floor((Math.abs(refChart.current.offsetWidth / 2) / binWidth) * selected_pool.feeTier * 200)),
          tokenInWhichToShowPrices === 'token0' ? true : false,
          tokenInWhichToShowPrices === 'token0'
            ? selected_pool.token0Decimals - selected_pool.token1Decimals
            : selected_pool.token1Decimals - selected_pool.token0Decimals,
        ).toPrecision(5);
    }
  }, [selected_pool, tickWidth, binWidth, tokenInWhichToShowPrices]);

  const middlePrice = useMemo(() => {
    if (selected_pool?.tick && tickWidth && binWidth)
      return getPriceByTick(
        Number(selected_pool.tick) -
          Number(Math.floor((Math.abs(tickWidth - tickWidth / 1) / binWidth) * selected_pool.feeTier * 200)),
        tokenInWhichToShowPrices === 'token0' ? true : false,
        tokenInWhichToShowPrices === 'token0'
          ? selected_pool.token0Decimals - selected_pool.token1Decimals
          : selected_pool.token1Decimals - selected_pool.token0Decimals,
      ).toPrecision(5);
  }, [selected_pool, tickWidth, binWidth, tokenInWhichToShowPrices]);

  const highestPrice = useMemo(() => {
    if (selected_pool?.tick && tickWidth && binWidth) {
      if (tokenInWhichToShowPrices === 'token0')
        return getPriceByTick(
          Number(selected_pool.tick) +
            Number(Math.floor((Math.abs(refChart.current.offsetWidth / 2) / binWidth) * selected_pool.feeTier * 200)),
          tokenInWhichToShowPrices === 'token0' ? true : false,
          tokenInWhichToShowPrices === 'token0'
            ? selected_pool.token0Decimals - selected_pool.token1Decimals
            : selected_pool.token1Decimals - selected_pool.token0Decimals,
        ).toPrecision(5);
      else if (tokenInWhichToShowPrices === 'token1')
        return getPriceByTick(
          Number(selected_pool.tick) -
            Number(Math.floor((Math.abs(tickWidth - tickWidth / 12) / binWidth) * selected_pool.feeTier * 200)),
          tokenInWhichToShowPrices === 'token0' ? true : false,
          tokenInWhichToShowPrices === 'token0'
            ? selected_pool.token0Decimals - selected_pool.token1Decimals
            : selected_pool.token1Decimals - selected_pool.token0Decimals,
        ).toPrecision(5);
    }
  });

  useEffect(() => changeTick('tickUpper'), [tickData?.tickUpper]);
  useEffect(() => changeTick('tickLower'), [tickData?.tickLower]);
  useEffect(() => {
    if (chartData) {
      changeTick('tickUpper', true);
      changeTick('tickLower', true);
    }
  }, [chartData, tokenInWhichToShowPrices]);

  return (
    <div ref={refChart} className='mt-2'>
      {canShowChart ? (
        <>
          <svg width='-webkit-fill-avaiable' height={CHART_HEIGHT} id='chart'>
            {chartData &&
              maxLiquidityBin &&
              tickWidth &&
              cumulativeLiquidityBins.map((bin, index) => (
                <LiquidityBin
                  key={index}
                  liquidity={bin}
                  binWidth={binWidth}
                  count={index + 1}
                  maxLiquidityBin={maxLiquidityBin}
                  tickIndex={tickIndex}
                  coordinateTickLower={coordinateTickLower}
                  coordinateTickUpper={coordinateTickUpper}
                />
              ))}
            <use xlinkHref='#tickCentral' />

            {refChart?.current?.offsetWidth &&
              coordinateTickLower > 0 &&
              coordinateTickLower < refChart.current.offsetWidth && (
                <g
                  onMouseDown={() => {
                    setMouseDownLower(true);
                    setStartingXLower(globalCoords.x);
                  }}
                  onMouseUp={() => {
                    const coordinate = coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0);

                    const tick =
                      tokenInWhichToShowPrices === 'token0'
                        ? getPriceByTick(
                            coordinate > tickWidth
                              ? Number(selected_pool.tick) +
                                  Number(
                                    Math.floor(
                                      (Math.abs(coordinate - tickWidth) / binWidth) * selected_pool.feeTier * 200,
                                    ),
                                  )
                              : Number(selected_pool.tick) -
                                  Number(
                                    Math.floor(
                                      (Math.abs(tickWidth - coordinate) / binWidth) * selected_pool.feeTier * 200,
                                    ),
                                  ),
                            tokenInWhichToShowPrices === 'token0' ? true : false,
                            tokenInWhichToShowPrices === 'token0'
                              ? selected_pool.token0Decimals - selected_pool.token1Decimals
                              : selected_pool.token1Decimals - selected_pool.token0Decimals,
                          ).toPrecision(5)
                        : (
                            1 /
                            getPriceByTick(
                              coordinate > tickWidth
                                ? Number(selected_pool.tick) -
                                    Number(
                                      Math.floor(
                                        (Math.abs(coordinate - tickWidth) / binWidth) * selected_pool.feeTier * 200,
                                      ),
                                    )
                                : Number(selected_pool.tick) +
                                    Number(
                                      Math.floor(
                                        (Math.abs(tickWidth - coordinate) / binWidth) * selected_pool.feeTier * 200,
                                      ),
                                    ),
                              true,
                              decimalsDiff(selected_pool),
                            )
                          ).toPrecision(5);

                    handleChangeTick(tick, 'Lower');
                    setCoordinateTickLower(coordinate);
                    setMouseDownLower(false);
                  }}
                >
                  <rect
                    x={Math.min(
                      Math.max(coordinateTickLower - 55 - (mouseDownLower ? startingXLower - globalCoords.x : 0), 12),
                      refChart.current.offsetWidth - 65,
                    )}
                    y='0'
                    width='60'
                    height='20'
                    fill='#525252'
                    rx={5}
                  ></rect>
                  {chartData[tickIndex]?.tickIdx && (
                    <text
                      x={Math.min(
                        Math.max(coordinateTickLower - 50 - (mouseDownLower ? startingXLower - globalCoords.x : 0), 12),
                        refChart.current.offsetWidth - 65,
                      )}
                      y='15'
                      className='text-[0.90rem] font-medium'
                      z={10}
                      fill='#FFFFFF'
                    >
                      {Number(tickData?.tickLower) >
                        (tokenInWhichToShowPrices === 'token0'
                          ? getPriceByTick(selected_pool.tick, true, decimalsDiff(selected_pool))
                          : 1 / getPriceByTick(selected_pool.tick, true, decimalsDiff(selected_pool))) && '+'}
                      {(
                        ((Number(tickData?.tickLower) -
                          (tokenInWhichToShowPrices === 'token0'
                            ? getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool))
                            : 1 / getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool)))) /
                          Math.abs(
                            tokenInWhichToShowPrices === 'token0'
                              ? getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool))
                              : 1 / getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool)),
                          )) *
                        100
                      ).toFixed(2)}
                      %
                    </text>
                  )}
                  <line
                    x1={`${Math.min(
                      Math.max(coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0), 10),
                      refChart.current.offsetWidth - 12,
                    )}`}
                    y1='20'
                    x2={`${Math.min(
                      Math.max(coordinateTickLower - (mouseDownLower ? startingXLower - globalCoords.x : 0), 10),
                      refChart.current.offsetWidth - 12,
                    )}`}
                    y2={CHART_HEIGHT}
                    strokeWidth={2}
                    stroke='rgb(82, 15, 236)'
                  />
                  <rect
                    x={`${Math.max(
                      Math.min(-coordinateTickLower + (mouseDownLower ? startingXLower - globalCoords.x : 0), -10),
                      -refChart.current.offsetWidth + 12,
                    )}`}
                    width='30'
                    height={CHART_HEIGHT}
                    rx='2'
                    transform='matrix(-1 0 0 1 10 10)'
                    fill='red'
                    opacity={0}
                  />
                  <rect
                    x={`${Math.max(
                      Math.min(-coordinateTickLower + 10 + (mouseDownLower ? startingXLower - globalCoords.x : 0), 0),
                      -refChart.current.offsetWidth + 12,
                    )}`}
                    width='10'
                    height='15'
                    rx='2'
                    transform='matrix(-1 0 0 1 10 20)'
                    fill='#520FEC'
                  />
                  <path
                    transform={`translate(${Math.min(
                      Math.max(coordinateTickLower - 10 - (mouseDownLower ? startingXLower - globalCoords.x : 0), 0),
                      refChart.current.offsetWidth - 12,
                    )}, 20)`}
                    d='M7 4V11'
                    stroke='white'
                    strokeLinecap='round'
                  />
                  <path
                    transform={`translate(${Math.min(
                      Math.max(coordinateTickLower - 10 - (mouseDownLower ? startingXLower - globalCoords.x : 0), 0),
                      refChart.current.offsetWidth - 12,
                    )}, 20)`}
                    d='M3 4V11'
                    stroke='white'
                    strokeLinecap='round'
                  />
                </g>
              )}

            {refChart?.current?.offsetWidth &&
              coordinateTickUpper > 0 &&
              coordinateTickUpper < refChart.current.offsetWidth && (
                <g
                  onMouseDown={() => {
                    setMouseDownUpper(true);
                    setStartingXUpper(globalCoords.x);
                  }}
                  onMouseUp={() => {
                    const coordinate = coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0);

                    const tick =
                      tokenInWhichToShowPrices === 'token0'
                        ? getPriceByTick(
                            coordinate > tickWidth
                              ? Number(selected_pool.tick) +
                                  Number(
                                    Math.floor(
                                      (Math.abs(coordinate - tickWidth) / binWidth) * selected_pool.feeTier * 200,
                                    ),
                                  )
                              : Number(selected_pool.tick) -
                                  Number(
                                    Math.floor(
                                      (Math.abs(tickWidth - coordinate) / binWidth) * selected_pool.feeTier * 200,
                                    ),
                                  ),
                            true,
                            decimalsDiff(selected_pool),
                          ).toPrecision(5)
                        : (
                            1 /
                            getPriceByTick(
                              coordinate > tickWidth
                                ? Number(selected_pool.tick) -
                                    Number(
                                      Math.floor(
                                        (Math.abs(coordinate - tickWidth) / binWidth) * selected_pool.feeTier * 200,
                                      ),
                                    )
                                : Number(selected_pool.tick) +
                                    Number(
                                      Math.floor(
                                        (Math.abs(tickWidth - coordinate) / binWidth) * selected_pool.feeTier * 200,
                                      ),
                                    ),
                              true,
                              decimalsDiff(selected_pool),
                            )
                          ).toPrecision(5);

                    handleChangeTick(tick, 'Upper');
                    setCoordinateTickUpper(coordinate);
                    setMouseDownUpper(false);
                  }}
                >
                  <rect
                    x={Math.min(
                      Math.max(coordinateTickUpper - 5 - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      refChart.current.offsetWidth - 65,
                    )}
                    y='0'
                    width='60'
                    height='20'
                    fill='#525252'
                    rx={5}
                  ></rect>
                  {chartData[tickIndex]?.tickIdx && (
                    <text
                      x={Math.min(
                        Math.max(coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                        refChart.current.offsetWidth - 65,
                      )}
                      y='15'
                      className='text-[0.90rem] font-medium'
                      z={10}
                      fill='#FFFFFF'
                    >
                      {Number(tickData?.tickUpper) >
                        (tokenInWhichToShowPrices === 'token0'
                          ? getPriceByTick(selected_pool.tick, true, decimalsDiff(selected_pool))
                          : 1 / getPriceByTick(selected_pool.tick, true, decimalsDiff(selected_pool))) && '+'}
                      {(
                        ((Number(tickData?.tickUpper) -
                          (tokenInWhichToShowPrices === 'token0'
                            ? getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool))
                            : 1 / getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool)))) /
                          Math.abs(
                            tokenInWhichToShowPrices === 'token0'
                              ? getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool))
                              : 1 / getPriceByTick(chartData[tickIndex].tickIdx, true, decimalsDiff(selected_pool)),
                          )) *
                        100
                      ).toFixed(2)}
                      %
                    </text>
                  )}
                  <line
                    x1={`${Math.min(
                      Math.max(coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      refChart.current.offsetWidth - 12,
                    )}`}
                    y1='20'
                    x2={`${Math.min(
                      Math.max(coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      refChart.current.offsetWidth - 12,
                    )}`}
                    y2={CHART_HEIGHT}
                    strokeWidth={2}
                    stroke='rgb(82, 15, 236)'
                  />
                  <rect
                    x={`${Math.max(
                      Math.min(-coordinateTickUpper - 10 + (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      -refChart.current.offsetWidth + 12,
                    )}`}
                    width='30'
                    height={CHART_HEIGHT}
                    rx='2'
                    transform='matrix(-1 0 0 1 10 10)'
                    fill='red'
                    opacity={0}
                  />

                  <rect
                    x={`${Math.max(
                      Math.min(-coordinateTickUpper + (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      -refChart.current.offsetWidth + 12,
                    )}`}
                    width='10'
                    height='15'
                    rx='2'
                    transform='matrix(-1 0 0 1 10 20)'
                    fill='#520FEC'
                  />
                  <path
                    transform={`translate(${Math.min(
                      Math.max(coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      refChart.current.offsetWidth - 12,
                    )}, 20)`}
                    d='M7 4V11'
                    stroke='white'
                    strokeLinecap='round'
                  />
                  <path
                    transform={`translate(${Math.min(
                      Math.max(coordinateTickUpper - (mouseDownUpper ? startingXUpper - globalCoords.x : 0), 12),
                      refChart.current.offsetWidth - 12,
                    )}, 20)`}
                    d='M3 4V11'
                    stroke='white'
                    strokeLinecap='round'
                  />
                </g>
              )}
          </svg>

          <div id='price-bar' className='grid grid-cols-3 mt-2'>
            <p className='text-sm text-left ml-2'>{lowestPrice}</p>
            <p className='text-sm text-center '>{middlePrice}</p>
            <p className='text-sm text-right mr-2'>{highestPrice}</p>
          </div>
        </>
      ) : (
        <div className='h-[226px] rounded-xl bg-gray-200 animate-pulse' />
      )}
    </div>
  );
}

// liquidity: the amount of liquidity in the specific bin
// count: an incremental positional number for the width (starting from 1)
// maxLiquidityBin: the bin with the max amount of liquidity
const LiquidityBin = ({
  liquidity,
  count,
  maxLiquidityBin,
  binWidth,
  tickIndex,
  coordinateTickLower,
  coordinateTickUpper,
}) => {
  const binLiquidityRatio = (Math.abs(liquidity) / maxLiquidityBin) * 0.93;
  const binHeight = CHART_HEIGHT * binLiquidityRatio;

  const translateX = binWidth * count;
  const translateY = CHART_HEIGHT - binHeight;
  return (
    <>
      <rect
        x='0'
        transform={`translate(${translateX},${translateY})`}
        width={binWidth < 2 && tickIndex === count - 1 ? 2 : binWidth}
        height={binHeight}
        fill={`${
          tickIndex === count - 1
            ? '#520FEC'
            : translateX > coordinateTickLower && translateX < coordinateTickUpper
            ? '#44FFD1'
            : '#C2C2C2'
        }`}
        id={tickIndex === count - 1 ? 'tickCentral' : ''}
      />
      {tickIndex === count - 1 ? (
        <rect
          x='0'
          transform={`translate(${translateX},25)`}
          width={binWidth > 2 ? binWidth : 2}
          height={CHART_HEIGHT}
          fill={`${tickIndex === count - 1 ? '#520FEC' : '#44FFD1'}`}
        />
      ) : null}
    </>
  );
};
