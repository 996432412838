const InfoCircle = (props) => (
  <svg width={18} height={18} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity={0.5}>
      <circle cx={9} cy={9} r={8.35} stroke='#525252' strokeWidth={1.3} />
      <path
        d='M8.438 12.286v-5.52h1.19v5.52h-1.19Zm-1.95 0v-.97h5.01v.97h-5.01Zm.71-4.55v-.97h1.83v.97h-1.83Zm1.17-1.84v-1.22h1.3v1.22h-1.3Z'
        fill='#525252'
      />
    </g>
  </svg>
);

export default InfoCircle;
