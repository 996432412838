import { useEffect, useState } from 'react';
import { useSharedState } from '../../../context/store';
import { getDollarFormat } from '../../../utils/utils';
import InfoCircle from '../../icons/InfoCircle';

export default function PositionData() {
  const [{ analytics }] = useSharedState();
  const [marketValueVariation, setMarketValueVariation] = useState(0);
  const [hodlValueVariation, setHodlValueVariation] = useState(0);

  const calcValueVariation = (startValue, endValue) => {
    if (endValue > startValue) {
      const valueVariation = ((endValue - startValue) / startValue) * 100;
      if (isNaN(valueVariation)) return 0;
      return `+${valueVariation.toFixed(2)}%`;
    } else {
      const valueVariation = ((startValue - endValue) / startValue) * 100;
      if (isNaN(valueVariation)) return 0;

      return `-${valueVariation.toFixed(2)}%`;
    }
  };
  useEffect(() => {
    setMarketValueVariation(
      calcValueVariation(
        analytics?.dailySnapshots[0]?.marketValueUSD,
        analytics?.dailySnapshots[analytics?.dailySnapshots.length - 1]?.marketValueUSD,
      ),
    );

    setHodlValueVariation(
      calcValueVariation(
        analytics?.dailySnapshots[0]?.HODLValueUSD,
        analytics?.dailySnapshots[analytics?.dailySnapshots.length - 1]?.HODLValueUSD,
      ),
    );
  }, [analytics?.dailySnapshots]);

  return (
    <>
      <div className='flex w-full h-full justify-between items-start'>
        <div id='market-value' className='flex flex-col h-full'>
          <div className='flex'>
            <h2 className='work-sans-bold text-gray border-b-2 border-secondary'>Market Value</h2>
            <div className='relative'>
              <InfoCircle className='ml-2 analytics-info' />
              <div className='hint opacity-100 rounded-2xl z-10'>
                <div className='inner-hint border-primary border-2'>
                  <p className='primary text-sm'>
                    The market value of your selected LP position, with generated and collected fees.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full h-full flex flex-col justify-end'>
            <p className='m-0 text-md work-sans-normal text-gray-500'>Total</p>
            <div className='flex'>
              <h3 className='m-0 azeret primary text-xl font-medium'>
                {analytics?.marketValue ? getDollarFormat(analytics.marketValue) : '–'}
              </h3>
              {marketValueVariation ? (
                <h5
                  className={`h-fit my-auto ml-2 text-sm font-bold ${
                    marketValueVariation[0] === '-' ? 'text-red-600' : 'text-emerald-400'
                  }`}
                >
                  {marketValueVariation}
                </h5>
              ) : null}
            </div>
          </div>
        </div>

        <div id='hodl-value' className='flex flex-col h-full'>
          <div className='flex'>
            <h2 className='work-sans-bold text-gray border-b-2 border-primary'>HODL Value</h2>
            <div className='relative'>
              <InfoCircle className='ml-2 analytics-info' />
              <div className='hint opacity-100 rounded-2xl z-10'>
                <div className='inner-hint border-primary border-2'>
                  <p className='primary text-sm'>
                    The current hypothetical market value of your initial tokens in their original quantities.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full h-full flex flex-col justify-end'>
            <p className='m-0 text-md work-sans-normal text-gray-500'>Total</p>
            <div className='flex'>
              <h3 className='m-0 azeret primary text-xl font-medium'>
                {analytics?.hodlValue ? getDollarFormat(analytics.hodlValue) : '–'}
              </h3>
              {hodlValueVariation ? (
                <h5
                  className={`h-fit my-auto ml-2 text-sm font-bold ${
                    hodlValueVariation[0] === '-' ? 'text-red-600' : 'text-emerald-400'
                  }`}
                >
                  {hodlValueVariation}
                </h5>
              ) : null}
            </div>
          </div>
        </div>

        <div id='apr' className='flex flex-col h-full'>
          <div className='flex'>
            <h2 className='work-sans-bold text-gray'>APR</h2>
            <div className='relative'>
              <InfoCircle className='ml-2 analytics-info' />
              <div className='hint opacity-100 rounded-2xl z-10'>
                <div className='inner-hint border-primary border-2'>
                  <p className='primary text-sm'>
                    The annualized APR of your selected LP position, calculated by fees generated over position value.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full h-full flex flex-col justify-end'>
            <p className='m-0 text-md work-sans-normal text-gray-500'>Total</p>
            <h3 className='m-0 azeret primary text-xl font-medium'>
              {analytics?.apr ? `${(analytics.apr * 100).toFixed(2)}%` : '–'}
            </h3>
          </div>
        </div>

        <div id='generated-fees' className='flex flex-col h-full'>
          <div className='flex'>
            <h2 className='work-sans-bold text-gray border-b-2 border-orange-300'>Generated Fees</h2>
            <div className='relative'>
              <InfoCircle className='ml-2 analytics-info' />
              <div className='hint opacity-100 rounded-2xl z-10'>
                <div className='inner-hint border-primary border-2'>
                  <p className='primary text-sm'>
                    The daily generated fees of your selected LP position, converted in USD.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full h-full flex flex-col justify-end'>
            <p className='m-0 text-md work-sans-normal text-gray-500'>Total</p>
            <h3 className='m-0 azeret primary text-xl font-medium'>
              {analytics?.generatedFees ? getDollarFormat(analytics.generatedFees) : '–'}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
