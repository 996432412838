import OrbitLoader from '../icons/OrbitLoader';

export default function FullPageLoader() {
  return (
    <div
      id='loading-card'
      className={`overflow-hidden w-full h-[566px] bg-white grid place-items-center shadow-md rounded-xl my-6`}
    >
      <div className='w-36 mb-3'>
        <OrbitLoader />
      </div>
    </div>
  );
}
