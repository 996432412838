import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSharedState } from '../../context/store';
import { useWallet } from '../../hooks/useWallet';
import addresses from '../../contracts/addresses';
import ConnectorModal from './ConnectorModal';
import MobileMoreSvg from '../icons/MobileMoreSvg';
import MobileDropdownMenu from './MobileDropdownMenu';
import withdrawIcon from '../../images/withdraw.svg';

export default function WalletHeader() {
  const [{ chain_id }] = useSharedState();
  const { changeNetwork } = useWallet();
  const [showNetworks, setShowNetworks] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  const options = Object.keys(addresses).map((key) => ({
    label: (
      <>
        <img
          src={require(`../../images/${addresses[key].image}`)}
          style={{ width: '1.6rem', height: '100%' }}
          alt={addresses[key].shortName}
        />
      </>
    ),
    value: key,
    name: addresses[key].shortName,
    key,
  }));

  const defaultOption = options[0];
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === chain_id) || defaultOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain_id, pathname]);

  return (
    <>
      <div className='row pt-8 gap-0'>
        {/* Mobile footer */}
        {!showNetworks && !showMenu ? (
          <div className='d-md-none d-block sticky-footer background-light-gray'>
            <div className='flex items-center justify-center'>
              <div className='d-block d-lg-none'>
                <div
                  className='t-align-center rounded-xl arrow-down bg-second custom-p h-12'
                  onClick={() => setShowNetworks(true)}
                >
                  {selectedOption ? selectedOption.label : defaultOption.label}
                </div>
              </div>
              <div className='d-block d-lg-none mx-4'>
                <ConnectorModal />
              </div>
              <div className='d-block d-lg-none'>
                <div className='flex bg-second h-12 rounded-xl place-content-center items-center px-3'>
                  {' '}
                  <MobileMoreSvg onClick={() => setShowMenu(true)} />
                </div>
              </div>
            </div>
          </div>
        ) : showNetworks ? (
          <div className='d-md-none d-block sticky-footer background-light-gray p-4'>
            <div className='flex'>
              <p className='primary uppercase work-sans-bold text-lg'>Select network</p>
              <img
                src={require('../../images/cross.svg').default}
                className='ml-auto'
                alt='close menu'
                onClick={() => setShowNetworks(false)}
              />
            </div>
            <div className='mt-4 row items-center justify-center'>
              {options.map((option) => (
                <div className='col-6' key={option.key}>
                  <div
                    className={`${
                      option.key === selectedOption.key
                        ? 't-align-center rounded-xl bg-white selected custom-p h-12 flex'
                        : 't-align-center rounded-xl bg-second custom-p h-12 flex'
                    }`}
                    onClick={() => {
                      setSelectedOption(option);
                      changeNetwork(parseInt(option.key));
                      setShowNetworks(false);
                    }}
                  >
                    {option.label} <p className='primary azeret ml-2 self-center'>{option.name}</p>
                  </div>
                </div>
              ))}
              <div className='col-6'></div>
            </div>
          </div>
        ) : (
          <>
            {showMenu && (
              <div className='d-md-none d-block sticky-footer background-light-gray p-4'>
                <div className='row'>
                  <MobileDropdownMenu open={showMenu} />
                  <img
                    src={require('../../images/cross.svg').default}
                    className='ml-auto mb-auto py-2 px-4'
                    onClick={() => setShowMenu(false)}
                    alt='close menu'
                  />
                </div>
                <div className='pt-2 mt-1 border-t-2 border-gray-palette'>
                  <button
                    className='group flex w-full items-center rounded-md pl-2 py-2 text-sm'
                    onClick={() => window.open('/withdraw-erc20', '_blank')}
                  >
                    <img src={withdrawIcon} alt='withdraw erc20' className={`mr-2 h-8 w-10`} aria-hidden='true' />
                    <span className='text-xl uppercase primary work-sans-bold pl-1'>Withdraw erc20</span>
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
