import { gql } from '@apollo/client';
import ApolloClient from './ApolloClient';

const GET_TOP_POOLS = `
  query topPools {
    pools(orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
      id
    }
  }
`;

export const getTopPools = async (chain_id) => {
  const source = 'uniswapSubGraph';
  const result = await ApolloClient(chain_id, source).query({
    query: gql(GET_TOP_POOLS),
  });

  return result.data.pools;
};
