import { getPositionManagerFactory } from '../helpers/contracts';
import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';

export const usePositionManager = () => {
  const [{ chain_id, provider }] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const createPositionManager = async () => {
    try {
      const signer = await provider.getSigner();
      const PositionManagerFactory = getPositionManagerFactory(chain_id, signer);

      const gasLimitEstimate = await PositionManagerFactory.estimateGas.create();

      setWalletActionRequired(true);
      const tx = await PositionManagerFactory.create({ gasLimit: gasLimitEstimate.add(150000) });
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Creating your Smart Vault');
    } catch (err) {
      setWalletActionRequired(false);
      console.error(err?.message);
      notify('error', "Couldn't create your Smart Vault");
    }
  };

  return { createPositionManager };
};
