import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { getWithdrawRecipes } from '../helpers/contracts';
import actions from '../context/actions';

export const useWithdraw = () => {
  const [{ account, provider, chain_id, positions, selected_position }, dispatch] = useSharedState();
  const { notify, setWalletActionRequired, notifyLoadingAsyncTx } = useNotifications();

  const withdrawFromUniswap = async (shares) => {
    try {
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);
      shares = shares.replace('%', '');

      const gasLimitEstimate = await WithdrawRecipes.estimateGas.withdrawUniNft(selected_position.id, shares * 100);

      setWalletActionRequired(true);
      const tx = await WithdrawRecipes.withdrawUniNft(selected_position.id, shares * 100, {
        gasLimit: gasLimitEstimate.add(150000),
      });
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Withdrawing your position from Uniswap');
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during withdraw');
      console.error(err?.message);
    }
  };

  const withdrawFromAave = async (shares) => {
    try {
      shares = shares.replace('%', '');
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);

      const gasLimitEstimate = await WithdrawRecipes.estimateGas.withdrawFromAave(selected_position.id, shares * 100);

      setWalletActionRequired(true);
      const tx = await WithdrawRecipes.withdrawFromAave(selected_position.id, shares * 100, {
        gasLimit: gasLimitEstimate.add(150000),
      });
      setWalletActionRequired(false);

      const success = await notifyLoadingAsyncTx(tx, 'Withdrawing your position from Aave');

      const positionsDeepCopy = JSON.parse(JSON.stringify(positions));
      const positionsLeft = positionsDeepCopy.filter((position) => position.id !== selected_position.id);
      if (success) dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: positionsLeft } });
    } catch (err) {
      setWalletActionRequired(false);
      notify('error', 'Error during withdraw');
      console.error(err?.message);
    }
  };

  const getAaveWithdrawTokenAmount = async (shares) => {
    try {
      shares = shares.replace('%', '');
      const signer = await provider.getSigner();
      const WithdrawRecipes = getWithdrawRecipes(chain_id, signer);

      if (!selected_position?.isOnAave) throw new Error('Selected position is not on Aave');

      const amountWithdrawn = await WithdrawRecipes.callStatic.withdrawFromAave(selected_position.id, shares * 100, {
        gasLimit: 3e6,
        from: account,
      });

      return Number(amountWithdrawn);
    } catch (err) {
      console.warn('Amounts preview error: ', err?.message);
    }
  };

  return { withdrawFromUniswap, withdrawFromAave, getAaveWithdrawTokenAmount };
};
