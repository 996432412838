import { getDepositRecipes, getERC20, getNonFungiblePositionManager } from '../helpers/contracts';
import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import { BigNumber } from 'ethers';

export const useApprove = () => {
  const [{ account, chain_id, provider }] = useSharedState();
  const { setWalletActionRequired, addNotification, updateNotification, removeNotification, notifyLoadingAsyncTx } =
    useNotifications();

  // Approve 'recipientAddress' to spend 'tokenAddress' for 'account'
  const approveToken = async (tokenAddress, recipientAddress) => {
    try {
      const signer = await provider.getSigner();
      const token = getERC20(tokenAddress, signer);

      const tokenSymbol = await token.symbol();
      const tokenDecimals = await token.decimals();

      const amount = BigNumber.from(1e9).mul(BigNumber.from(10).pow(tokenDecimals)).toString();

      // Return if token is already approved
      if (await checkAllowance(tokenAddress, recipientAddress)) return;

      // Custom notification flow: keep user updated when approvals are happening
      setWalletActionRequired(true);
      const approval = await token.approve(recipientAddress, amount, { gasLimit: 200000 });
      setWalletActionRequired(false);

      const notificationId = addNotification('loading', `Approving ${tokenSymbol}`, '');
      await approval.wait();

      updateNotification(notificationId, 'loading', `Waiting for ${tokenSymbol} spending allowance to be updated`, '');

      // Wait for allowance to be updated (max 30 secs)
      var maxTries = 30;
      var hasAllowanceUpdated = await checkAllowance(tokenAddress, recipientAddress);
      for (var tries = 0; tries < maxTries && !hasAllowanceUpdated; tries++) {
        hasAllowanceUpdated = await checkAllowance(tokenAddress, recipientAddress);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      if (hasAllowanceUpdated) {
        // Success case
        updateNotification(notificationId, 'success', `Approved ${tokenSymbol}`, '');
        await new Promise((resolve) => setTimeout(resolve, 6000));
      } else {
        // Failure case
        updateNotification(notificationId, 'error', `Failed to approve ${tokenSymbol}`, '');
        await new Promise((resolve) => setTimeout(resolve, 6000));
      }

      removeNotification(notificationId);
    } catch (err) {
      setWalletActionRequired(false);
      console.error(err?.message);
    }
  };

  // Check 'account' allowance of 'tokenAddress' spending on 'recipientAddress'
  const checkAllowance = async (tokenAddress, recipientAddress) => {
    if (!recipientAddress || !tokenAddress) throw new Error("'recipientAddress' or 'tokenAddress' is undefined");

    try {
      const signer = await provider.getSigner();
      const token = getERC20(tokenAddress, signer);

      const tokenAllowance = await token.allowance(account, recipientAddress);
      const tokenDecimals = await token.decimals();

      const maxAmount = BigNumber.from(1e9).mul(BigNumber.from(10).pow(tokenDecimals));

      // Returns true if the allowance is at least the max amount
      return !tokenAllowance.lte(maxAmount.sub(maxAmount.mul(90).div(100)));
    } catch (err) {
      console.error(err?.message);
    }
  };

  // Check if the account has approved Uniswap's NonfungiblePositionManager for moving its positions
  const checkIsApprovedForAll = async () => {
    try {
      const signer = await provider.getSigner();
      const NonFungiblePositionManager = getNonFungiblePositionManager(chain_id, signer);
      const DepositRecipes = getDepositRecipes(chain_id, signer);

      return await NonFungiblePositionManager.isApprovedForAll(account, DepositRecipes.address);
    } catch (err) {
      console.error(err?.message);
      return false;
    }
  };

  const setApprovalForAll = async () => {
    try {
      const signer = await provider.getSigner();
      const NonFungiblePositionManager = getNonFungiblePositionManager(chain_id, signer);
      const DepositRecipes = getDepositRecipes(chain_id, signer);

      const gasLimitEstimate = await NonFungiblePositionManager.estimateGas.setApprovalForAll(
        DepositRecipes.address,
        true,
      );

      setWalletActionRequired(true);
      const tx = await NonFungiblePositionManager.setApprovalForAll(DepositRecipes.address, true, {
        gasLimit: gasLimitEstimate.add(150000),
      });
      setWalletActionRequired(false);

      await notifyLoadingAsyncTx(tx, 'Approving to move your positions to the Smart Vault');
    } catch (err) {
      console.error(err?.message);
    }
  };

  return { checkAllowance, approveToken, checkIsApprovedForAll, setApprovalForAll };
};
