import { ethers, BigNumber } from 'ethers';
import { getERC20, getNonFungiblePositionManager } from '../helpers/contracts';
import { getAmountsForLiquidity, getPriceByTick, getV3PoolAddress } from '../utils/utils';
import { useSharedState } from '../context/store';
import { useTokenValue } from './useTokenValue';
import { usePools } from './usePools';

export const useUniswapPositions = () => {
  const [{ account, provider, chain_id }] = useSharedState();
  const { getEthUsdcValue } = useTokenValue();
  const { getUniPool } = usePools();

  const getUniswapPositions = async () => {
    if (provider) {
      try {
        const signer = provider.getSigner();
        const NonfungiblePositionManager = getNonFungiblePositionManager(chain_id, signer);
        if (!NonfungiblePositionManager) return null;

        const balanceResult = await NonfungiblePositionManager.balanceOf(account);
        const accountBalance = balanceResult.toNumber();

        const tokenIdResults = [];
        for (let i = 0; i < accountBalance; i++) {
          tokenIdResults.push(await NonfungiblePositionManager.tokenOfOwnerByIndex(account, i));
        }
        const tokenIds = tokenIdResults.map((result) => result.toNumber());

        const positions = await Promise.all(
          tokenIds.map(async (tokenId) => {
            const position = await NonfungiblePositionManager.positions(tokenId);
            if (Number(position.liquidity) === 0) return;

            const token0symbol = await getERC20(position.token0, signer).symbol();
            const token1symbol = await getERC20(position.token1, signer).symbol();
            const token0decimals = await getERC20(position.token0, signer).decimals();
            const token1decimals = await getERC20(position.token1, signer).decimals();

            const poolAddress = getV3PoolAddress(
              chain_id,
              position.token0,
              position.token1,
              token0decimals,
              token1decimals,
              position.fee,
            );

            const uniPool = await getUniPool(poolAddress);
            const ethUsdPrice = await getEthUsdcValue();
            const { token0EthValue, token1EthValue, tick } = uniPool;

            let token0Value, token1Value;

            const { amount0: token0Amount, amount1: token1Amount } = getAmountsForLiquidity(
              tick,
              position.tickLower,
              position.tickUpper,
              position.liquidity,
            );

            const token0AmountWithDecimals = Number(
              ethers.utils.formatUnits(BigNumber.from(token0Amount.toFixed(0)), token0decimals),
            );
            const token1AmountWithDecimals = Number(
              ethers.utils.formatUnits(BigNumber.from(token1Amount.toFixed(0)), token1decimals),
            );
            token0Value = token0AmountWithDecimals * token0EthValue * ethUsdPrice || 0;
            token1Value = token1AmountWithDecimals * token1EthValue * ethUsdPrice || 0;

            const priceByTick0 = getPriceByTick(position.tickLower, true, token0decimals - token1decimals);
            const priceByTick1 = getPriceByTick(position.tickUpper, true, token0decimals - token1decimals);
            const range = `${priceByTick0.toPrecision(3)}-${priceByTick1.toPrecision(3)}`;

            return {
              tokenId: tokenId.toString(),
              token0: token0symbol,
              token1: token1symbol,
              token0Address: position.token0,
              token1Address: position.token1,
              token0Decimals: token0decimals,
              token1Decimals: token1decimals,
              value: token0Value + token1Value,
              liquidity: position.liquidity.toString(),
              range: range,
              tickLower: position.tickLower.toString(),
              tickUpper: position.tickUpper.toString(),
              fee: (Number(position.fee) / 10000).toString(),
              apy: '-%',
            };
          }),
        );

        return positions;
      } catch (err) {
        console.error(err?.message);
      }
    } else console.error('No provider found');
  };

  return { getUniswapPositions };
};
