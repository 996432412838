import { Contract } from 'ethers';
import NonFungiblePositionManagerABI from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json';
import PositionManagerFactoryABI from '../contracts/abi/PositionManagerFactory.json';
import PositionManagerABI from '../contracts/abi/PositionManager.json';
import WithdrawRecipesABI from '../contracts/abi/WithdrawRecipes.json';
import DepositRecipesABI from '../contracts/abi/DepositRecipes.json';
import LendingPoolV2ABI from '../contracts/abi/LendingPoolV2.json';
import AaveTokenABI from '../contracts/abi/AaveToken.json';
import ERC20ABI from '../contracts/abi/ERC20.json';
import addresses from '../contracts/addresses';

export const getPositionManager = async (account, chain_id, signer) => {
  const PositionManagerAddress = await checkPositionManagerExists(account, chain_id, signer);
  if (PositionManagerAddress === null) return null;
  return new Contract(PositionManagerAddress, PositionManagerABI.abi, signer);
};

export const getNonFungiblePositionManager = (chain_id, signer) => {
  return new Contract(addresses[chain_id].NonFungiblePositionManager, NonFungiblePositionManagerABI.abi, signer);
};

export const getPositionManagerFactory = (chain_id, signer) => {
  return new Contract(addresses[chain_id].PositionManagerFactory, PositionManagerFactoryABI.abi, signer);
};

export const getDepositRecipes = (chain_id, signer) => {
  return new Contract(addresses[chain_id].DepositRecipes, DepositRecipesABI.abi, signer);
};

export const getWithdrawRecipes = (chain_id, signer) => {
  return new Contract(addresses[chain_id].WithdrawRecipes, WithdrawRecipesABI.abi, signer);
};

export const getLendingPoolV2 = (chain_id, signer) => {
  return new Contract(addresses[chain_id].LendingPoolV2, LendingPoolV2ABI, signer);
};

export const getERC20 = (address, signer) => {
  return new Contract(address, ERC20ABI, signer);
};

export const getAaveToken = (address, signer) => {
  return new Contract(address, AaveTokenABI, signer);
};

export const checkPositionManagerExists = async (account, chain_id, signer) => {
  const PositionManagerFactory = getPositionManagerFactory(chain_id, signer);
  try {
    const positionManagerAddress = await PositionManagerFactory.userToPositionManager(account);
    if (positionManagerAddress === '0x0000000000000000000000000000000000000000') return null;
    return positionManagerAddress;
  } catch (err) {
    console.warn('No position manager found');
    return null;
  }
};
