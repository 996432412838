import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { SharedStateProvider } from './context/store';
import reportWebVitals from './reportWebVitals';
import Client from './api/ApolloClient';
import App from './App';
import './styles/index.scss';

ReactDOM.render(
  <SharedStateProvider>
    <ApolloProvider client={Client}>
      <App />
    </ApolloProvider>
  </SharedStateProvider>,
  document.getElementById('root'),
);

reportWebVitals();
