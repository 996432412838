import { useMemo } from 'react';
import { useSharedState } from '../context/store';
import { useEtherscanApi } from './useEtherscanApi';
import { getPoolTick } from '../utils/utils';
import actions from '../context/actions';

import { getPoolData, getPoolVolumeAtBlock } from '../api/getPoolData';
import { getTopPools } from '../api/getTopPools';
import { getTickData } from '../api/getTickData';

const DEFAULT_CHAIN_ID = 137;

export const usePools = () => {
  const [{ chain_id, provider }, dispatch] = useSharedState();
  const { getBlockNumberByTimestamp } = useEtherscanApi();

  const chainId = useMemo(() => chain_id ?? DEFAULT_CHAIN_ID, [chain_id]);

  const getUniPools = async () => {
    const queryBatchSize = 5;

    const topPoolsIds = await getTopPools(chainId);
    const topPoolsAddresses = topPoolsIds.map((pool) => pool.id);
    const topPoolsBatched = topPoolsAddresses.reduce((acc, cur, i) => {
      if (i % queryBatchSize === 0) acc.push([]);
      acc[acc.length - 1].push(cur);
      return acc;
    }, []);

    const T24 = Math.ceil(Date.now() / 1e3 - 86400);
    const blockT24 = await getBlockNumberByTimestamp(T24);

    const poolDataPromisesCurrentBlock = topPoolsBatched.map((batch) => getPoolData(chainId, batch));
    const poolVolumePromisesT24 = topPoolsBatched.map((batch) => getPoolVolumeAtBlock(chain_id, batch, blockT24));
    const responses = await Promise.all([...poolDataPromisesCurrentBlock, ...poolVolumePromisesT24]);

    const poolDataBatchedResponseCurrentBlock = responses.filter((_, i) => i < poolDataPromisesCurrentBlock.length);
    const poolVolumeBatchedResponseT24 = responses.filter((_, i) => i >= poolDataPromisesCurrentBlock.length);
    const poolsDataCurrentBlock = [].concat(...poolDataBatchedResponseCurrentBlock.map((batch) => batch.pools));
    const poolsVolumeT24 = [].concat(...poolVolumeBatchedResponseT24.map((batch) => batch.pools));

    const formattedPoolsData = poolsDataCurrentBlock.map((pool) => {
      const volumeTot = Number(pool.volumeUSD || 0);
      const volumeTotT24 = Number(poolsVolumeT24.find((p) => p.id === pool.id)?.volumeUSD || 0);
      const volumeT24 = Math.abs(volumeTot - volumeTotT24);

      return {
        token0: pool.token0.symbol,
        token0Address: pool.token0.id,
        token0Decimals: Number(pool.token0.decimals),
        token1: pool.token1.symbol,
        token1Address: pool.token1.id,
        token1Decimals: Number(pool.token1.decimals),
        feeTier: pool.feeTier / 10000,
        tick: Number(pool.tick),
        tvl: +Number(pool.totalValueLockedUSD).toFixed(2),
        volume: +Number(volumeT24).toFixed(2),
        poolAddress: pool.id,
      };
    });

    dispatch({ type: actions.SET_UNI_TOP_POOLS, payload: { uniswap_top_pools: formattedPoolsData } });
    return formattedPoolsData;
  };

  const getUniPool = async (poolAddress) => {
    const poolData = await getPoolData(chainId, [poolAddress.toLowerCase()]);

    const pool = poolData.pools[0];
    const formattedPoolData = {
      token0: pool.token0.symbol,
      token0Address: pool.token0.id,
      token0Decimals: Number(pool.token0.decimals),
      token0EthValue: Number(pool.token0.derivedETH),
      token0Price: pool.token0Price,
      token1: pool.token1.symbol,
      token1Address: pool.token1.id,
      token1Decimals: Number(pool.token1.decimals),
      token1EthValue: Number(pool.token1.derivedETH),
      token1Price: pool.token1Price,
      feeTier: pool.feeTier / 10000,
      tick: Number(pool.tick),
      tvl: +Number(pool.totalValueLockedUSD).toFixed(2),
      volume: +Number(pool.volumeUSD).toFixed(2),
      poolAddress: pool.id,
    };

    return formattedPoolData;
  };

  const updateSelectedPool = async (selectedPool) => {
    const { token0Address, token0Decimals, token1Address, token1Decimals, feeTier } = selectedPool;
    const tick = await getPoolTick(
      chain_id,
      await provider.getSigner(),
      token0Address,
      token1Address,
      token0Decimals,
      token1Decimals,
      feeTier * 10000,
    );
    const pool = { ...selectedPool, tick };
    dispatch({ type: actions.SELECT_POOL, payload: { selected_pool: pool } });
  };

  const getUniswapPoolLiquidity = async (poolAddress, minimumTick, maximumTick) => {
    const poolData = await getTickData(chain_id, poolAddress, minimumTick, maximumTick);

    return poolData.ticks;
  };

  return { getUniPools, getUniPool, updateSelectedPool, getUniswapPoolLiquidity };
};
