export default function Hero() {
  return (
    <div className='md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center'>
      <div className='md:col-span-1 lg:col-span-1 mb-8 md:mb-0 text-center md:text-left'></div>
      <div className='md:col-span-10 lg:col-span-10 mb-8 md:mb-0 text-center md:text-left max-w-6xl'>
        <h1 className='primary text-6xl lg:text-7xl lg:mb-2 md:mb-2 mb-4 work-sans-bold text-center'>
          Liquidity <br /> automation platform
        </h1>
        <h2 className='text-black text-center mx-2 azeret text-2xl'>
          Orbit automates and optimizes your defi <br /> strategies, starting from Uniswap V3
        </h2>
      </div>
      <div className='md:col-span-1 lg:col-span-1 mb-8 md:mb-0 text-center md:text-left'></div>
    </div>
  );
}
