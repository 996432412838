/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';

export const useBackgroundWaves = () => {
  const [isResized, setIsResized] = useState(false);

  const handleChange = useCallback(() => setIsResized(!isResized), []);

  useEffect(() => {
    const wave1 = document.getElementById('wave1');
    const wave2 = document.getElementById('wave2');

    wave1.classList.remove('wave');
    wave2.classList.remove('wave2');
    void wave1.offsetWidth;
    void wave2.offsetWidth;
    wave1.classList.add('wave');
    wave2.classList.add('wave2');
  }, [isResized]);

  useEffect(() => {
    window.addEventListener('resize', handleChange);
    window.addEventListener('load', handleChange);

    return () => {
      window.removeEventListener('resize', handleChange);
      window.removeEventListener('load', handleChange);
    };
  }, []);
};
