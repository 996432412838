import * as React from 'react';

const SelectorIconSmall = (props) => (
  <svg width={11} height={8} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m6.292 7.195 4.382-5.5a.805.805 0 0 0 .167-.38.891.891 0 0 0-.035-.43.732.732 0 0 0-.225-.333.541.541 0 0 0-.34-.126H1.478a.538.538 0 0 0-.345.12.727.727 0 0 0-.231.333.89.89 0 0 0-.038.432c.023.146.082.28.168.385l4.382 5.5a.617.617 0 0 0 .2.17.513.513 0 0 0 .479 0 .617.617 0 0 0 .2-.17Z'
      fill='#520FEC'
    />
  </svg>
);

export default SelectorIconSmall;
