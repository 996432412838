import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSharedState } from '../../context/store';
import OrbitLoader from '../icons/OrbitLoader';

const LoadingModal = () => {
  const [{ loading_state }, dispatch] = useSharedState();
  const open = loading_state === true && loading_state !== 'initial';
  const setOpen = (loading_state) => dispatch({ type: 'SET_LOADING_STATE', payload: loading_state });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0'>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative inline-block bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div>
                  <div className='mx-auto flex items-center justify-center w-25 rounded-full'>
                    <OrbitLoader />
                  </div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <div className='mt-3'>
                      <p className='text-md text-gray-800 azeret'>
                        Please confirm the operation in your wallet to proceed.
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LoadingModal;
