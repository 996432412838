import * as React from 'react';

const BigArrow = (props) => (
  <svg width={19} height={14} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.176.563 1.289 10.777c-.154.193-.259.439-.301.706-.043.268-.02.545.063.797s.224.468.406.62a.957.957 0 0 0 .612.234h15.774a.949.949 0 0 0 .62-.223c.185-.15.33-.365.416-.618.086-.253.11-.533.068-.803a1.517 1.517 0 0 0-.303-.713L10.758.563a1.12 1.12 0 0 0-.36-.317.901.901 0 0 0-.431-.112.901.901 0 0 0-.431.112 1.121 1.121 0 0 0-.36.317Z'
      fill='#520FEC'
    />
  </svg>
);

export default BigArrow;
