/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useSharedState } from '../../../context/store';
import { useZapMint } from '../../../hooks/useZapMint';
import { useApprove } from '../../../hooks/useApprove';
import { getPositionManager } from '../../../helpers/contracts';
import TokenImage from '../../utils/TokenImage';
import addresses from '../../../contracts/addresses';
import BoundsSection from './BoundsSection';
import Chart from './Chart';
import {
  getPriceByTick,
  maxAmount,
  getSuggestedUpperTick,
  getSuggestedLowerTick,
  decimalsDiff,
  getClosestAvailableTick,
  checkIsValidTick,
} from '../../../utils/utils';
import SelectorIconSmall from '../../icons/SelectorIconSmall';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ZapSection() {
  const { zapMint } = useZapMint();
  const [{ account, chain_id, provider, selected_pool }] = useSharedState();
  const { checkAllowance, approveToken } = useApprove();

  const [tokenInWhichToShowPrices, setTokenInWhichToShowPrices] = useState('token0');
  const [needToChangeChart, setNeedToChangeChart] = useState(false);
  const [selectedZapToken, setSelectedZapToken] = useState(null);
  const [zapTokenOptions, setZapTokenOptions] = useState([]);
  const [positionData, setPositionData] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [tickData, setTickData] = useState({});

  // Automatically update amounts when ticks change
  const handleChangeTick = (tick, tickType) => {
    const tickToChange = tickType === 'Lower' ? 'tickLower' : 'tickUpper';
    if (!checkIsValidTick(tick)) return;
    setTickData({ ...tickData, [tickToChange]: tick });

    try {
      const newTick = getClosestAvailableTick(
        tokenInWhichToShowPrices === 'token0' ? tick : 1 / tick,
        selected_pool.feeTier,
        decimalsDiff(selected_pool),
      );

      setPositionData({ ...positionData, [tickToChange]: newTick });
    } catch (err) {
      console.warn(err?.message);
      setPositionData({ ...positionData, [tickToChange]: '0' });
    }
  };

  const handleChangeAmount = (e, tokenNumber) => {
    if (isNaN(e.target.value)) return;
    setPositionData({ ...positionData, [`amount${tokenNumber}Desired`]: e.target.value });
  };

  const selectMaxAmount = async (tokenAddress, tokenNumber) => {
    const max = await maxAmount(await provider.getSigner(), tokenAddress);
    setPositionData({ ...positionData, [`amount${tokenNumber}Desired`]: max });
  };

  const approveTokens = async () => {
    try {
      const PositionManager = await getPositionManager(account, chain_id, await provider.getSigner());

      if (!(await checkAllowance(selectedZapToken.address, PositionManager.address)))
        await approveToken(selectedZapToken.address, PositionManager.address);

      setIsApproved(true);
    } catch (err) {
      console.error(err?.message);
    }
  };

  useEffect(() => {
    var isMounted = true;
    if (selected_pool?.token0Address && selected_pool?.token1Address && selectedZapToken?.address) {
      const checkIsApproved = async () => {
        try {
          const PositionManager = await getPositionManager(account, chain_id, await provider.getSigner());

          const _p1 = checkAllowance(selectedZapToken.address, addresses[chain_id].DepositRecipes);
          const _p2 = checkAllowance(selectedZapToken.address, PositionManager.address);
          const [approved0, approved1] = await Promise.all([_p1, _p2]);

          if (!isMounted) return;
          setIsApproved(approved0 && approved1 && true);
        } catch (err) {
          console.error(err?.message);
        }
      };
      checkIsApproved();
    }

    return () => (isMounted = false);
  }, [selected_pool?.token0Address, selected_pool?.token1Address, selectedZapToken]);

  useEffect(() => {
    if (selected_pool) {
      const getInitialZapTokenOptions = async () => {
        const zapToken = { address: selected_pool.token0Address, symbol: selected_pool.token0 };
        const options = [{ address: selected_pool.token1Address, symbol: selected_pool.token1 }];

        setSelectedZapToken(zapToken);
        setZapTokenOptions(options);
      };
      getInitialZapTokenOptions();
    }
  }, [selected_pool]);

  const handleChangeSelectedZapToken = (newSelectedToken) => {
    // For now, we only keep pool tokens in the list of options.
    // This can be expanded to include other tokens in the future
    // with the use of useToken.getZapTokensInfo() hook.

    if (newSelectedToken.address === selected_pool.token0Address)
      setZapTokenOptions([{ address: selected_pool.token1Address, symbol: selected_pool.token1 }]);

    if (newSelectedToken.address === selected_pool.token1Address)
      setZapTokenOptions([{ address: selected_pool.token0Address, symbol: selected_pool.token0 }]);

    setSelectedZapToken(newSelectedToken);
  };

  const zap = async () => {
    try {
      const { token0Address, token1Address, feeTier } = selected_pool;
      let { tickLower, tickUpper, amount0Desired } = positionData;

      if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(selected_pool?.tick, selected_pool?.feeTier, 10);
      if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(selected_pool?.tick, selected_pool?.feeTier, 10);

      if (tokenInWhichToShowPrices === 'token1') [tickUpper, tickLower] = [tickLower, tickUpper];

      await zapMint(
        token0Address,
        token1Address,
        amount0Desired,
        selectedZapToken.address,
        tickLower,
        tickUpper,
        feeTier,
      );
    } catch (err) {
      console.error(err?.message);
    }
  };

  const currentPrice = useMemo(() => {
    if (selected_pool)
      return tokenInWhichToShowPrices === 'token0'
        ? getPriceByTick(Number(selected_pool.tick), true, decimalsDiff(selected_pool)).toPrecision(5)
        : (1 / getPriceByTick(Number(selected_pool.tick), true, decimalsDiff(selected_pool))).toPrecision(5);
  }, [selected_pool?.tick, tokenInWhichToShowPrices]);

  useEffect(() => setPositionData({ ...positionData, amount0Desired: '', amount1Desired: '' }), [selected_pool]);
  useEffect(() => setPositionData({ ...positionData, amount0Desired: '', amount1Desired: '' }), [selectedZapToken]);

  return (
    <>
      <div className='pb-2 px-4 mt-4'>
        <div className='mb-3 text-center md:text-left background-light-gray rounded-xl'>
          <div className='flex px-4'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary pt-2.5 pb-1 uppercase'>
              Set price range
            </h4>
            {selected_pool && (
              <div className='relative w-fit ml-auto pt-3'>
                <div className='absolute top-3 right-[4px] w-full flex justify-end items-center'>
                  <button
                    className={`rounded-[0.45rem] azeret font-medium text-sm uppercase focus:outline-none ${
                      tokenInWhichToShowPrices === 'token0'
                        ? 'px-2 bg-white z-10 -mr-3 border-2 border-secondary primary'
                        : 'pl-2 pr-3 border-none bg-gray-600 z-0 text-white py-[1px]'
                    }`}
                    onClick={() => setTokenInWhichToShowPrices('token0')}
                  >
                    {selected_pool.token0}
                  </button>
                  <button
                    className={`px-2 rounded-[0.45rem] azeret font-medium text-sm uppercase focus:outline-none ${
                      tokenInWhichToShowPrices === 'token1'
                        ? 'px-2 bg-white z-10 -ml-3 border-2 border-secondary primary'
                        : 'pl-3 pr-2 border-none bg-gray-600 z-0 text-white py-[1px]'
                    }`}
                    onClick={() => setTokenInWhichToShowPrices('token1')}
                  >
                    {selected_pool.token1}
                  </button>
                </div>
              </div>
            )}
          </div>
          {selected_pool ? (
            <div className='flex px-4'>
              <p className='text-gray text-base azeret pb-2'>
                Current Price:{' '}
                <span className='primary font-bold'>
                  {currentPrice}
                  &nbsp;
                  {tokenInWhichToShowPrices === 'token0'
                    ? selected_pool?.token1?.toUpperCase()
                    : selected_pool?.token0?.toUpperCase()}
                </span>{' '}
                per
                <span className='primary font-bold'>
                  {' '}
                  {tokenInWhichToShowPrices === 'token0'
                    ? selected_pool?.token0?.toUpperCase()
                    : selected_pool?.token1?.toUpperCase()}
                </span>
              </p>
            </div>
          ) : (
            <div className='py-[26px]' />
          )}
        </div>
        {tickData ? (
          <Chart
            tokenInWhichToShowPrices={tokenInWhichToShowPrices}
            handleChangeTick={handleChangeTick}
            tickData={tickData}
            needToChangeChart={needToChangeChart}
            setNeedToChangeChart={setNeedToChangeChart}
          />
        ) : (
          ''
        )}
        {positionData && (
          <BoundsSection
            tokenInWhichToShowPrices={tokenInWhichToShowPrices}
            positionData={positionData}
            setPositionData={setPositionData}
            lastAmountChanged={null}
            useSingleToken={false}
            setTickData={setTickData}
            tickData={tickData}
            setNeedToChangeChart={setNeedToChangeChart}
            handleChangeTick={handleChangeTick}
          />
        )}
        <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
          <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
            Deposit amounts
          </h4>
        </div>
        <div className='text-center md:text-left border-primary py-1 gap-x-4 grid grid-cols-2'>
          <div className='col-span-1'>
            {selectedZapToken && (
              <Listbox value={selectedZapToken} onChange={handleChangeSelectedZapToken}>
                {({ open }) => (
                  <>
                    <div className='relative mt-3'>
                      <Listbox.Button
                        className={`relative w-full bg-white border-2 border-primary
                       pl-3 pr-6 py-[5px] text-left cursor-default focus:outline-none
                       ${open ? 'rounded-t-xl' : 'rounded-xl'}`}
                      >
                        <span className='flex items-center'>
                          <TokenImage
                            token={selectedZapToken.symbol.toLowerCase()}
                            offsetSize='25px'
                            offsetMarginLeft='0'
                          />
                          <p className='ml-2 text-gray azeret-md text-lg'>{selectedZapToken.symbol.toUpperCase()}</p>
                        </span>
                        <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                          <div className={`${open ? 'rotate-180' : ''}`}>
                            <SelectorIconSmall />
                          </div>
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter='transition ease-in duration-50'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        <Listbox.Options
                          className='absolute z-20 w-full bg-white shadow-md max-h-56 border-x-2 border-b-2 border-primary
                        rounded-b-xl text-base -mt-[2px] overflow-auto focus:outline-none'
                        >
                          {zapTokenOptions.map((token) => (
                            <Listbox.Option
                              key={token.address}
                              value={token}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-gray-200' : 'bg-white',
                                  'text-gray cursor-pointer select-none relative py-2 pl-3 pr-9',
                                )
                              }
                            >
                              {({ selected, active }) => (
                                <div className='flex items-center'>
                                  <TokenImage
                                    token={token.symbol.toLowerCase()}
                                    offsetSize='25px'
                                    offsetMarginLeft='0'
                                  />
                                  <p className='ml-2 azeret-md text-lg text-gray truncate'>
                                    {token.symbol.toUpperCase()}
                                  </p>
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            )}
          </div>

          <div className='gap-y-1 grid grid-cols-12 col-span-1 text-center md:text-left mt-3 border-primary mx-auto'>
            <div
              className={`h-18 col-span-12 grid grid-cols-12 background-light-gray rounded-xl border-2 p-2 border-primary`}
            >
              <button
                className={`col-span-12 text-xs bg-first azeret-md mb-1 text-white px-2 py-0-5 mr-0 ml-auto
                 rounded-lg h-6 hover:opacity-90 pointer uppercase ${!selected_pool ? 'bg-gray-400 text-white' : ''}`}
                onClick={() => selectMaxAmount(selectedZapToken.address, 0)}
              >
                max
              </button>
              <input
                type='text'
                value={positionData?.amount0Desired?.toString() || '0'}
                onChange={(e) => handleChangeAmount(e, 0)}
                placeholder='0'
                className='col-span-12 text-gray text-xl text-right azeret hidden-input'
              />
            </div>
          </div>
        </div>

        <div className='row mx-auto mb-2'>
          {isApproved ? (
            <button
              className={`uppercase text-lg azeret mt-2 ml-auto mr-0 hover:opacity-90 rounded-xl px-9 py-2 
              ${!selected_pool ? 'bg-gray-400 text-white' : 'bg-first text-white'}`}
              onClick={zap}
            >
              deposit
            </button>
          ) : (
            <button
              className={`uppercase text-lg azeret mt-2 ml-auto mr-0 hover:opacity-90 rounded-xl px-9 py-2 
              ${!selected_pool ? 'bg-gray-400 text-white' : 'bg-first text-white'}`}
              onClick={approveTokens}
            >
              approve
            </button>
          )}
        </div>
      </div>
    </>
  );
}
