export default function Card(props) {
  //change dinamically background color and text color
  const { title, description, backgroundColor, titleColor, descriptionColor, border, shadow } = props;
  return (
    <div className={'card bg-' + backgroundColor + ' ' + (border ? border : '') + ' ' + (shadow ? 'shadow' : '')}>
      <h4 className={'mb-2 lg:text-2xl text-3xl leading-8 text-left font-bold mr-10 pb-2 work-sans-bold ' + titleColor}>
        {title}
      </h4>
      <p className={'text-lg pt-2 text-white text-left azeret ' + descriptionColor}>{description}</p>
    </div>
  );
}
