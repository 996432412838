import { ToastBox } from './components/utils/Toast';
import Router from './Router';
import './styles/App.scss';

function App() {
  return (
    <div className='App'>
      <Router />
      <ToastBox />
    </div>
  );
}

export default App;
