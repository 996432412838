const OrbitLoader = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    preserveAspectRatio='xMidYMid meet'
    width={800}
    height={800}
    viewBox='0 0 800 800'
    style={{ width: '100%', height: '100%' }}
  >
    <defs>
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_5_G'
        fill='freeze'
        attributeName='transform'
        from='0'
        to='270'
        type='rotate'
        additive='sum'
        keyTimes='0;0.2;0.35;0.55;0.7;0.9;1'
        values='0;90;90;180;180;270;270'
        keySplines='0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_5_G'
        fill='freeze'
        attributeName='transform'
        from='2.38 2.38'
        to='2.38 2.38'
        type='scale'
        additive='sum'
        keyTimes='0;0.05;0.2;0.35;0.4;0.55;0.7;0.75;0.9;1'
        values='2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38'
        keySplines='0.333 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_5_G'
        fill='freeze'
        attributeName='transform'
        from='-8.582 -110.749'
        to='-8.582 -110.749'
        type='translate'
        additive='sum'
        keyTimes='0;1'
        values='-8.582 -110.749;-8.582 -110.749'
        keySplines='0 0 1 1'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_4_G'
        fill='freeze'
        attributeName='transform'
        from='0'
        to='270'
        type='rotate'
        additive='sum'
        keyTimes='0;0.03;0.23;0.38;0.58;0.73;0.93;1'
        values='0;0;90;90;180;180;270;270'
        keySplines='0.333 0 0 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_4_G'
        fill='freeze'
        attributeName='transform'
        from='2.38 2.38'
        to='2.38 2.38'
        type='scale'
        additive='sum'
        keyTimes='0;0.03;0.08;0.23;0.38;0.43;0.58;0.73;0.78;0.93;1'
        values='2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38'
        keySplines='0.333 0 0.833 1;0.333 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_4_G'
        fill='freeze'
        attributeName='transform'
        from='-75.603 -119.701'
        to='-75.603 -119.701'
        type='translate'
        additive='sum'
        keyTimes='0;1'
        values='-75.603 -119.701;-75.603 -119.701'
        keySplines='0 0 1 1'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_3_G'
        fill='freeze'
        attributeName='transform'
        from='0'
        to='270'
        type='rotate'
        additive='sum'
        keyTimes='0;0.06;0.26;0.41;0.61;0.76;0.96;1'
        values='0;0;90;90;180;180;270;270'
        keySplines='0.333 0 0 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_3_G'
        fill='freeze'
        attributeName='transform'
        from='2.38 2.38'
        to='2.38 2.38'
        type='scale'
        additive='sum'
        keyTimes='0;0.06;0.11;0.26;0.41;0.46;0.61;0.76;0.81;0.96;1'
        values='2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38'
        keySplines='0.333 0 0.833 1;0.333 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_3_G'
        fill='freeze'
        attributeName='transform'
        from='-141.803 -87.604'
        to='-141.803 -87.604'
        type='translate'
        additive='sum'
        keyTimes='0;1'
        values='-141.803 -87.604;-141.803 -87.604'
        keySplines='0 0 1 1'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_2_G'
        fill='freeze'
        attributeName='transform'
        from='0'
        to='270'
        type='rotate'
        additive='sum'
        keyTimes='0;0.09;0.29;0.44;0.64;0.79;0.99;1'
        values='0;0;90;90;180;180;270;270'
        keySplines='0.333 0 0 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_2_G'
        fill='freeze'
        attributeName='transform'
        from='2.38 2.38'
        to='2.38 2.38'
        type='scale'
        additive='sum'
        keyTimes='0;0.09;0.14;0.29;0.44;0.49;0.64;0.79;0.84;0.99;1'
        values='2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38'
        keySplines='0.333 0 0.833 1;0.333 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_2_G'
        fill='freeze'
        attributeName='transform'
        from='-167.151 -19.347'
        to='-167.151 -19.347'
        type='translate'
        additive='sum'
        keyTimes='0;1'
        values='-167.151 -19.347;-167.151 -19.347'
        keySplines='0 0 1 1'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_1_G'
        fill='freeze'
        attributeName='transform'
        from='0'
        to='180'
        type='rotate'
        additive='sum'
        keyTimes='0;0.12;0.32;0.47;0.67;0.82;1'
        values='0;0;90;90;180;180;180'
        keySplines='0.333 0 0 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0.333 0 0.667 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_1_G'
        fill='freeze'
        attributeName='transform'
        from='2.38 2.38'
        to='2.142 2.142'
        type='scale'
        additive='sum'
        keyTimes='0;0.12;0.17;0.32;0.47;0.52;0.67;0.82;0.87;1'
        values='2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.142 2.142'
        keySplines='0.333 0 0.833 1;0.333 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_1_G'
        fill='freeze'
        attributeName='transform'
        from='-141.834 -41.119'
        to='-141.834 -41.119'
        type='translate'
        additive='sum'
        keyTimes='0;1'
        values='-141.834 -41.119;-141.834 -41.119'
        keySplines='0 0 1 1'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_0_G'
        fill='freeze'
        attributeName='transform'
        from='0'
        to='180'
        type='rotate'
        additive='sum'
        keyTimes='0;0.15;0.35;0.5;0.7;0.85;1'
        values='0;0;90;90;180;180;180'
        keySplines='0.333 0 0 1;0.333 0 0 1;0.333 0 0.667 1;0.333 0 0 1;0.333 0 0.667 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_0_G'
        fill='freeze'
        attributeName='transform'
        from='2.38 2.38'
        to='2.142 2.142'
        type='scale'
        additive='sum'
        keyTimes='0;0.15;0.2;0.35;0.5;0.55;0.7;0.85;0.9;1'
        values='2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.38 2.38;2.38 2.38;2.142 2.142;2.142 2.142'
        keySplines='0.333 0 0.833 1;0.333 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0.167 0 0.833 1;0 0 0 0'
        calcMode='spline'
      />
      <animateTransform
        repeatCount='indefinite'
        dur='4s'
        begin='0s'
        xlinkHref='#_R_G_L_1_G_L_0_G'
        fill='freeze'
        attributeName='transform'
        from='-75.625 -79.432'
        to='-75.625 -79.432'
        type='translate'
        additive='sum'
        keyTimes='0;1'
        values='-75.625 -79.432;-75.625 -79.432'
        keySplines='0 0 1 1'
        calcMode='spline'
      />
      <animate attributeType='XML' attributeName='opacity' dur='4s' from='0' to='1' xlinkHref='#time_group' />
    </defs>
    <g id='_R_G'>
      <g id='_R_G_L_1_G'>
        <g id='_R_G_L_1_G_L_5_G' transform=' translate(400.298, 398.81)'>
          <path
            id='_R_G_L_1_G_L_5_G_D_0_P_0'
            fill='#520fec'
            fillOpacity='1'
            fillRule='nonzero'
            d=' M14.04 111.25 C14.04,111.25 16.78,9.44 16.78,9.44 C16.91,4.41 13.2,0.25 8.58,0.25 C3.96,0.25 0.25,4.41 0.38,9.44 C0.38,9.44 3.12,111.25 3.12,111.25 C3.12,111.25 3.12,111.25 3.12,111.25 C3.12,111.25 0.38,213.06 0.38,213.06 C0.25,218.1 3.96,222.25 8.58,222.25 C13.2,222.25 16.91,218.09 16.78,213.06 C16.78,213.06 14.04,111.25 14.04,111.25 C14.04,111.25 14.04,111.25 14.04,111.25z '
          />
        </g>
        <g id='_R_G_L_1_G_L_4_G' transform=' translate(400.149, 398.81)'>
          <path
            id='_R_G_L_1_G_L_4_G_D_0_P_0'
            fill='#520fec'
            fillOpacity='1'
            fillRule='nonzero'
            d=' M3.93 15.87 C3.93,15.87 115.13,197.13 115.13,197.13 C116.65,199.6 120.15,199.87 122.02,197.64 C122.04,197.62 122.05,197.6 122.07,197.58 C123.2,196.23 123.39,194.34 122.56,192.81 C122.56,192.81 21.24,5.95 21.24,5.95 C19.26,2.31 15.22,0.25 11.12,0.87 C10.99,0.89 10.87,0.91 10.75,0.93 C3.8,2.01 0.25,9.88 3.93,15.87z '
          />
        </g>
        <g id='_R_G_L_1_G_L_3_G' transform=' translate(400.149, 398.81)'>
          <path
            id='_R_G_L_1_G_L_3_G_D_0_P_0'
            fill='#520fec'
            fillOpacity='1'
            fillRule='nonzero'
            d=' M208.75 122.99 C208.75,122.99 25.12,4.02 25.12,4.02 C19.3,0.25 11.58,1.18 6.88,6.28 C6.81,6.35 6.74,6.43 6.67,6.51 C0.25,13.5 2.18,24.66 10.63,28.98 C10.63,28.98 205.55,128.55 205.55,128.55 C207.27,129.43 209.39,128.59 210.01,126.76 C210.02,126.74 210.02,126.72 210.03,126.7 C210.5,125.31 209.97,123.79 208.75,122.99z '
          />
        </g>
        <g id='_R_G_L_1_G_L_2_G' transform=' translate(400.149, 398.81)'>
          <path
            id='_R_G_L_1_G_L_2_G_D_0_P_0'
            fill='#520fec'
            fillOpacity='1'
            fillRule='nonzero'
            d=' M241.68 19.85 C241.68,19.85 241.68,19.78 241.68,19.78 C241.68,18.55 240.73,17.53 239.51,17.44 C239.51,17.44 20.44,1.06 20.44,1.06 C9.62,0.25 0.27,8.75 0.25,19.6 C0.25,19.6 0.25,19.85 0.25,19.85 C0.25,19.85 0.25,20.06 0.25,20.06 C0.27,30.91 9.62,39.4 20.44,38.6 C20.44,38.6 239.51,22.22 239.51,22.22 C240.73,22.12 241.68,21.1 241.68,19.87 C241.68,19.87 241.68,19.85 241.68,19.85z '
          />
        </g>
        <g id='_R_G_L_1_G_L_1_G' transform=' translate(400.149, 398.81)'>
          <path
            id='_R_G_L_1_G_L_1_G_D_0_P_0'
            fill='#520fec'
            fillOpacity='1'
            fillRule='nonzero'
            d=' M205.56 1.13 C205.56,1.13 10.64,100.71 10.64,100.71 C2.19,105.03 0.25,116.19 6.67,123.19 C6.74,123.26 6.81,123.34 6.88,123.42 C11.58,128.52 19.31,129.45 25.13,125.68 C25.13,125.68 208.76,6.7 208.76,6.7 C209.99,5.91 210.51,4.38 210.05,2.99 C210.04,2.97 210.03,2.95 210.03,2.93 C209.4,1.1 207.29,0.25 205.56,1.13z '
          />
        </g>
        <g id='_R_G_L_1_G_L_0_G' transform=' translate(400.149, 398.81)'>
          <path
            id='_R_G_L_1_G_L_0_G_D_0_P_0'
            fill='#520fec'
            fillOpacity='1'
            fillRule='nonzero'
            d=' M115.15 2.98 C115.15,2.98 3.92,184.26 3.92,184.26 C0.25,190.25 3.8,198.13 10.74,199.2 C10.87,199.22 10.99,199.24 11.11,199.26 C15.21,199.88 19.25,197.83 21.23,194.18 C21.23,194.18 122.56,7.32 122.56,7.32 C123.39,5.78 123.2,3.89 122.08,2.54 C122.08,2.54 122.02,2.47 122.02,2.47 C120.16,0.25 116.67,0.51 115.15,2.98z '
          />
        </g>
      </g>
      <g id='_R_G_L_0_G' transform=' translate(400.173, 400.005) scale(2.38, 2.38) translate(-39.866, -39.866)'>
        <path
          id='_R_G_L_0_G_D_0_P_0'
          fill='#ffffff'
          fillOpacity='1'
          fillRule='nonzero'
          d=' M79.48 39.87 C79.48,17.99 61.75,0.25 39.87,0.25 C17.99,0.25 0.25,17.99 0.25,39.87 C0.25,61.75 17.99,79.48 39.87,79.48 C61.75,79.48 79.48,61.75 79.48,39.87z '
        />
      </g>
    </g>
    <g id='time_group' />
  </svg>
);

export default OrbitLoader;
