export default function Footer(props) {
  const { textColor, orbitIcon, companyIcon, discordIcon, twitterIcon, docsIcon, blogIcon } = props;

  return (
    <div className={props.className}>
      {/* desktop */}
      <div className='row border-top border-bottom border-purple d-none d-lg-flex work-sans-bold'>
        <div className='col pt-2 pb-3'>
          <img className='align-middle block pt-2' height='40rem' width='40rem' src={orbitIcon} alt='Orbit icon' />
        </div>
        <div className='col'>
          <div className='row pt-4 justify-content-center'>
            <img
              className='align-middle block pt-1 pointer'
              height='auto'
              width='20rem'
              alt='fiveelements'
              src={companyIcon}
              onClick={() => window.open('https://fiveelementslabs.com/', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://fiveelementslabs.com/', '_blank')}
            >
              By Five Elements Labs
            </p>
          </div>
        </div>
        <div className='col'>
          <div className='row pt-4 justify-content-center'>
            <img
              className='align-middle block pt-1 pointer'
              height='auto'
              width='20rem'
              src={blogIcon}
              alt='Orbit Defi Blog'
              onClick={() => window.open('https://medium.com/@OrbitDefi.finance', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://medium.com/@OrbitDefi.finance', '_blank')}
            >
              Blog
            </p>
          </div>
        </div>
        <div className='col'>
          <div className='row pt-4 justify-content-center'>
            <img
              className='align-right block pt-1 pointer'
              height='auto'
              width='20rem'
              src={docsIcon}
              alt='Documentation icon'
              onClick={() => window.open('https://fiveelementslabs.gitbook.io/orbit/', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://fiveelementslabs.gitbook.io/orbit/', '_blank')}
            >
              Docs
            </p>
          </div>
        </div>
        <div className='col'>
          <div className='row pt-4 justify-content-center'>
            <img
              className='align-right block pt-1 pointer'
              height='auto'
              width='20rem'
              src={twitterIcon}
              alt='Twitter icon'
              onClick={() => window.open('https://twitter.com/OrbitFi', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://twitter.com/OrbitFi', '_blank')}
            >
              Twitter
            </p>
          </div>
        </div>
        <div className='col'>
          <div className='row pt-4 justify-content-center'>
            <img
              className='align-right block pt-1 pointer'
              height='auto'
              width='20rem'
              alt='discord'
              src={discordIcon}
              onClick={() => window.open('https://discord.gg/j8RgSsdFh9', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://discord.gg/j8RgSsdFh9', '_blank')}
            >
              Discord
            </p>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className='border-top border-bottom border-purple mt-5 mb-10 d-lg-none mx-2 grid grid-cols-12 pb-12 gap-4'>
        <div className='col-span-5'>
          <div className='row pt-4 mx-2'>
            <img
              className='pt-1 pointer'
              height='auto'
              width='20rem'
              src={blogIcon}
              alt='Orbit DeFi blog'
              onClick={() => window.open('https://medium.com/@OrbitDefi.finance', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://medium.com/@OrbitDefi.finance', '_blank')}
            >
              Blog
            </p>
          </div>
        </div>
        <div className='col-span-7'>
          <div className='row pt-4'>
            <img
              className='align-middle block pt-1 pointer'
              height='auto'
              width='20rem'
              alt='fiveelements'
              src={companyIcon}
              onClick={() => window.open('https://fiveelementslabs.com/', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://fiveelementslabs.com/', '_blank')}
            >
              By Five Elements Labs
            </p>
          </div>
        </div>
        <div className='col-span-5'>
          <div className='row pt-1 mx-2'>
            <img
              className='pt-1 pointer'
              height='auto'
              width='20rem'
              src={twitterIcon}
              alt='twitter'
              onClick={() => window.open('https://twitter.com/OrbitFi', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://twitter.com/OrbitFi', '_blank')}
            >
              Twitter
            </p>
          </div>
        </div>
        <div className='col-span-7'>
          <div className='row pt-1'>
            <img
              className='align-middle block pt-1 pointer'
              height='auto'
              width='20rem'
              src={docsIcon}
              alt='docs'
              onClick={() => window.open('https://fiveelementslabs.gitbook.io/orbit/', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://fiveelementslabs.gitbook.io/orbit/', '_blank')}
            >
              Docs
            </p>
          </div>
        </div>
        <div className='col-span-5'>
          <div className='row pt-1 mx-2'>
            <img
              className='pt-1 pointer'
              height='auto'
              width='20rem'
              src={discordIcon}
              alt='discord'
              onClick={() => window.open('https://discord.gg/j8RgSsdFh9', '_blank')}
            />
            <p
              className={`text-icon ${textColor} pointer opacity`}
              onClick={() => window.open('https://discord.gg/j8RgSsdFh9', '_blank')}
            >
              Discord
            </p>
          </div>
        </div>
      </div>
      <div className='row border-purple d-md-none mx-2 pb-4'></div>
    </div>
  );
}
