import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

// Main dApp layout
import Layout from './components/layout/Layout';

// Page components
import Landing from './components/landing/Landing';
import ProvideLiquidity from './components/dapp/provideLiquidity/ProvideLiquidity';
import Dashboard from './components/dapp/dashboard/Dashboard';
import Analytics from './components/dapp/analytics/Analytics';
import Import from './components/dapp/import/Import';
import Withdraw from './components/dapp/withdraw/Withdraw';
import WithdrawERC20 from './components/dapp/withdrawERC20/withdrawERC20';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function Router() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route index element={<Landing />} />
          <Route path='/' element={<Layout />}>
            <Route path='/provide-liquidity' element={<ProvideLiquidity />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/analytics' element={<Analytics />} />
            <Route path='/import' element={<Import />} />
            <Route path='/withdraw' element={<Withdraw />} />
            <Route path='/withdraw-erc20' element={<WithdrawERC20 />} />
          </Route>
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}
