/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react';
import { useSharedState } from '../../../context/store';
import { useModules } from '../../../hooks/useModules';
import { useTokenValue } from '../../../hooks/useTokenValue';
import { useNotifications } from '../../../hooks/useNotifications';
import { getPriceByTick, getAmountsForLiquidity, getPoolTick } from '../../../utils/utils';
import ModuleSwitch from './ModuleSwitch';
import PoolImage from '../../utils/PoolImage';

import InfoCircle from '../../icons/InfoCircle';
import OrbitLogoSecond from '../../icons/OrbitLogoSecondSvg';
import BigArrow from '../../icons/BigArrow';
import LinkSvg from '../../icons/LinkSvg';

export default function PoolDetailsBox() {
  const [state] = useSharedState();
  const { toggleAutocompound, toggleIdleLiquidity, toggleAave, setModuleData } = useModules();
  const { getValueOfTokensInsidePosition } = useTokenValue();
  const { notify } = useNotifications();

  const [tokenInWhichToShowPrices, setTokenInWhichToShowPrices] = useState('token0');
  const [viewAdvancedMode, setViewAdvancedMode] = useState(false);
  const [viewMoreDetails, setViewMoreDetails] = useState(false);
  const [amount0, setAmount0] = useState(null);
  const [amount1, setAmount1] = useState(null);
  const [amount0PercentValue, setAmount0PercentValue] = useState(null);
  const [amount1PercentValue, setAmount1PercentValue] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(0);

  // Mapping from moduleData values to values shown in the UI
  const autoCompoundFeePercentageChoices = { 1: 1, 2: 2, 5: 5 };
  const rebalanceDistanceChoices = { 200: 2, 500: 5, 1000: 10 };
  const aaveDistanceChoices = { 200: 2, 500: 5, 1000: 10 };

  // Number of decimals to show prices in
  const rangePriceDecimals = 3;
  const currentPriceDecimals = 5;

  const getTokenAmounts = async () => {
    try {
      const signer = await state.provider.getSigner();
      const { liquidity, tickLower, tickUpper, token0Address, token1Address, token0Decimals, token1Decimals, fee } =
        state.selected_position;

      if (state.selected_position.isOnAave) {
        // Position is on Aave: show the amountToAave
        const { amountToAave, tokenToAave } = state.selected_position.aaveData;
        const amountToAaveWithDecimals =
          tokenToAave === token0Address
            ? amountToAave.toNumber() / Math.pow(10, token0Decimals)
            : amountToAave.toNumber() / Math.pow(10, token1Decimals);

        if (tokenToAave === token0Address) {
          setAmount0(amountToAaveWithDecimals.toPrecision(3));
          setAmount1('0.00');
        } else {
          setAmount0('0.00');
          setAmount1(amountToAaveWithDecimals.toPrecision(3));
        }
      } else {
        // Position is on Uniswap: show amounts for both tokens
        const tick = await getPoolTick(
          state.chain_id,
          signer,
          token0Address,
          token1Address,
          token0Decimals,
          token1Decimals,
          fee * 10000,
        );

        var { amount0: amount0WithDecimals, amount1: amount1WithDecimals } = getAmountsForLiquidity(
          Number(tick),
          Number(tickLower),
          Number(tickUpper),
          liquidity,
        );

        if (isNaN(amount0WithDecimals)) amount0WithDecimals = 0;
        if (isNaN(amount1WithDecimals)) amount1WithDecimals = 0;

        setAmount0((amount0WithDecimals / Math.pow(10, token0Decimals)).toPrecision(3));
        setAmount1((amount1WithDecimals / Math.pow(10, token1Decimals)).toPrecision(3));
      }
    } catch (err) {
      console.error(err?.message);
    }
  };

  const getTokenPercentValues = async () => {
    try {
      setAmount0PercentValue(null);
      setAmount1PercentValue(null);

      if (state.selected_position.isOnAave) {
        // Position is on Aave: show the amountToAave
        const { tokenToAave } = state.selected_position.aaveData;
        if (tokenToAave === state.selected_position.token0Address) {
          setAmount0PercentValue(100);
          setAmount1PercentValue(0);
        } else {
          setAmount0PercentValue(0);
          setAmount1PercentValue(100);
        }
      } else {
        // Position is on Uniswap: get the value of tokens from hooks
        // Deep-copy and rebuild object in order to update fee field without modifying the state
        const position = JSON.parse(JSON.stringify(state.selected_position));
        position.fee = position.fee * 10000;

        const values = await getValueOfTokensInsidePosition(position);
        if (!values) return null;

        const totalValue = values.token0Value.add(values.token1Value);

        if (totalValue.toFixed(2) === '0.00') {
          setAmount0PercentValue(0);
          setAmount1PercentValue(0);
        } else {
          setAmount0PercentValue(
            Number(((Number(values.token0Value.toFixed(2)) / Number(totalValue.toFixed(2))) * 100).toFixed(0)),
          );
          setAmount1PercentValue(
            Number(((Number(values.token1Value.toFixed(2)) / Number(totalValue.toFixed(2))) * 100).toFixed(0)),
          );
        }
      }
    } catch (err) {
      console.error(err?.message);
    }
  };

  const parsePrice = (price, precision = rangePriceDecimals) => {
    if (price >= 1000) return Number(price).toFixed(2);
    return Number(price).toPrecision(precision);
  };

  const getCurrentPrice = async () => {
    const signer = await state.provider.getSigner();
    const { token0Address, token1Address, token0Decimals, token1Decimals, fee } = state.selected_position;

    const tick = await getPoolTick(
      state.chain_id,
      signer,
      token0Address,
      token1Address,
      token0Decimals,
      token1Decimals,
      fee * 10000,
    );

    setCurrentPrice(
      tokenInWhichToShowPrices === 'token0'
        ? parsePrice(
            1 /
              getPriceByTick(
                Number(tick),
                true,
                state.selected_position.token0Decimals - state.selected_position.token1Decimals,
              ),
            currentPriceDecimals,
          )
        : parsePrice(
            getPriceByTick(tick, true, state.selected_position.token0Decimals - state.selected_position.token1Decimals),
            currentPriceDecimals,
          ),
    );
  };

  const handleAdvancedModeWhileEmpty = () => {
    if (!state?.selected_position) {
      notify('error', 'Please select a position first.');
      return;
    }
    setViewAdvancedMode(true);
  };

  useEffect(() => {
    if (state?.selected_position && state?.provider && state?.chain_id) {
      getTokenAmounts();
      getTokenPercentValues();
    }
  }, [state?.selected_position, state?.provider, state?.chain_id]);

  useEffect(() => {
    if (state?.selected_position && state?.provider && state?.chain_id) getCurrentPrice();
  }, [state?.selected_position, state?.provider, state?.chain_id, tokenInWhichToShowPrices]);

  const minimumPrice = useMemo(() => {
    if (!state?.selected_position) return 0;
    return tokenInWhichToShowPrices === 'token0'
      ? parsePrice(
          1 /
            getPriceByTick(
              state.selected_position.tickUpper,
              true,
              state.selected_position.token0Decimals - state.selected_position.token1Decimals,
            ),
        )
      : parsePrice(
          getPriceByTick(
            state.selected_position.tickLower,
            true,
            state.selected_position.token0Decimals - state.selected_position.token1Decimals,
          ),
        );
  }, [state?.selected_position, tokenInWhichToShowPrices]);

  const maximumPrice = useMemo(() => {
    if (!state?.selected_position) return 0;
    return tokenInWhichToShowPrices === 'token0'
      ? parsePrice(
          1 /
            getPriceByTick(
              state.selected_position.tickLower,
              true,
              state.selected_position.token0Decimals - state.selected_position.token1Decimals,
            ),
        )
      : parsePrice(
          getPriceByTick(
            state.selected_position.tickUpper,
            true,
            state.selected_position.token0Decimals - state.selected_position.token1Decimals,
          ),
        );
  }, [state?.selected_position, tokenInWhichToShowPrices]);

  const NFTLink = useMemo(
    () =>
      state?.selected_position?.id ? `https://app.uniswap.org/#/pool/${state.selected_position.id}?chain=polygon` : '#',
    [state?.selected_position?.id],
  );

  return (
    <div className='xs:mt-0 md:mt-0 md:ml-3 pb-2'>
      <div className='pool-box xs:ml-0 md:ml-3 p-4 border-[3px] border-primary min-h-[602px]'>
        {state.selected_position && (
          <div className='background-light-gray rounded-xl p-2 mb-3'>
            <div className='relative flex items-center'>
              <div className='grid place-content-center'>
                <PoolImage
                  token0={state.selected_position.token0.toLowerCase()}
                  token1={state.selected_position.token1.toLowerCase()}
                  size='60px'
                  offsetMarginLeft='-20px'
                />
              </div>
              <div className='w-[140px]'>
                <p className='text-gray azeret-bold ml-2 my-auto pair-header uppercase'>
                  {`${state.selected_position.token0}-${state.selected_position.token1}`}
                  <br /> {state.selected_position.fee}%
                </p>
              </div>
              <div className='absolute right-2 h-full grid place-content-center'>
                <BigArrow
                  className={`cursor-pointer ${viewMoreDetails ? '' : 'rotate-180'}`}
                  onClick={() => setViewMoreDetails(!viewMoreDetails)}
                />
              </div>
            </div>

            {viewMoreDetails && (
              <div className='my-2'>
                <div className='flex items-center justify-between border-t-2 border-gray-300 mx-1 py-2'>
                  <h3 className='text-gray azeret-bold my-auto text-lg uppercase'>{state.selected_position.token0}</h3>
                  <div className='flex items-center'>
                    <p className='azeret text-lg text-gray-700'>{amount0 || '–'}</p>
                    <div className='bg-white border-[1px] rounded-[0.6rem] py-[2px] px-[3px] border-gray-700 ml-3'>
                      <p className='m-0 text-sm azeret'>{amount0PercentValue !== null ? amount0PercentValue : '0'}%</p>
                    </div>
                  </div>
                </div>

                <div className='flex items-center justify-between border-y-2 border-gray-300 mx-1 py-2'>
                  <h3 className='text-gray azeret-bold my-auto text-lg uppercase'>{state.selected_position.token1}</h3>
                  <div className='flex items-center'>
                    <p className='azeret text-lg text-gray-700'>{amount1 || '–'}</p>
                    <div className='bg-white border-[1px] rounded-[0.6rem] py-[2px] px-[3px] border-gray-700 ml-3'>
                      <p className='m-0 text-sm azeret'>{amount1PercentValue ?? '–'}%</p>
                    </div>
                  </div>
                </div>

                <div className='relative w-full pb-5'>
                  <div className='absolute top-3 right-[4px] w-full flex justify-end items-center'>
                    <button
                      className={`rounded-[0.45rem] azeret font-medium text-sm uppercase focus:outline-none ${
                        tokenInWhichToShowPrices === 'token0'
                          ? 'px-2 bg-white z-10 -mr-3 border-2 border-secondary primary'
                          : 'pl-2 pr-3 border-none bg-gray-600 z-0 text-white py-[1px]'
                      }`}
                      onClick={() => setTokenInWhichToShowPrices('token0')}
                    >
                      {state.selected_position.token0}
                    </button>
                    <button
                      className={`px-2 rounded-[0.45rem] azeret font-medium text-sm uppercase focus:outline-none ${
                        tokenInWhichToShowPrices === 'token1'
                          ? 'px-2 bg-white z-10 -ml-3 border-2 border-secondary primary'
                          : 'pl-3 pr-2 border-none bg-gray-600 z-0 text-white py-[1px]'
                      }`}
                      onClick={() => setTokenInWhichToShowPrices('token1')}
                    >
                      {state.selected_position.token1}
                    </button>
                  </div>
                </div>

                <div className='flex justify-evenly w-full'>
                  <div className='bg-white grid place-content-center text-center rounded-xl h-20 w-32'>
                    <h3 className='uppercase work-sans-bold primary'>Min Price</h3>
                    <p className='text-gray-700 azeret font-medium'>{minimumPrice}</p>
                    <p className='azeret text-xs uppercase text-gray-700'>
                      {tokenInWhichToShowPrices === 'token0'
                        ? state.selected_position.token0
                        : state.selected_position.token1}{' '}
                      per{' '}
                      {tokenInWhichToShowPrices === 'token0'
                        ? state.selected_position.token1
                        : state.selected_position.token0}
                    </p>
                  </div>
                  <div className='h-20 grid place-content-center'>
                    <BigArrow className='rotate-90' />
                  </div>
                  <div className='bg-white grid place-content-center text-center rounded-xl h-20 w-32'>
                    <h3 className='uppercase work-sans-bold primary'>Max Price</h3>
                    <p className='text-gray-700 azeret font-medium'>{maximumPrice}</p>
                    <p className='azeret text-xs uppercase text-gray-700 text-center'>
                      {tokenInWhichToShowPrices === 'token0'
                        ? state.selected_position.token0
                        : state.selected_position.token1}{' '}
                      per{' '}
                      {tokenInWhichToShowPrices === 'token0'
                        ? state.selected_position.token1
                        : state.selected_position.token0}
                    </p>
                  </div>
                </div>

                <div className='mt-2 mx-1 h-20 bg-white rounded-xl grid place-items-center p-2'>
                  <h3 className='uppercase work-sans-bold primary'>Current Price</h3>
                  <p className='text-gray-700 azeret font-medium'>{currentPrice}</p>
                  <p className='azeret text-xs uppercase text-gray-700 text-center'>
                    {tokenInWhichToShowPrices === 'token0'
                      ? state.selected_position.token0
                      : state.selected_position.token1}{' '}
                    per{' '}
                    {tokenInWhichToShowPrices === 'token0'
                      ? state.selected_position.token1
                      : state.selected_position.token0}
                  </p>
                </div>

                <div className='border-b-2 border-gray-300 mx-1 my-[10px]' />
                <div className='flex justify-between w-full px-1'>
                  <h3 className='text-gray azeret-bold my-auto text-lg uppercase'>NFT Link</h3>
                  <a href={NFTLink} target='_blank' rel='noopener noreferrer'>
                    <button className='rounded-full p-2 btn-secondary'>
                      <LinkSvg />
                    </button>
                  </a>
                </div>
              </div>
            )}
          </div>
        )}

        <div className='bg-first rounded-xl p-2 text-center flex items-center justify-center gap-2 mb-3'>
          <OrbitLogoSecond />
          <h3 className='work-sans-bold uppercase text-xl text-white pr-2'>Orbit Strategies</h3>
        </div>

        <div className='mb-3 text-center md:text-left background-light-gray rounded-xl'>
          <div className='flex items-center w-full justify-left ml-3'>
            <h4 className='text-lg text-center work-sans-bold primary py-3 uppercase'>Autocompound</h4>
            <div className='relative'>
              <InfoCircle className='ml-2 autocompound-info' />
              <div className='hint opacity-100 rounded-2xl z-10'>
                <div className='inner-hint border-primary border-2'>
                  <p className='primary text-sm'>
                    Autocompound periodically reinvests fees accrued in order to maximize APY
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-2 px-3 flex justify-between'>
          <p
            className={`azeret ${
              state?.selected_position?.module_states['AutoCompoundModule'] ? 'opacity-100' : 'opacity-50'
            }`}
          >
            Reinvest fees
          </p>
          <ModuleSwitch
            checked={state.selected_position ? state.selected_position.module_states['AutoCompoundModule'] : false}
            onChange={toggleAutocompound}
          />
        </div>

        {viewAdvancedMode && (
          <div
            id='fee%'
            className={`w-full flex items-center px-3 mt-2 gap-4 ${
              state.selected_position.module_states['AutoCompoundModule'] ? 'opacity-100' : 'opacity-50'
            }`}
          >
            <ModuleOptions
              optionChoices={autoCompoundFeePercentageChoices}
              setModuleData={setModuleData}
              module_states={state.selected_position.module_states}
              module_data={state.selected_position.module_data}
              moduleName='AutoCompoundModule'
            />
          </div>
        )}

        <div className='my-3 text-center md:text-left background-light-gray rounded-xl'>
          <div className='flex items-center w-full justify-left ml-3'>
            <h4 className='text-lg text-center work-sans-bold primary py-3 uppercase'>Out of range</h4>
            <div className='relative'>
              <InfoCircle className='ml-2 oor-info' />
              <div className='hint opacity-100 rounded-2xl z-10'>
                <div className='inner-hint border-primary border-2'>
                  <p className='primary text-sm'>
                    Settings for when the position is out of range. Strategies are applied automatically by Orbit
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='px-3'>
          <div className='mt-2 flex justify-between relative'>
            <div className={`flex items-center gap-2 ${viewAdvancedMode ? 'mb-3' : 'mb-0'} `}>
              <p
                className={`azeret ${
                  state?.selected_position?.module_states['IdleLiquidityModule'] ? 'opacity-100' : 'opacity-50'
                }`}
              >
                Uniswap rebalance
              </p>
              {viewAdvancedMode && (
                <small className='absolute top-6 azeret tracking-tighter text-xs'>Price distance from range</small>
              )}
              <div className='relative'>
                <InfoCircle
                  className={`rebalance-info ${
                    state?.selected_position?.module_states['AaveModule'] ? 'opacity-100' : 'opacity-50'
                  }`}
                />
                <div className='hint opacity-100 rounded-2xl z-10'>
                  <div className='inner-hint border-primary border-2'>
                    <p className='primary text-sm'>
                      When the position is not earning fees on Uniswap, it is closed and reopened with a new range that
                      earns fees
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ModuleSwitch
              checked={state.selected_position ? state.selected_position.module_states['IdleLiquidityModule'] : false}
              onChange={() =>
                state?.selected_position?.module_states?.['AaveModule'] === true
                  ? notify('warning', 'To activate this module, you need to turn off "Move to Aave" first')
                  : toggleIdleLiquidity()
              }
            />
          </div>

          {viewAdvancedMode && (
            <div
              id='fee%'
              className={`w-full flex items-center mt-2 gap-4 ${
                state.selected_position.module_states['IdleLiquidityModule'] ? 'opacity-100' : 'opacity-50'
              }`}
            >
              <ModuleOptions
                optionChoices={rebalanceDistanceChoices}
                setModuleData={setModuleData}
                module_states={state.selected_position.module_states}
                module_data={state.selected_position.module_data}
                moduleName='IdleLiquidityModule'
              />
            </div>
          )}

          <div className='mt-3 flex justify-between relative'>
            <div className={`flex items-center gap-2 ${viewAdvancedMode ? 'mb-3' : 'mb-0'} `}>
              <p
                className={`azeret ${
                  state?.selected_position?.module_states['AaveModule'] ? 'opacity-100' : 'opacity-50'
                }`}
              >
                Move to Aave
              </p>
              {viewAdvancedMode && (
                <small className='absolute top-6 azeret tracking-tighter text-xs'>Price distance from range</small>
              )}
              <div className='relative'>
                <InfoCircle
                  className={`aave-info ${
                    state?.selected_position?.module_states['AaveModule'] ? 'opacity-100' : 'opacity-50'
                  }`}
                />
                <div className='hint opacity-100 rounded-2xl z-10'>
                  <div className='bg-gradient-to-tr p-[3px] from-[#0bcae3] to-[#e424c8] rounded-2xl'>
                    <div className='inner-hint'>
                      <p className='primary text-sm'>
                        When the position is not earning fees on Uniswap, it is deployed to Aave to get additional yield
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ModuleSwitch
              checked={state.selected_position ? state.selected_position.module_states['AaveModule'] : false}
              onChange={() =>
                state?.selected_position?.module_states?.['IdleLiquidityModule'] === true
                  ? notify('warning', 'To activate this module, you need to turn off "Uniswap rebalance" first')
                  : toggleAave()
              }
            />
          </div>

          {viewAdvancedMode && (
            <div
              id='fee%'
              className={`w-full flex items-center mt-2 gap-4 ${
                state?.selected_position?.module_states['AaveModule'] ? 'opacity-100' : 'opacity-50'
              }`}
            >
              <ModuleOptions
                optionChoices={aaveDistanceChoices}
                setModuleData={setModuleData}
                module_states={state.selected_position.module_states}
                module_data={state.selected_position.module_data}
                moduleName='AaveModule'
              />
            </div>
          )}

          <div className='py-4' />
          <div id='viewMode' className='absolute bottom-4 left-0 w-full flex justify-center items-center px-4'>
            <button
              className={`w-full rounded-xl h-9 azeret font-medium uppercase focus:outline-none ${
                !viewAdvancedMode
                  ? 'bg-white z-10 -mr-3 border-2 border-secondary primary'
                  : 'border-none bg-gray-600 z-0 text-white'
              }`}
              onClick={() => setViewAdvancedMode(false)}
            >
              Simple
            </button>
            <button
              className={`w-full rounded-xl h-9 azeret font-medium uppercase focus:outline-none ${
                !viewAdvancedMode
                  ? 'border-none bg-gray-600 z-0 text-white'
                  : 'bg-white z-10 -ml-3 border-2 border-secondary primary'
              }`}
              onClick={handleAdvancedModeWhileEmpty}
            >
              Advanced
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const ModuleOptions = ({ optionChoices, setModuleData, module_states, module_data, moduleName }) => {
  return (
    <>
      {Object.keys(optionChoices).map((choice, id) => (
        <div
          key={id}
          onClick={async () =>
            module_states[moduleName] &&
            module_data[moduleName] !== Number(choice) &&
            (await setModuleData(moduleName, choice))
          }
          className={`w-14 h-8 rounded-md cursor-pointer ${
            module_states[moduleName] && module_data[moduleName] === Number(choice) && 'pill-shadow bg-white'
          } ${
            module_states[moduleName] && module_data[moduleName] !== Number(choice)
              ? 'bg-module-option'
              : 'bg-gray-400 cursor-default'
          }`}
        >
          <p
            className={`w-full h-full grid place-items-center azeret-bold ${
              module_states[moduleName] && module_data[moduleName] !== Number(choice) && 'text-module-option'
            } ${module_states[moduleName] && module_data[moduleName] === Number(choice) && 'primary'}`}
          >
            {optionChoices[choice]}%
          </p>
        </div>
      ))}
    </>
  );
};
