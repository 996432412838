const OutOfRangeIcon = (props) => (
  <svg width={37} height={37} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.5 36.445c9.91 0 17.945-8.034 17.945-17.945C36.445 8.59 28.41.555 18.5.555 8.589.555.555 8.589.555 18.5c0 9.91 8.034 17.945 17.945 17.945Z'
      fill='#F5AC37'
    />
    <path
      d='M18.5 4a14.5 14.5 0 1 0 0 29 14.5 14.5 0 0 0 0-29Zm0 26.1a11.6 11.6 0 1 1 0-23.199 11.6 11.6 0 0 1 0 23.199Z'
      fill='#fff'
    />
    <path
      d='M18.5 25.75a1.45 1.45 0 1 0 0-2.9 1.45 1.45 0 0 0 0 2.9ZM18.5 11.25a1.45 1.45 0 0 0-1.45 1.45v7.25a1.45 1.45 0 0 0 2.9 0V12.7a1.45 1.45 0 0 0-1.45-1.45Z'
      fill='#fff'
    />
  </svg>
);

export default OutOfRangeIcon;
