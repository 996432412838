import { BigNumber, Contract } from 'ethers';
import { CurrencyAmount, Token, TradeType, Percent, Price } from '@uniswap/sdk-core';
import { Pool, Position, computePoolAddress } from '@uniswap/v3-sdk';
import { Trade as V2Trade, Pair, computePairAddress } from '@uniswap/v2-sdk';
import IUniswapV2PairABI from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import IUniswapV3PoolStateABI from '@uniswap/v3-core/artifacts/contracts/interfaces/pool/IUniswapV3PoolState.sol/IUniswapV3PoolState.json';
import { Interface } from 'ethers/lib/utils';
import { useSharedState } from '../context/store';
import { usePools } from './usePools';
import JSBI from 'jsbi';

const POLYGON_CHAIN_ID = 137;
const MUMBAI_CHAIN_ID = 80001;
const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000));
const ZERO_PERCENT = new Percent('0');
const ONE_HUNDRED_PERCENT = new Percent('1');
const PAIR_INTERFACE = new Interface(IUniswapV2PairABI.abi);
const POOL_STATE_INTERFACE = new Interface(IUniswapV3PoolStateABI.abi);

const V2_FACTORY_ADDRESS = '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32';
const V2_FACTORY_ADDRESSES = constructSameAddressMap(V2_FACTORY_ADDRESS, [POLYGON_CHAIN_ID, MUMBAI_CHAIN_ID]);

const V3_FACTORY_ADDRESS = '0x1F98431c8aD98523631AE4a59f267346ea31F984';
const V3_CORE_FACTORY_ADDRESSES = constructSameAddressMap(V3_FACTORY_ADDRESS, [POLYGON_CHAIN_ID, MUMBAI_CHAIN_ID]);

const USDC_POLYGON = new Token(POLYGON_CHAIN_ID, '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', 6, 'USDC', 'USD//C');
const DAI_POLYGON = new Token(
  POLYGON_CHAIN_ID,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin',
);
const USDT_POLYGON = new Token(POLYGON_CHAIN_ID, '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', 6, 'USDT', 'Tether USD');
const WETH_POLYGON = new Token(
  POLYGON_CHAIN_ID,
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  18,
  'WETH',
  'Wrapped Ether',
);
const USDC_MUMBAI = new Token(MUMBAI_CHAIN_ID, '0xe11a86849d99f524cac3e7a0ec1241828e332c62', 6, 'USDC', 'USD//C');

// Stablecoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
export const STABLECOIN_AMOUNT_OUT = {
  [POLYGON_CHAIN_ID]: CurrencyAmount.fromRawAmount(USDC_POLYGON, 10000e6),
  [MUMBAI_CHAIN_ID]: CurrencyAmount.fromRawAmount(USDC_MUMBAI, 10000e6),
};

const WRAPPED_NATIVE_CURRENCY = {
  [POLYGON_CHAIN_ID]: new Token(
    POLYGON_CHAIN_ID,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC',
  ),
  [MUMBAI_CHAIN_ID]: new Token(
    MUMBAI_CHAIN_ID,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'WMATIC',
    'Wrapped MATIC',
  ),
};

const WRAPPED_NATIVE_CURRENCIES_ONLY = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean),
);

const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [POLYGON_CHAIN_ID]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[POLYGON_CHAIN_ID],
    DAI_POLYGON,
    USDC_POLYGON,
    USDT_POLYGON,
    WETH_POLYGON,
  ],
};

function getAllCurrencyCombinations(currencyA, currencyB) {
  const chainId = currencyA?.chainId;

  const [tokenA, tokenB] = chainId ? [currencyA?.wrapped, currencyB?.wrapped] : [undefined, undefined];

  const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? [];
  const bases = [...common];

  const basePairs = bases
    .flatMap((base) => bases.map((otherBase) => [base, otherBase]))
    .filter(([t0, t1]) => !t0.equals(t1));

  return tokenA && tokenB
    ? [
        // the direct pair
        [tokenA, tokenB],
        // token A against all bases
        ...bases.map((base) => [tokenA, base]),
        // token B against all bases
        ...bases.map((base) => [tokenB, base]),
        // each base against all bases
        ...basePairs,
      ]
        // filter out invalid pairs comprised of the same asset (e.g. WETH<>WETH)
        .filter(([t0, t1]) => !t0.equals(t1))
        // filter out duplicate pairs
        .filter(([t0, t1], i, otherPairs) => {
          // find the first index in the array at which there are the same 2 tokens as the current
          const firstIndexInOtherPairs = otherPairs.findIndex(([t0Other, t1Other]) => {
            return (t0.equals(t0Other) && t1.equals(t1Other)) || (t0.equals(t1Other) && t1.equals(t0Other));
          });
          // only accept the first occurrence of the same 2 tokens
          return firstIndexInOtherPairs === i;
        })
    : [];
}

function constructSameAddressMap(address, additionalNetworks = []) {
  return [POLYGON_CHAIN_ID, MUMBAI_CHAIN_ID].concat(additionalNetworks).reduce((memo, chainId) => {
    memo[chainId] = address;
    return memo;
  }, {});
}

class PoolCache {
  // Evict after 128 entries. Empirically, a swap uses 64 entries.
  static MAX_ENTRIES = 128;

  // These are FIFOs, using unshift/pop. This makes recent entries faster to find.
  static pools = [];
  static addresses = [];

  static getPoolAddress(factoryAddress, tokenA, tokenB, fee) {
    if (this.addresses.length > this.MAX_ENTRIES) {
      this.addresses = this.addresses.slice(0, this.MAX_ENTRIES / 2);
    }

    const { address: addressA } = tokenA;
    const { address: addressB } = tokenB;
    const key = `${factoryAddress}:${addressA}:${addressB}:${fee.toString()}`;
    const found = this.addresses.find((address) => address.key === key);
    if (found) return found.address;

    const address = {
      key,
      address: computePoolAddress({
        factoryAddress,
        tokenA,
        tokenB,
        fee,
      }),
    };
    this.addresses.unshift(address);
    return address.address;
  }

  static getPool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick) {
    if (this.pools.length > this.MAX_ENTRIES) {
      this.pools = this.pools.slice(0, this.MAX_ENTRIES / 2);
    }

    const found = this.pools.find(
      (pool) =>
        pool.token0 === tokenA &&
        pool.token1 === tokenB &&
        pool.fee === fee &&
        JSBI.EQ(pool.sqrtRatioX96, sqrtPriceX96) &&
        JSBI.EQ(pool.liquidity, liquidity) &&
        pool.tickCurrent === tick,
    );
    if (found) return found;

    const pool = new Pool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick);
    this.pools.unshift(pool);
    return pool;
  }
}

const PairState = {
  LOADING: 0,
  NOT_EXISTS: 1,
  EXISTS: 2,
  INVALID: 3,
};

async function getV2Pairs(signer, currencies) {
  const tokens = currencies.map(([currencyA, currencyB]) => [currencyA?.wrapped, currencyB?.wrapped]);

  const pairAddresses = tokens.map(([tokenA, tokenB]) => {
    return tokenA &&
      tokenB &&
      tokenA.chainId === tokenB.chainId &&
      !tokenA.equals(tokenB) &&
      V2_FACTORY_ADDRESSES[tokenA.chainId]
      ? computePairAddress({ factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId], tokenA, tokenB })
      : undefined;
  });

  const results = [];
  for (const pairAddress in pairAddresses) {
    const pairContract = new Contract(pairAddresses[pairAddress], PAIR_INTERFACE, signer);
    const result = await pairContract.getReserves();
    result && results.push(result);
  }

  return results.map((result, i) => {
    const reserves = result;
    const tokenA = tokens[i][0];
    const tokenB = tokens[i][1];

    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null];
    if (!reserves) return [PairState.NOT_EXISTS, null];
    const { reserve0, reserve1 } = reserves;

    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];

    return [
      PairState.EXISTS,
      new Pair(
        CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
        CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
      ),
    ];
  });
}

async function getAllCommonPairs(signer, currencyA, currencyB) {
  const allCurrencyCombinations = getAllCurrencyCombinations(currencyA, currencyB);

  const allPairs = await getV2Pairs(signer, allCurrencyCombinations);

  return Object.values(
    allPairs
      // filter out invalid pairs
      .filter((result) => Boolean(result[0] === PairState.EXISTS && result[1]))
      .map(([, pair]) => pair),
  );
}

// returns whether tradeB is better than tradeA by at least a threshold percentage amount
// only used by v2 hooks
export function isTradeBetter(tradeA, tradeB, minimumDelta = ZERO_PERCENT) {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency)
  ) {
    throw new Error('Comparing incomparable trades');
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  } else {
    return tradeA.executionPrice.asFraction
      .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
      .lessThan(tradeB.executionPrice);
  }
}

async function getBestV2Trade(signer, tradeType, amountSpecified, otherCurrency, maxHops = 3) {
  const [currencyIn, currencyOut] =
    tradeType === TradeType.EXACT_INPUT
      ? [amountSpecified?.currency, otherCurrency]
      : [otherCurrency, amountSpecified?.currency];

  const allowedPairs = await getAllCommonPairs(signer, currencyIn, currencyOut);

  if (amountSpecified && currencyIn && currencyOut && allowedPairs.length > 0) {
    if (maxHops === 1) {
      const options = { maxHops: 1, maxNumResults: 1 };
      if (tradeType === TradeType.EXACT_INPUT) {
        const amountIn = amountSpecified;
        return V2Trade.bestTradeExactIn(allowedPairs, amountIn, currencyOut, options)[0] ?? null;
      } else {
        const amountOut = amountSpecified;
        return V2Trade.bestTradeExactOut(allowedPairs, currencyIn, amountOut, options)[0] ?? null;
      }
    }

    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar = null;
    for (let i = 1; i <= maxHops; i++) {
      const options = { maxHops: i, maxNumResults: 1 };
      let currentTrade;

      if (tradeType === TradeType.EXACT_INPUT) {
        const amountIn = amountSpecified;
        currentTrade = V2Trade.bestTradeExactIn(allowedPairs, amountIn, currencyOut, options)[0] ?? null;
      } else {
        const amountOut = amountSpecified;
        currentTrade = V2Trade.bestTradeExactOut(allowedPairs, currencyIn, amountOut, options)[0] ?? null;
      }

      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade;
      }
    }
    return bestTradeSoFar;
  }

  return null;
}

const getUSDCPrice = async (signer, chain_id, currency) => {
  const amountOut = chain_id ? STABLECOIN_AMOUNT_OUT[chain_id] : undefined;
  const stablecoin = amountOut?.currency;

  const v2USDCTrade = await getBestV2Trade(signer, TradeType.EXACT_OUTPUT, amountOut, currency);

  var price = 0;
  if (v2USDCTrade) {
    const { numerator, denominator } = v2USDCTrade.route.midPrice;
    price = new Price(currency, stablecoin, denominator, numerator);
  }

  return price;
};

export const useTokenValue = () => {
  const [{ provider, chain_id }] = useSharedState();
  const { getUniPool } = usePools();

  const getValueOfTokensInsidePosition = async (position) => {
    try {
      const signer = await provider?.getSigner();
      const { token0Address, token1Address, token0, token1, token0Decimals, token1Decimals, fee } = position;

      const tokenA = new Token(chain_id, token0Address, token0Decimals, token0);
      const tokenB = new Token(chain_id, token1Address, token1Decimals, token1);
      const factoryAddress = chain_id && V3_CORE_FACTORY_ADDRESSES[chain_id];

      const poolAddress = computePoolAddress({
        factoryAddress,
        tokenA,
        tokenB,
        fee,
      });

      const poolContract = new Contract(poolAddress, POOL_STATE_INTERFACE, signer);
      const slot0 = await poolContract.slot0();
      const liquidity = await poolContract.liquidity();

      const pool = PoolCache.getPool(tokenA, tokenB, position.fee, slot0.sqrtPriceX96, liquidity, slot0.tick);

      const uniPosition = new Position({
        pool,
        liquidity: position.liquidity.toString(),
        tickLower: position.tickLower,
        tickUpper: position.tickUpper,
      });

      const price0 = await getUSDCPrice(signer, chain_id, tokenA ?? undefined);
      const price1 = await getUSDCPrice(signer, chain_id, tokenB ?? undefined);

      if (!price0 || !price1) return null;

      const token0Value = price0.quote(uniPosition.amount0);
      const token1Value = price1.quote(uniPosition.amount1);

      return { token0Value, token1Value };
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getValueOfLiquidity = async (position) => {
    if (!position?.liquidity) return 0;

    const values = await getValueOfTokensInsidePosition(position);
    const totalValue = values.token0Value.add(values.token1Value);
    return totalValue.toFixed(2);
  };

  // given tokenInAmount (and both tokens), return tokenOutAmount
  const getTokenOutAmount = async (tokenInAmount, tokenIn, tokenOut) => {
    try {
      const signer = await provider?.getSigner();

      if (!tokenInAmount) {
        console.warn('getTokenOutAmount: tokenInAmount is undefined');
        return null;
      }

      if (typeof tokenInAmount !== BigNumber) tokenInAmount = BigNumber.from(tokenInAmount.toFixed(0));

      const _tokenIn = new Token(chain_id, tokenIn.address, tokenIn.decimals);
      const _tokenInAmount = CurrencyAmount.fromRawAmount(_tokenIn, tokenInAmount);
      const _tokenOut = new Token(chain_id, tokenOut.address, tokenOut.decimals);

      // get best v2 trade: signer, tradeType, amountSpecified of input token, outputCurrency
      const v2Trade = await getBestV2Trade(signer, TradeType.EXACT_INPUT, _tokenInAmount, _tokenOut);

      var price = 0;
      if (v2Trade) {
        const { numerator, denominator } = v2Trade.route.midPrice;
        price = new Price(_tokenIn, _tokenOut, denominator, numerator);
      } else {
        console.warn('getTokenOutAmount: no v2 trade found');
        return null;
      }

      if (!price) {
        console.warn('getTokenOutAmount: price is undefined');
        return null;
      }

      const _tokenOutAmount = price.quote(_tokenInAmount);
      return Number(_tokenOutAmount.toFixed(5));
    } catch (err) {
      console.error(err?.message);
      return null;
    }
  };

  // get USD value of a specific token amount
  const getTokenValue = async (tokenData, tokenAmount) => {
    try {
      const signer = await provider?.getSigner();
      const { address, decimals } = tokenData;
      const _token = new Token(chain_id, address, decimals);
      const _tokenAmount = CurrencyAmount.fromRawAmount(_token, tokenAmount);
      const price = await getUSDCPrice(signer, chain_id, _token);
      if (!price) return null;
      const tokenValue = price.quote(_tokenAmount);
      return Number(tokenValue.toFixed(5));
    } catch (err) {
      console.error(err?.message);
      return null;
    }
  };

  const getEthUsdcValue = async () => {
    const USDC_WETH_POOL_ADDRESS = '0x45dda9cb7c25131df268515131f647d726f50608';
    const pool = await getUniPool(USDC_WETH_POOL_ADDRESS);
    return pool.token0Price;
  };

  return { getValueOfTokensInsidePosition, getValueOfLiquidity, getTokenOutAmount, getTokenValue, getEthUsdcValue };
};
