import { useEffect } from 'react';
import { useSharedState } from '../../../context/store';
import { useUniswapPositions } from '../../../hooks/useUniswapPositions';
import SmartVaultBox from './SmartVaultBox';
import PositionsList from './PositionsList';
import networks from '../../../helpers/networks';
import FullPageLoader from '../../utils/FullPageLoader';
import NoWalletConnected from '../../utils/NoWalletConnected';
import actions from '../../../context/actions';

import OrbitLoader from '../../icons/OrbitLoader';
import HolderIcon from '../../icons/HolderIcon';
import '../home.scss';

export default function Import() {
  const [{ account, provider, chain_id, vault_positions, uniswap_user_positions }, dispatch] = useSharedState();
  const { getUniswapPositions } = useUniswapPositions();

  useEffect(() => {
    var isMounted = true;
    (async () => {
      if (provider && chain_id in networks) {
        const uniPositions = await getUniswapPositions();
        if (!isMounted) return;
        dispatch({ type: actions.SET_UNI_USER_POSITIONS, payload: { uniswap_user_positions: uniPositions } });
      }
    })();

    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  return (
    <>
      {account ? (
        <>
          <div className=' pb-2'>
            {uniswap_user_positions ? (
              <div className='row mt-6 gap-y-1 mx-auto'>
                <div className='col-12 col-md-4 p-0'>
                  <div className='text-center md:text-left mb-3'>
                    <div className='text-box-less-px bg-white'>
                      <h4 className='primary text-xl text-center font-bold work-sans-bold pb-1 uppercase'>
                        Your positions – Uni v3
                      </h4>
                      <div className='box-shadow background-light-gray border-none rounded-2xl p-2 mt-2'>
                        {!uniswap_user_positions && (
                          <div className='w-full h-[507px] grid place-items-center'>
                            <div className='w-36'>
                              <OrbitLoader />
                            </div>
                          </div>
                        )}
                        {uniswap_user_positions?.length > 0 && (
                          <div className='min-h-[507px]'>
                            <PositionsList positions={uniswap_user_positions} colsXs={1} colsMd={1} page='import' />
                          </div>
                        )}

                        {uniswap_user_positions &&
                          uniswap_user_positions?.length === 0 &&
                          vault_positions?.length === 0 && (
                            <div className='min-h-[507px] grid place-items-center'>
                              <div className='flex items-center gap-2 flex-col justify-center p-5'>
                                <HolderIcon />
                                <p className='text-xl text-center text-gray-500 font-medium'>
                                  Your active UNI V3 positions will appear here
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-md-0 mt-4 col-md-8 p-0'>
                  <div className='text-center md:text-left md:ml-4'>
                    <div className='text-box bg-white'>
                      <h4 className='primary text-xl text-center font-bold work-sans-bold pb-1 uppercase'>
                        import to your smart vault
                      </h4>
                      <SmartVaultBox positions={vault_positions} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <FullPageLoader />
            )}
          </div>
        </>
      ) : (
        <NoWalletConnected />
      )}
    </>
  );
}
