import { gql } from '@apollo/client';
import ApolloClient from './ApolloClient';
import addresses from '../contracts/addresses';

const GET_PROTOCOL_TVL = (chain_id) => {
  const factory = addresses[chain_id].PositionManagerFactory.toLowerCase();
  const queryString = `
  query protocolTVL {
    positionManagerFactory(id: "${factory}") {
      protocolTVL
    }
  }
  `;
  return queryString;
};

export const getProtocolTVL = async (chain_id) => {
  const source = 'orbitSubGraph';
  const result = await ApolloClient(chain_id, source).query({
    query: gql(GET_PROTOCOL_TVL(chain_id)),
  });

  return result.data;
};
