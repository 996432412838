import Card from './Card.js';

export default function BoxSection() {
  return (
    <>
      <div className='md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center'>
        <div className='md:col-span-1 lg:col-span-1 md:mb-0 text-center md:text-left'></div>
        <div className='md:col-span-10 lg:col-span-10 md:mb-0 text-center md:text-left max-w-6xl'>
          <h3 className='primary lg:text-5xl text-5xl mb-6 font-extrabold text-center work-sans-bold'>
            Making DeFi fluid
          </h3>
        </div>
        <div className='md:col-span-1 lg:col-span-1 md:mb-0 text-center md:text-left'></div>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 place-items-center gap-8'>
        <Card
          title={'Import'}
          description='Mint or deposit your positions inside a smart vault, or select a pre made strategy.'
          backgroundColor='white'
          titleColor='text-black'
          descriptionColor='text-gray'
          shadow='true'
        />
        <Card
          title='Decide strategy parameters'
          description='Decide how to employ idle liquidity and rebalance logic.'
          backgroundColor='white'
          titleColor='text-black'
          descriptionColor='text-gray'
          shadow='true'
        />
        <Card
          title='Withdraw any time'
          description='Exit whenever you feel like, with the option of using a single token.'
          backgroundColor='white'
          titleColor='text-black'
          descriptionColor='text-gray'
          shadow='true'
        />
      </div>
    </>
  );
}
