const OrbitLogoSvg = (props) => (
  <svg height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M94.3 47.938c-2.4 0-4.5-.5-6.2-1.5-1.7-1-3.1-2.4-4-4.2-.9-1.8-1.4-4-1.4-6.5s.5-4.7 1.4-6.6c.9-1.8 2.3-3.2 4-4.2 1.7-1 3.8-1.5 6.2-1.5 3.6 0 6.5 1.1 8.5 3.3 2.1 2.2 3.1 5.2 3.1 9 0 2.5-.5 4.7-1.4 6.5-.9 1.8-2.3 3.3-4 4.2-1.6 1-3.7 1.5-6.2 1.5Zm0-3c2.5 0 4.5-.8 5.9-2.5 1.4-1.7 2.1-3.9 2.1-6.8s-.7-5.2-2.1-6.8c-1.4-1.6-3.4-2.5-5.9-2.5-2.5 0-4.4.8-5.9 2.5-1.4 1.6-2.1 3.9-2.1 6.8s.7 5.1 2.1 6.8c1.5 1.7 3.5 2.5 5.9 2.5ZM113.2 47.438v-23.5h3.3l.3 5.7v17.8h-3.6Zm15.9-13.9v-2.7c0-1.3-.4-2.4-1.2-3.2-.8-.8-1.9-1.2-3.4-1.2-1.9 0-3.7.7-5.1 2.2-1.5 1.5-2.6 3.7-3.4 6.6l-.6-3.9c.6-1.7 1.4-3.2 2.3-4.4.9-1.2 2-2.1 3.2-2.7 1.2-.6 2.6-.9 4.1-.9 2.3 0 4.2.7 5.6 2 1.4 1.3 2.1 3.1 2.1 5.4v2.7h-3.6v.1ZM151.4 23.438c2.1 0 3.9.5 5.5 1.5s2.9 2.5 3.8 4.3c.9 1.8 1.4 4 1.4 6.4 0 2.4-.5 4.5-1.4 6.4-.9 1.8-2.2 3.3-3.8 4.3-1.6 1-3.4 1.6-5.5 1.6-1.7 0-3.3-.4-4.7-1.1-1.4-.7-2.5-1.7-3.3-3l-.1 3.6H140v-31.4h3.6v11.4c.8-1.2 1.9-2.2 3.3-2.9 1.2-.8 2.8-1.1 4.5-1.1Zm-.7 21.5c1.6 0 2.9-.4 4.1-1.2 1.2-.8 2.1-1.9 2.7-3.2.6-1.4 1-3 1-4.8 0-1.9-.3-3.5-1-4.9-.6-1.4-1.5-2.5-2.7-3.2-1.2-.8-2.5-1.1-4.1-1.1-1.6 0-3.1.4-4.4 1.3-1.3.9-2.3 2.1-2.9 3.7v8.4c.6 1.6 1.6 2.8 2.9 3.7 1.3.8 2.8 1.3 4.4 1.3ZM167.5 47.438v-2.9h20.8v2.9h-20.8Zm3.2-20.6v-2.9h7.6v2.9h-7.6Zm5.5-7.2v-4.1h4.1v4.1h-4.1Zm.3 27.8v-23.5h3.6v23.5h-3.6ZM191.5 26.838v-2.9h17.3v2.9h-17.3Zm12.4 20.6c-1.4 0-2.6-.3-3.6-.8s-1.7-1.3-2.1-2.3c-.5-1-.7-2.2-.7-3.6v-21.4l3.5-2.1v23.6c0 .7.1 1.3.3 1.9.2.6.6 1.1 1.2 1.4.6.4 1.5.5 2.6.5h7.4v2.9h-8.6v-.1Z'
      fill='#525252'
    />
    <path
      d='M69 47.838c.5.3 1.1 0 1.3-.5.1-.4 0-.9-.4-1.1l-9.1-5.9c-.4.9-.9 1.8-1.5 2.6l9.7 4.9ZM51.9 24.038l.5-18.8c0-1.5-1.1-2.7-2.5-2.7s-2.5 1.2-2.5 2.7l.5 18.8c.6-.1 1.3-.2 1.9-.2.8 0 1.4.1 2.1.2ZM61.7 58.738c.5.7 1.5.8 2.1.2.3-.4.4-1 .1-1.4l-6.7-12.4c-.9.7-1.8 1.2-2.9 1.7l7.4 11.9ZM38.5 32.538 6 30.138c-3.2-.2-6 2.3-6 5.5v.2c0 3.2 2.8 5.8 6 5.5l32.5-2.4c-.3-1-.4-2.1-.4-3.2s.1-2.2.4-3.2ZM71.6 35.038l-9.9-.7c.1.5.1 1 .1 1.5s0 1-.1 1.4l9.9-.7c.4 0 .7-.3.7-.7-.1-.5-.4-.8-.7-.8ZM33.6 1.538c-.6-1.1-1.8-1.7-3-1.5h-.1c-2.1.3-3.1 2.7-2 4.5l13.7 22.3c1.1-1 2.5-1.8 4-2.3l-12.6-23ZM69.9 25.238c.4-.2.5-.7.4-1.1-.2-.5-.8-.8-1.3-.5l-9.6 4.9c.6.8 1.1 1.7 1.5 2.6l9-5.9ZM63.9 14.038c.2-.5.2-1-.1-1.4-.6-.7-1.6-.6-2.1.2l-7.4 12c1 .4 2 1 2.9 1.7l6.7-12.5ZM28.5 66.938c-1.1 1.8 0 4.1 2 4.5h.1c1.2.2 2.4-.4 3-1.5l12.4-23c-1.5-.5-2.8-1.3-4-2.3l-13.5 22.3ZM10.7 53.338c-2.5 1.3-3.1 4.6-1.2 6.7l.1.1c1.4 1.5 3.7 1.8 5.5.7l26.3-17c-1.2-1.3-2.2-2.9-2.7-4.7l-28 14.2ZM48 47.438l-.5 18.8c0 1.5 1.1 2.7 2.5 2.7s2.5-1.2 2.5-2.7l-.5-18.8c-.6.1-1.3.2-2 .2-.7-.1-1.4-.1-2-.2ZM15 10.538c-1.7-1.1-4-.9-5.5.7l-.1.1c-1.9 2.1-1.3 5.4 1.2 6.7l27.9 14.3c.5-1.8 1.5-3.4 2.7-4.7L15 10.538Z'
      fill='#520FEC'
    />
  </svg>
);

export default OrbitLogoSvg;
