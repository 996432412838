import { ResponsiveLine } from '@nivo/line';
import { useSharedState } from '../../../context/store';

export default function PositionLineChart() {
  const [{ analytics }] = useSharedState();
  const snapshots = analytics?.dailySnapshots;
  let minValue = snapshots[0].HODLValueUSD;
  let maxValue = snapshots[0].HODLValueUSD;

  if (!snapshots) return NoData();

  const minAmountOfSnapshots = 2;
  if (snapshots.length < minAmountOfSnapshots) return NotEnoughSnapshots();

  const marketValueHistory = {
    id: 'Market value',
    color: '#44ffd1', // secondary
    data: snapshots.map((snapshot) => {
      return {
        x: formatDate(new Date(snapshot.date)),
        y: formatNumber(snapshot.marketValueUSD),
      };
    }),
  };

  const hodlValueHistory = {
    id: 'HODL value',
    color: '#520fec', //primary
    data: snapshots.map((snapshot) => {
      if (Math.min(snapshot.HODLValueUSD, snapshot.marketValueUSD) < minValue)
        minValue = Math.min(snapshot.HODLValueUSD, snapshot.marketValueUSD);
      if (Math.max(snapshot.HODLValueUSD, snapshot.marketValueUSD) > maxValue)
        maxValue = Math.max(snapshot.HODLValueUSD, snapshot.marketValueUSD);

      return {
        x: formatDate(new Date(snapshot.date)),
        y: formatNumber(snapshot.HODLValueUSD),
      };
    }),
  };

  const data = [hodlValueHistory, marketValueHistory];

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 15, bottom: 37, left: 40 }}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', stacked: false, min: minValue * 0.95, max: maxValue * 1.05 }}
      curve='catmullRom'
      axisTop={null}
      axisRight={null}
      tooltip={({ point }) => {
        const index = point.id.split('.')[1];
        return (
          <div className='bg-white p-2 rounded-lg border-2 border-gray-300'>
            <div className='flex pb-2'>
              <h2 className='work-sans-bold text-gray border-b-2 border-secondary'>Market Value: </h2>
              <h2 className='work-sans-bold text-gray'>&nbsp;${data[1].data[index].y.toFixed(2)}</h2>
            </div>
            <div className='flex'>
              <h2 className='work-sans-bold text-gray border-b-2 border-primary'>HODL Value: </h2>
              <h2 className='work-sans-bold text-gray'>&nbsp;${data[0].data[index].y.toFixed(2)}</h2>
            </div>
          </div>
        );
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 7,
        tickRotation: -35,
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickValues: 3,
        tickPadding: 4,
        tickRotation: 0,
        legend: '',
        legendOffset: -40,
        legendPosition: 'middle',
        format: (value) =>
          `$${Number(value).toLocaleString('en-US', {
            minimumFractionDigits: 0,
          })}`,
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: '#878C98',
            },
          },
        },
        grid: {
          line: {
            stroke: '#d1d1d1',
            strokeWidth: 0.5,
          },
        },
      }}
      colors={{ datum: 'color' }}
      pointSize={0}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel='y'
      pointLabelYOffset={-12}
      enableArea={false}
      enableGridX={true}
      enableGridY={true}
      gridYValues={4}
      enableLabels={false}
      isInteractive={true}
      useMesh={true}
      animate={true}
      motionConfig={'stiff'}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}

const NoData = () => {
  return (
    <div className='w-full h-full grid place-content-center'>
      <h2 className='text-gray azeret text-lg'>No available data 🔍</h2>
    </div>
  );
};

const NotEnoughSnapshots = () => {
  return (
    <div className='w-full h-full grid place-content-center'>
      <h2 className='text-gray azeret text-lg text-center px-3'>
        This position is too new, <br />
        please wait a few days for more data to be collected.
      </h2>
    </div>
  );
};

const formatNumber = (number) => Number(Number(number).toFixed(2));

const formatDate = (date) => {
  return `${date.getDate()} ${Date.locale['en'].month_names_short[date.getMonth()]}`;
};

Date.locale = {
  en: {
    month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
};
