import { useEffect } from 'react';

export const useEvents = () => {
  useEffect(() => {
    window?.ethereum?.on('chainChanged', chainChanged);
    window?.ethereum?.on('accountsChanged', accountsChanged);

    return () => window?.ethereum?.removeAllListeners();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *****************  Event handlers *****************

  const chainChanged = () => window.location.reload();

  const accountsChanged = () => window.location.reload();
};
