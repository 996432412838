import Select from 'react-select';
import { useEffect, useState, useMemo } from 'react';
import { useSharedState } from '../../../context/store';
import { useZapWithdraw } from '../../../hooks/useZapWithdraw';
import { useWithdraw } from '../../../hooks/useWithdraw';
import { useToken } from '../../../hooks/useToken';
import { useTokenValue } from '../../../hooks/useTokenValue';
import { getAmountsForLiquidity, getPoolTick } from '../../../utils/utils';
import TokenImage from '../../utils/TokenImage';
import PoolImage from '../../utils/PoolImage';

export default function ZapSection() {
  const [{ chain_id, provider, selected_position }] = useSharedState();
  const { zapOutFromAave, zapOutFromUniswap } = useZapWithdraw();
  const { getAaveWithdrawTokenAmount } = useWithdraw();
  const { getTokenOutAmount } = useTokenValue();
  const { getZapTokensInfo } = useToken();

  const [tick, setTick] = useState();
  const [zapToken, setZapToken] = useState();
  const [zapTokens, setZapTokens] = useState([]);
  const [tokenOutValue, setTokenOutValue] = useState('');

  const buildToken = (t) => {
    return { address: selected_position[`token${t}Address`], decimals: selected_position[`token${t}Decimals`] };
  };

  const allZapTokens = useMemo(async () => await getZapTokensInfo(), [getZapTokensInfo]);

  useEffect(() => {
    var isMounted = true;

    const updatePoolTick = async () => {
      const poolTick = await getPoolTick(
        chain_id,
        await provider.getSigner(),
        selected_position.token0Address,
        selected_position.token1Address,
        selected_position.token0Decimals,
        selected_position.token1Decimals,
        selected_position.fee * 10000,
      );
      if (!isMounted) return;
      setTick(poolTick);
    };

    if (provider && chain_id) updatePoolTick();
    return () => (isMounted = false);
  }, [provider, chain_id, selected_position]);

  const shouldWithdrawFromAave = useMemo(() => {
    if (selected_position?.isOnAave) return true;
    else return false;
  }, [selected_position]);

  const tokenToAave = useMemo(() => {
    if (shouldWithdrawFromAave) {
      if (selected_position?.tickLower > tick || tick > selected_position?.tickUpper) {
        const outOfRangeDirection = tick > selected_position?.tickUpper ? 'up' : 'down';
        if (outOfRangeDirection === 'up') return selected_position?.token1Address;
        else if (outOfRangeDirection === 'down') return selected_position?.token0Address;
        else throw new Error('Expected an OOR direction');
      }
    }
    return null;
  }, [shouldWithdrawFromAave, selected_position, tick]);

  const _showAmountToWithdrawFromAave = async () => {
    const amountWithdrawnFromAave = await getAaveWithdrawTokenAmount('100');
    var amountFinal = 0;

    if (tokenToAave.toLowerCase() === selected_position.token0Address.toLowerCase()) {
      amountFinal = amountWithdrawnFromAave / Math.pow(10, selected_position.token0Decimals);
    } else if (tokenToAave.toLowerCase() === selected_position.token1Address.toLowerCase()) {
      amountFinal = amountWithdrawnFromAave / Math.pow(10, selected_position.token1Decimals);
    } else throw new Error('Expected tokenToAave to be a pool token');

    if (zapToken.key !== tokenToAave) {
      if (zapToken.key.toLowerCase() === selected_position.token0Address.toLowerCase()) {
        amountFinal = (await getTokenOutAmount(amountWithdrawnFromAave, buildToken(1), buildToken(0))) || 0;
      } else if (zapToken.key.toLowerCase() === selected_position.token1Address.toLowerCase()) {
        amountFinal = (await getTokenOutAmount(amountWithdrawnFromAave, buildToken(0), buildToken(1))) || 0;
      } else throw new Error('Expected zapToken to be a pool token');
    }

    return amountFinal;
  };

  const _showAmountToWithdrawFromUniswap = async () => {
    const liqToWithdraw = selected_position.liquidity;
    var amountFinal = 0;

    const { amount0, amount1 } = getAmountsForLiquidity(
      tick,
      selected_position.tickLower,
      selected_position.tickUpper,
      liqToWithdraw,
    );

    if (zapToken.key.toLowerCase() === selected_position.token0Address.toLowerCase()) {
      // Convert amount1 value to token0 and add to amount0 value
      amountFinal += amount0 / Math.pow(10, selected_position.token0Decimals) || 0;
      amountFinal += (await getTokenOutAmount(amount1, buildToken(1), buildToken(0))) || 0;
    } else if (zapToken.key.toLowerCase() === selected_position.token1Address.toLowerCase()) {
      // Convert amount0 value to token1 and add to amount1 value
      amountFinal += amount1 / Math.pow(10, selected_position.token1Decimals) || 0;
      amountFinal += (await getTokenOutAmount(amount0, buildToken(0), buildToken(1))) || 0;
    } else {
      // Since zapToken is different from pool tokens, convert both amount0 and amount1 values to zapToken
      // NOTE: this feature has been disabled for now.
      throw new Error('Zapping to a different token is disabled.');

      // eslint-disable-next-line no-unreachable
      const _zapToken = (await allZapTokens).find((t) => t.tokenAddress.toLowerCase() === zapToken.key.toLowerCase());
      if (!_zapToken) return;
      const zapTokenInfo = { address: _zapToken.tokenAddress, decimals: _zapToken.tokenDecimals };
      amountFinal += (await getTokenOutAmount(amount0, buildToken(0), zapTokenInfo)) || 0;
      amountFinal += (await getTokenOutAmount(amount1, buildToken(1), zapTokenInfo)) || 0;
    }

    return amountFinal;
  };

  useEffect(() => {
    var isMounted = true;
    var amountFinal = 0;

    const showAmountToWithdrawn = async () => {
      try {
        setTokenOutValue('...');

        if (shouldWithdrawFromAave && tokenToAave) amountFinal = await _showAmountToWithdrawFromAave();
        else if (!shouldWithdrawFromAave) amountFinal = await _showAmountToWithdrawFromUniswap();
        if (!amountFinal) console.warn('showAmountToWithdraw: not found');
        if (!isMounted) return;

        setTokenOutValue(amountFinal);
      } catch (e) {
        console.error(e?.message);
      }
    };

    if (zapToken && tick) showAmountToWithdrawn();
    return () => (isMounted = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldWithdrawFromAave, provider, selected_position, zapToken, tick]);

  useEffect(() => {
    const setZap = async () => {
      const tokensArray = (await allZapTokens).filter(
        (token) =>
          token.tokenAddress.toLowerCase() !== selected_position.token0Address.toLowerCase() &&
          token.tokenAddress.toLowerCase() !== selected_position.token1Address.toLowerCase(),
      );

      let options = [];
      options.push({
        value: selected_position.token0Address,
        key: selected_position.token0Address,

        label: (
          <div className='flex'>
            <TokenImage token={selected_position.token0} offsetSize='25px' offsetMarginLeft='0' />
            <p className='ml-2 text-black'>{selected_position.token0.toUpperCase()}</p>
          </div>
        ),
      });
      options.push({
        value: selected_position.token1Address,
        key: selected_position.token1Address,

        label: (
          <div className='flex'>
            <TokenImage token={selected_position.token1} offsetSize='25px' offsetMarginLeft='0' />
            <p className='ml-2 text-black'>{selected_position.token1.toUpperCase()}</p>
          </div>
        ),
      });
      for (var i = 0; i < tokensArray.length; i++) {
        options.push({
          value: tokensArray[i].tokenAddress,
          key: tokensArray[i].tokenAddress,

          label: (
            <div className='flex'>
              <TokenImage token={tokensArray[i].tokenSymbol} offsetSize='25px' offsetMarginLeft='0' />
              <p className='ml-2 text-black'>{tokensArray[i].tokenSymbol.toUpperCase()}</p>
            </div>
          ),
        });
      }

      setZapTokens(options);
      setZapToken(options[0]);
    };

    setZap();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_position]);

  return (
    <>
      {selected_position && (
        <div className='py-2 px-4 mt-4'>
          <div className='text-center md:text-left background-light-gray rounded-xl'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
              you withdraw
            </h4>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <div className='col-span-2 items-center'>
              <PoolImage
                token0={selected_position.token0.toLowerCase()}
                token1={selected_position.token1.toLowerCase()}
                offsetMarginLeft='-18px'
                size='45px'
                className={'mx-auto'}
              />
              <p className='text-md azeret-md text-gray self-center ml-2'>
                {selected_position.token0.toUpperCase()}-{selected_position.token1.toUpperCase()}
              </p>
            </div>
            <div className='text-center md:text-left background-light-gray rounded-xl border-none grid grid-cols-1 mx-auto px-3 col-span-3'>
              <input disabled type='text' value='100%' className='text-gray text-xl text-right azeret hidden-input' />
            </div>
          </div>
          <div className='text-center md:text-left background-light-gray rounded-xl mt-4'>
            <h4 className='text-black text-lg text-center font-bold work-sans-bold primary py-3 uppercase'>
              you receive
            </h4>
          </div>
          <div className='text-center md:text-left mt-3 border-primary grid grid-cols-5 mx-auto py-2'>
            <Select
              className='text-black azeret-md uppercase t-align-center text-md col-start-1 col-end-3 rounded-xl border-primary border-2 z-10'
              classNamePrefix='text-center text-black azeret-md uppercase t-align-center text-xl z-10'
              menuPortalTarget={document.body}
              isSearchable={false}
              value={zapToken}
              onChange={(e) => setZapToken(e)}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  boxShadow: 'none',
                  border: 'none',
                  backgroundColor: 'transparent',
                  zIndex: '1000',
                }),
                menu: (provided, state) => ({
                  ...provided,
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: 'white',
                  zIndex: '1000',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: 'white',
                  color: 'black',
                  zIndex: '1000',
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              options={zapTokens}
            />

            <div className='text-center background-light-gray rounded-xl grid grid-cols-1 ml-2 mr-0 py-2 px-3 col-span-3'>
              <input
                readOnly
                type='text'
                placeholder='0'
                value={tokenOutValue || '...'}
                className='text-gray text-xl text-right azeret hidden-input'
              />
            </div>
          </div>
          <div className='row mx-auto'>
            <button
              className='rounded-2xl btn-main font-medium px-5 px-md-3 px-lg-5 my-3 mr-0 ml-auto uppercase'
              onClick={() => (shouldWithdrawFromAave ? zapOutFromAave(zapToken.key) : zapOutFromUniswap(zapToken.key))}
            >
              Withdraw
            </button>
          </div>
        </div>
      )}
    </>
  );
}
