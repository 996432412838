import { gql } from '@apollo/client';
import ApolloClient from './ApolloClient';

const GET_TICK_DATA = (poolAddress, minimumTick, maximumTick) => {
  const queryString = `
  {
    ticks(first: 1000, where: { 
        poolAddress: "${poolAddress.toLowerCase()}" , 
        tickIdx_gt: ${minimumTick}, 
        tickIdx_lt: ${maximumTick} 
      }, orderBy: tickIdx) {
      tickIdx
      liquidityNet
    }
  }`;

  return queryString;
};

export const getTickData = async (chain_id, poolAddress, minimumTick, maximumTick) => {
  const source = 'uniswapSubGraph';

  const result = await ApolloClient(chain_id, source).query({
    query: gql(GET_TICK_DATA(poolAddress, minimumTick, maximumTick)),
  });
  return result.data;
};
