const LinkSvg = (props) => {
  return (
    <svg width={22} height={22} fill='none' xmlns='http://www.w3.org/2000/svg' className='stroke-btn'>
      <path
        d='M12.544 9.456a4.368 4.368 0 0 0-6.176 0L3.28 12.544a4.368 4.368 0 1 0 6.177 6.177L11 17.177'
        strokeWidth={2.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.456 12.544a4.368 4.368 0 0 0 6.176 0l3.09-3.088a4.368 4.368 0 0 0-6.178-6.177L11 4.823'
        strokeWidth={2.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LinkSvg;
