import Card from './Card.js';

export default function SolutionSection() {
  return (
    <>
      <div className='md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center lg:pt-24 md:pt-24 sm:pt-4'>
        <div className='md:col-span-9 lg:col-span-9 mb-8 md:mb-0 max-w-6xl'>
          <h3 className='secondary lg:text-7xl text-5xl mb-4 work-sans-bold'>
            Concentrated liquidity<br></br>made easy
          </h3>
          <p className='text-xl text-white azeret'>
            A solution that combines<br></br>flexibility and automation
          </p>
        </div>
        <div className='md:col-span-3 lg:col-span-3 mb-8 md:mb-0 text-center md:text-left'></div>
      </div>
      <div className='grid md:grid-cols-1 place-items-center lg:grid-cols-3 grid-cols-1 gap-8'>
        <Card
          title={'Flexibility'}
          description='Mix any amount of position and tokens from multiple protocols and automatically manage it.'
          backgroundColor='purple-2'
          titleColor='secondary'
          descriptionColor='text-light-gray'
          border='border-secondary'
        />
        <Card
          title='Less work, more yield'
          description="Do not waste time. We'll optimize every inch of liquidity."
          backgroundColor='purple-2'
          titleColor='secondary'
          descriptionColor='text-light-gray'
          border='border-secondary'
        />
        <Card
          title='No vault fees'
          description='Cheapest solution in the market. Uni v3 vaults takes 5-10% of positions. Orbit is currently free.'
          backgroundColor='purple-2'
          titleColor='secondary'
          descriptionColor='text-light-gray'
          border='border-secondary'
        />
      </div>
    </>
  );
}
