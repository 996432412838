import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSharedState } from '../../../context/store';
import { useNotifications } from '../../../hooks/useNotifications';
import { getERC20, getPositionManager, getAaveToken } from '../../../helpers/contracts';
import NoWalletConnected from '../../utils/NoWalletConnected';
import networks from '../../../helpers/networks';
import TokenImage from '../../utils/TokenImage';
import '../home.scss';

export default function WithdrawERC20() {
  const [{ account, chain_id, provider }] = useSharedState();
  const { notifyLoadingAsyncTx, setWalletActionRequired } = useNotifications();

  const [positionManager, setPositionManager] = useState(null);
  const [tokensStuck, setTokensStuck] = useState([]);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    if (account && provider && chain_id in networks)
      (async () => setPositionManager(await getPositionManager(account, chain_id, await provider.getSigner())))();
  }, [account, provider, chain_id]);

  useEffect(() => {
    if (positionManager?.address && provider)
      (async () => {
        const signer = await provider.getSigner();
        const apiUrl = `https://api.polygonscan.com/api`;
        const response = await axios.get(apiUrl, {
          headers: { 'Content-Type': 'application/json' },
          params: {
            module: 'account',
            action: 'tokentx',
            address: positionManager.address,
            page: 1,
            offset: 500,
            sort: 'asc',
            apikey: process.env.REACT_APP_ETHERSCAN_KEY,
          },
        });
        if (!response?.data || response?.status !== 200) setApiError(true);

        const tokensInsidePM = [];

        try {
          const allTokens = response.data.result.map((token) => token.contractAddress);
          const uniqueTokens = [...new Set(allTokens)];

          for (const address of uniqueTokens) {
            const erc20 = getERC20(address, signer);
            const balance = await erc20.balanceOf(positionManager.address);
            const symbol = await erc20.symbol();
            const decimals = await erc20.decimals();

            if (!balance.eq(0)) tokensInsidePM.push({ address, symbol, decimals, balance });
          }
        } catch (err) {
          console.error(err?.message);
          setApiError(true);
        }

        // Remove aTokens from the list to prevent users from breaking their position manager
        const aaveTokens = [];
        for (const token of tokensInsidePM) {
          try {
            const aaveToken = getAaveToken(token.address, signer);
            await aaveToken.POOL();
            aaveTokens.push(token);
          } catch (err) {
            continue;
          }
        }

        setTokensStuck(tokensInsidePM.filter((token) => !aaveTokens.includes(token)));
      })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain_id, provider, positionManager]);

  const withdrawERC20 = async (tokenAddress) => {
    if (positionManager?.address) {
      try {
        setWalletActionRequired(true);
        const tx = await positionManager.withdrawERC20(tokenAddress);
        setWalletActionRequired(false);

        notifyLoadingAsyncTx(tx, 'Withdrawing ERC20 token');
      } catch (err) {
        console.error(err?.message);
      }
    } else throw new Error('No PositionManager found');
  };

  return (
    <>
      {account ? (
        <>
          <div className='bg-white grid place-content-center p-4 rounded-xl my-4'>
            <p className='azeret font-medium text-gray-700 text-lg max-w-xl text-center'>
              If you have ERC20 tokens stuck in your Position Manager, you can withdraw them from this page.
              <br />
              {positionManager?.address ? (
                <a
                  className='underline text-primary'
                  href={`${networks[chain_id].blockExplorerUrls[0]}/address/${positionManager.address}`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  View on Block Explorer
                </a>
              ) : (
                ''
              )}
            </p>
            <div className='flex flex-col max-w-xl mt-3'>
              {!apiError ? (
                tokensStuck?.length > 0 && (
                  <>
                    {tokensStuck.map((token, id) => (
                      <div key={id} className='flex items-center w-full py-3 border-b gap-2 justify-between'>
                        <div className='flex gap-3 items-center'>
                          <TokenImage token={token.symbol.toLowerCase()} offsetMarginLeft='0' offsetSize='45px' />
                          <p>
                            <span className='azeret-bold text-lg md:text-xl text-gray'>{token.symbol}</span>
                            <span className='md:px-2 block md:inline' />
                            <span className='azeret text-lg md:text-xl text-gray'>
                              {(Number(token.balance) / Math.pow(10, token.decimals)).toPrecision(7)}
                            </span>
                          </p>
                        </div>
                        <button
                          className='bg-second btn-main uppercase md:text-lg text-base primary work-sans-bold md:py-2 md:px-5 rounded-xl'
                          onClick={() => withdrawERC20(token.address)}
                        >
                          Withdraw
                        </button>
                      </div>
                    ))}
                  </>
                )
              ) : (
                <p className='azeret text-center text-gray-700 font-medium'>
                  There was an error fetching your tokens. Please try again later.
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <NoWalletConnected />
      )}
    </>
  );
}
