import { useEffect, useState } from 'react';
import { useDeposit } from '../../../hooks/useDeposit';
import { useApprove } from '../../../hooks/useApprove';
import PositionsList from './PositionsList';
import InfoIcon from '../../../images/info.svg';

export default function SmartVaultBox({ positions }) {
  const { deposit } = useDeposit();
  const { checkIsApprovedForAll, setApprovalForAll } = useApprove();

  const [ids, setIds] = useState([]);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const positionIds = positions.map((position) => position.tokenId);
    setIds(positionIds);
  }, [positions]);

  useEffect(() => {
    const checkApprove = async () => setIsApproved(await checkIsApprovedForAll());
    checkApprove();
  });

  const handleApprove = () => {
    setApprovalForAll();
    setIsApproved(true);
  };

  const handleDeposit = async () => {
    await deposit(ids);
    await new Promise((r) => setTimeout(r, 10000));
    window.location.reload();
  };

  return (
    <div>
      <div className='p-2'>
        <div className='box-shadow window background-light-gray border-none overflow-auto p-2'>
          <PositionsList positions={positions} colsXs={1} colsMd={2} page='vault' />
        </div>
        <div className='row pt-4'>
          <div className='col-12 col-md-8'>
            <div className='flex-row flex'>
              <img src={InfoIcon} alt='info' width='38rem' height='auto' />
              <h3 className='pl-3 primary azeret font-medium text-sm text-left'>
                Press the plus button to add your Uniswap positions you want to import.
              </h3>
            </div>
          </div>
          <div className='col-12 col-md-4 t-align-right'>
            {isApproved ? (
              <button
                className='rounded-xl btn-main px-5 px-md-3 px-lg-5 mr-0 ml-auto uppercase'
                onClick={() => handleDeposit(ids)}
              >
                Deposit
              </button>
            ) : (
              <button
                className='rounded-xl btn-main px-5 px-md-3 px-lg-5 mr-0 ml-auto uppercase'
                onClick={() => handleApprove()}
              >
                Approve
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
