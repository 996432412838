const HolderIcon = (props) => (
  <svg width={61} height={55} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity={0.7} stroke='#525252' strokeWidth={3} strokeLinejoin='round'>
      <path d='M30 39c5.523 0 10-4.477 10-10h19v19a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V29h18c0 5.523 4.477 10 10 10Z' />
      <path d='M30 39c5.523 0 10-4.477 10-10h19L52.388 3.498A2 2 0 0 0 50.452 2H10.548a2 2 0 0 0-1.936 1.498L2 29h18c0 5.523 4.477 10 10 10Z' />
    </g>
  </svg>
);

export default HolderIcon;
