import { ethers } from 'ethers';
import { useSharedState } from '../context/store';
import { useNotifications } from './useNotifications';
import actions from '../context/actions';
import networks from '../helpers/networks';

export const useWallet = () => {
  const [, dispatch] = useSharedState();
  const { notify } = useNotifications();

  const connectMetamask = async () => {
    if (!window.ethereum) notify('error', 'No compatible wallet found.');

    const Web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = (await Web3Provider.send('eth_requestAccounts', []))[0];
    const { name: network_name, chainId: chain_id } = await Web3Provider.getNetwork();

    dispatch({ type: actions.LOGIN_WALLET, payload: { account, provider: Web3Provider, network_name, chain_id } });
  };

  const loginWallet = async () => {
    try {
      await connectMetamask();
      notify('success', 'Wallet connected');
    } catch (err) {
      console.error(err);
    }
  };

  const autoLoginWallet = async () => {
    const shouldAutoConnect = window.localStorage.getItem('shouldConnectMetamask') === 'true';

    if (shouldAutoConnect) {
      await loginWallet();
    }
  };

  const logoutWallet = () => {
    dispatch({ type: actions.LOGOUT_WALLET });
    notify('success', 'Wallet disconnected');
  };

  const changeNetwork = async (chainId) => {
    if (!window.ethereum) notify('error', 'No compatible wallet found.');
    try {
      await window.ethereum.request({ method: 'wallet_addEthereumChain', params: [{ ...networks[chainId] }] });

      const Web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      const { name: network_name, chainId: chain_id } = await Web3Provider.getNetwork();
      dispatch({ type: actions.CHANGE_NETWORK, payload: { provider: Web3Provider, network_name, chain_id } });
    } catch (err) {
      console.error(err?.message);
    }
  };

  const checkValidNetwork = (chainId) => chainId in networks;

  return { loginWallet, autoLoginWallet, logoutWallet, changeNetwork, checkValidNetwork };
};
