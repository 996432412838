import { HashLink as Link } from 'react-router-hash-link';
import OrbitLogoSvg from '../icons/OrbitLogoSvg';
//import { useState, useEffect } from 'react';
//import { useAnalytics } from '../../hooks/useAnalytics';
//import { getDollarFormat } from '../../utils/utils';

export default function Header() {
  // const { getTotalTVL } = useAnalytics();
  // const [tvl, setTvl] = useState(0);

  // useEffect(() => {
  //   (async () => setTvl(await getTotalTVL()))();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <header className='row pt-8'>
      {/* Desktop header */}
      <div className='col-5 sm:col-2 d-lg-block d-none'>
        <div className='flex items-center justify-between'>
          <OrbitLogoSvg viewBox='0 0 400 76' height='90' />
        </div>
      </div>
      <div className='col-1 d-lg-block d-none'></div>
      <div className='col-6 lg:mt-6 md:mt-6 d-lg-block d-none t-align-right'>
        <div className='flex gap-3 w-full justify-end'>
          {/*tvl ? (
            <div className='inline-block border-2 border-primary rounded-2xl tvl-pill bg-white'>
              <h2 className='azeret primary font-semibold'>
                <span className='work-sans-bold text-gray'>TVL</span> {getDollarFormat(tvl)}
              </h2>
            </div>
          ) : (
            ''
          )*/}
          <Link to='/dashboard' rel='noopener noreferrer' className='ml-4 inline-block no-underline t-align-center'>
            <button className='rounded-2xl launch-button bolder azeret-md py-2 mr-0 uppercase' data-aos='fade-left'>
              Launch App
            </button>
          </Link>
        </div>
      </div>

      {/* Mobile header */}
      <div className='col-6 d-lg-none d-block'>
        <div className='flex items-center justify-between'>
          <OrbitLogoSvg viewBox='0 0 200 100' className='align-middle block w-full d-block d-lg-none' />
        </div>
      </div>
      <div className='col-6 d-block d-lg-none'>
        <Link to='/dashboard' rel='noopener noreferrer'>
          <button className='form-control rounded-3xl launch-button azeret-md mt-2 uppercase' data-aos='fade-left'>
            Launch App
          </button>
        </Link>
      </div>
    </header>
  );
}
