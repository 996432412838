import { useRef } from 'react';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { useSharedState } from '../context/store';
import { ethers } from 'ethers';
import actions from '../context/actions';
import networks from '../helpers/networks';

const INITIAL_STATE = {
  connector: null,
  fetching: false,
  connected: false,
  chainId: 1,
  showModal: false,
  pendingRequest: false,
  uri: '',
  accounts: [],
  address: '',
  result: null,
  assets: [],
};

export const useWalletConnect = () => {
  const connectorState = useRef(INITIAL_STATE);
  const [, dispatch] = useSharedState();

  const connect = async () => {
    // bridge url
    const bridge = 'https://bridge.walletconnect.org';

    // create new connector
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    connectorState.current = { ...connectorState.current, connector: connector };

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }

    // subscribe to events
    await subscribeToEvents();
  };

  const subscribeToEvents = async () => {
    if (!connectorState.current.connector) {
      return;
    }

    connectorState.current.connector.on('session_update', async (error, payload) => {
      if (error) {
        throw error;
      }

      const { chainId, accounts } = payload.params[0];
      onSessionUpdate(accounts, chainId);
    });

    connectorState.current.connector.on('connect', (error, payload) => {
      const { chainId, accounts } = payload.params[0];
      const Web3Provider = new ethers.providers.Web3Provider(window.ethereum);

      dispatch({
        type: actions.LOGIN_WALLET,
        payload: {
          account: accounts[0],
          provider: Web3Provider,
          network_name: networks[chainId].chainName,
          chain_id: chainId,
        },
      });
      if (error) {
        throw error;
      }

      connectorState.current = { ...connectorState.current, connected: true, chainId, address: accounts[0], accounts };
    });

    connectorState.current.connector.on('disconnect', (error) => {
      if (error) {
        throw error;
      }

      connectorState.current = { ...INITIAL_STATE };
    });

    if (connectorState.current.connector.connected) {
      const { chainId, accounts } = connectorState.current.connector;
      const address = accounts[0];
      connectorState.current = { ...connectorState.current, connected: true, chainId, address, accounts };

      onSessionUpdate(accounts, chainId);
    }
  };

  const killSession = () => {
    const connector = connectorState.current.connector;
    if (connector) {
      connector.killSession();
    }

    connectorState.current = { ...INITIAL_STATE };
  };

  const onSessionUpdate = async (accounts, chainId) => {
    dispatch({ type: actions.UPDATE_POSITIONS, payload: { positions: [] } });

    const address = accounts[0];
    connectorState.current = { ...connectorState.current, chainId, address, accounts };
    const Web3Provider = new ethers.providers.Web3Provider(window.ethereum);

    if (address && chainId) {
      dispatch({
        type: actions.LOGIN_WALLET,
        payload: {
          account: address,
          provider: Web3Provider,
          network_name: networks[chainId].chainName,
          chain_id: chainId,
        },
      });
    }
  };

  return { connect, killSession };
};
