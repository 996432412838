/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useWallet } from '../../hooks/useWallet';
import { useEvents } from '../../hooks/useEvents';
import { useBackgroundWaves } from '../../hooks/useBackgroundWaves';

import WalletHeader from './WalletHeader';
import WalletFooter from './WalletFooter';
import Navbar from './Navbar';
import Footer from '../landing/Footer';
import CreatePM from '../utils/CreatePM';
import LoadingModal from '../utils/LoadingModal';

import orbitIcon from '../../images/icon-gray.svg';
import companyIcon from '../../images/five-elements-logo-gray.svg';
import blogIcon from '../../images/blog-gray.svg';
import docsIcon from '../../images/docs-gray.svg';
import twitterIcon from '../../images/twitter-gray.svg';
import discordIcon from '../../images/discord-gray.svg';

export default function Layout() {
  const { autoLoginWallet } = useWallet();
  const location = useLocation();

  useEvents();
  useBackgroundWaves();

  useEffect(() => autoLoginWallet(), []);

  return (
    <>
      <span className='hidden md:block ocean'>
        <span id='wave1' className='wave' />
        <span id='wave2' className='wave2' />
      </span>

      <div className='container pb-5 relative z-10'>
        <CreatePM />
        <LoadingModal />

        <WalletHeader />

        <Navbar currentPage={location.pathname.substring(1)} />

        <Outlet />

        <Footer
          className='hidden lg:block'
          textColor='primary'
          orbitIcon={orbitIcon}
          companyIcon={companyIcon}
          twitterIcon={twitterIcon}
          discordIcon={discordIcon}
          docsIcon={docsIcon}
          blogIcon={blogIcon}
        />
        <WalletFooter />
      </div>
    </>
  );
}
