/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSharedState } from '../../../context/store';
import { Pool, TickMath } from '@uniswap/v3-sdk';
import { Token } from '@uniswap/sdk-core';
import JSBI from 'jsbi';

import {
  getPriceByTick,
  getClosestAvailableTick,
  getTokenAmountsFromInput,
  getSuggestedUpperTick,
  getSuggestedLowerTick,
  parseUnnecessaryZeros,
  decimalsDiff,
} from '../../../utils/utils';

//component for the input box for the tick range of the position to be created (lower and upper bounds)
export default function BoundsSection({
  positionData,
  setPositionData,
  lastAmountChanged = null,
  useSingleToken = false,
  setTickData,
  tickData,
  setNeedToChangeChart,
  tokenInWhichToShowPrices,
  handleChangeTick,
}) {
  const [{ chain_id, selected_pool }] = useSharedState();
  const TICK_SPACING_WIDTH = 25;

  // Change ticks to the selected range, and update the amounts accordingly
  // "shouldResetAmounts" is a boolean indicating whether to set the token amounts to 0 or scale
  // them to the selected range and is used to avoid amounts update errors while changing selected_pool
  const selectSuggestedRange = (shouldResetAmounts = true) => {
    if (!selected_pool) return;
    setNeedToChangeChart(true);

    const tickLowerSuggested = getSuggestedLowerTick(selected_pool.tick, selected_pool.feeTier, TICK_SPACING_WIDTH);
    const tickUpperSuggested = getSuggestedUpperTick(selected_pool.tick, selected_pool.feeTier, TICK_SPACING_WIDTH);
    const priceLowerSuggested = getPriceByTick(
      tokenInWhichToShowPrices === 'token0' ? tickLowerSuggested : tickUpperSuggested,
      tokenInWhichToShowPrices === 'token0',
      decimalsDiff(selected_pool, tokenInWhichToShowPrices === 'token0'),
    ).toPrecision(5);
    const priceUpperSuggested = getPriceByTick(
      tokenInWhichToShowPrices === 'token0' ? tickUpperSuggested : tickLowerSuggested,
      tokenInWhichToShowPrices === 'token0',
      decimalsDiff(selected_pool, tokenInWhichToShowPrices === 'token0'),
    ).toPrecision(5);
    const updatedPositionData = { ...positionData, tickLower: tickLowerSuggested, tickUpper: tickUpperSuggested };

    // Also update suggested token amounts, only if this function is called by the user,
    // and not when changing the selected pool or doing automatic updates
    // and only if using both pool tokens to mint (for instance not if zapping)
    if (!useSingleToken) {
      if (shouldResetAmounts) {
        var amount0Suggested = getOtherTokenAmount(
          positionData.amount1Desired,
          false,
          tickLowerSuggested,
          tickUpperSuggested,
        );
        var amount1Suggested = getOtherTokenAmount(
          positionData.amount0Desired,
          true,
          tickLowerSuggested,
          tickUpperSuggested,
        );

        updatedPositionData.amount0Desired = parseUnnecessaryZeros(amount0Suggested);
        updatedPositionData.amount1Desired = parseUnnecessaryZeros(amount1Suggested);
      } else {
        updatedPositionData.amount0Desired = 0;
        updatedPositionData.amount1Desired = 0;
      }
    }

    setTickData({
      tickLower: priceLowerSuggested,
      tickUpper: priceUpperSuggested,
    });
    setPositionData(updatedPositionData);
  };

  useEffect(() => selectSuggestedRange(false), [selected_pool]);
  useEffect(() => selectSuggestedRange(false), [tokenInWhichToShowPrices]);

  // Given the amount of a token of the pool, return the amount of the other one in the pool
  // "amount" is the amount of the input token
  // "zeroForOne" is a flag to indicate whether to calculate the amount from token0 to token1 or vice versa
  const getOtherTokenAmount = (amount, zeroForOne, tickLower, tickUpper) => {
    if (!amount) return;

    let { feeTier, tick, token0Decimals, token1Decimals } = selected_pool;

    if (isNaN(tickLower)) tickLower = getSuggestedLowerTick(Number(tick), feeTier, TICK_SPACING_WIDTH);
    if (isNaN(tickUpper)) tickUpper = getSuggestedUpperTick(Number(tick), feeTier, TICK_SPACING_WIDTH);

    const currentSqrt = TickMath.getSqrtRatioAtTick(Number(tick));
    const tokenDecimals = zeroForOne ? token0Decimals : token1Decimals;
    const tokenA = new Token(chain_id, selected_pool.token0Address, Number(selected_pool.token0Decimals));
    const tokenB = new Token(chain_id, selected_pool.token1Address, Number(selected_pool.token1Decimals));
    const pool = new Pool(tokenA, tokenB, Number(feeTier) * 10000, currentSqrt, JSBI.BigInt(0), Number(tick), []);

    const tokens = getTokenAmountsFromInput(amount, tokenDecimals, zeroForOne, tick, tickLower, tickUpper, pool);

    return zeroForOne
      ? (tokens.amount1 / Math.pow(10, token1Decimals)).toFixed(token1Decimals)
      : (tokens.amount0 / Math.pow(10, token0Decimals)).toFixed(token0Decimals);
  };

  return (
    <>
      <div className='grid grid-cols-2 gap-4'>
        <BoundInputBox
          tickData={tickData}
          boundType='Lower'
          handleChangeTick={handleChangeTick}
          selected_pool={selected_pool}
          setNeedToChangeChart={setNeedToChangeChart}
          tokenInWhichToShowPrices={tokenInWhichToShowPrices}
          positionData={positionData}
          setPositionData={setPositionData}
        />
        <BoundInputBox
          tickData={tickData}
          boundType='Upper'
          handleChangeTick={handleChangeTick}
          selected_pool={selected_pool}
          setNeedToChangeChart={setNeedToChangeChart}
          tokenInWhichToShowPrices={tokenInWhichToShowPrices}
          positionData={positionData}
          setPositionData={setPositionData}
        />
      </div>
      <div className='grid grid-cols-2 gap-4 mt-1'>
        <div></div>
        <button
          className={
            'uppercase text-lg azeret mt-2 ml-auto mr-0 text-white bg-first hover:opacity-90 rounded-xl px-9 py-2'
          }
          onClick={() => {
            setNeedToChangeChart(true);
            selectSuggestedRange(true);
          }}
        >
          Suggested range
        </button>
      </div>
    </>
  );
}

const BoundInputBox = ({
  tickData,
  boundType,
  handleChangeTick,
  selected_pool,
  setNeedToChangeChart,
  tokenInWhichToShowPrices,
}) => {
  const boundToChange = boundType === 'Lower' ? 'tickLower' : 'tickUpper';

  const updateBound = (mul) => {
    const priceInTick = getClosestAvailableTick(
      tokenInWhichToShowPrices === 'token0' ? tickData[boundToChange] : 1 / tickData[boundToChange],
      selected_pool.feeTier,
      decimalsDiff(selected_pool),
    );

    handleChangeTick(
      getPriceByTick(
        tokenInWhichToShowPrices === 'token0'
          ? priceInTick + mul * selected_pool.feeTier * 200
          : priceInTick - mul * selected_pool.feeTier * 200,
        tokenInWhichToShowPrices === 'token0',
        decimalsDiff(selected_pool, tokenInWhichToShowPrices === 'token0'),
      ).toPrecision(5),
      boundType,
    );
  };

  return (
    <div className='grid grid-cols-12 gap-2 background-light-gray relative rounded-xl mt-3 border-primary border-2'>
      <img
        className='col-span-3 self-center w-7 justify-self-center hover:cursor-pointer'
        src={require('../../../images/minus-button.svg').default}
        alt='minus button'
        onClick={() => {
          setNeedToChangeChart(true);
          updateBound(-1);
        }}
      />
      <div className='col-span-6 text-center my-1'>
        <p className='text-sm azeret-md text-gray'>{boundType}</p>
        <input
          type='text'
          value={tickData?.[boundToChange] || ''}
          onChange={(e) => {
            setNeedToChangeChart(true);
            handleChangeTick(e.target.value, boundType);
          }}
          className='primary text-lg azeret-md text-center hidden-input my-1 w-full'
        />
        <p className='text-gray text-xs azeret-md'>
          {tokenInWhichToShowPrices === 'token0'
            ? selected_pool?.token1?.toUpperCase()
            : selected_pool?.token0?.toUpperCase()}{' '}
          per&nbsp;
          {tokenInWhichToShowPrices === 'token0'
            ? selected_pool?.token0?.toUpperCase()
            : selected_pool?.token1?.toUpperCase()}
        </p>
      </div>
      <img
        className='col-span-3 self-center w-7 justify-self-center hover:cursor-pointer'
        src={require('../../../images/plus-button.svg').default}
        alt='plus button'
        onClick={() => {
          setNeedToChangeChart(true);
          updateBound(1);
        }}
      />
    </div>
  );
};
