import vault_connector from '../../images/vault_connector.svg';
import vault_mobile from '../../images/vaultmobile.png';

export default function HowItWorks() {
  return (
    <div className='lg:pt-24 md:pt-24 pt-4'>
      <div className='md:grid md:grid-cols-12 lg:gap-20 items-center'>
        <div className='md:col-span-1 lg:col-span-1 mb-8 md:mb-0 text-center md:text-left'></div>
        <div className='md:col-span-10 lg:col-span-10 mb-8 md:mb-0 text-center md:text-left max-w-6xl'>
          <h3 className='primary lg:text-7xl text-5xl mb-2 work-sans-bold text-center'>How it works</h3>
        </div>
        <div className='md:col-span-1 lg:col-span-1 mb-8 md:mb-0 text-center md:text-left'></div>
      </div>
      {/* green text boxes */}
      <div className='d-none d-lg-block'>
        <div className='md:grid md:grid-cols-12 lg:gap-2 items-center mb-6'>
          <div className='md:col-span-4 lg:col-span-4 text-center'>
            <div className='text-box bg-primary'>
              <h4 className='secondary text-2xl text-center font-bold work-sans-bold'>Custom strategies</h4>
            </div>
          </div>
          <div className='md:col-span-2 lg:col-span-2 text-center md:text-left max-w-6xl'>{/* Empty space */}</div>
          <div className='md:col-span-6 lg:col-span-6 text-center md:text-left'>
            <div className='text-box bg-primary'>
              <h4 className='secondary text-2xl text-center font-bold work-sans-bold'>Smart vault</h4>
            </div>
          </div>
        </div>
      </div>
      <div className='d-none d-lg-block md:mb-12 lg:mb-24'>
        <div className='md:grid md:grid-cols-12 lg:gap-0'>
          <div className='md:col-span-4 lg:col-span-4 '>
            {/* White boxes */}
            <div className='grid grid-rows-3 grid-flow-col gap-4'>
              <div className='text-box-white'>
                <h4 className='text-lg text-center azeret'>
                  Collect fees and <br></br>reinvest
                </h4>
              </div>
              <div className='text-box-white flex items-center justify-content-center'>
                <h4 className='text-lg text-center azeret'>Rebalance options</h4>
              </div>
              <div className='text-box-white'>
                <h4 className='text-lg text-center azeret'>
                  Employ idle liquidity <br></br>in other protocols
                </h4>
              </div>
            </div>
          </div>
          {/* problem with image width */}
          <div className='md:col-span-8 lg:col-span-8 relative right-3 vault'>
            <img src={vault_connector} alt='vault' className='absolute' />
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className='md:grid md:grid-cols-12 lg:gap-2 items-center mb-6 d-lg-none d-block'>
        <div className='md:col-span-6 lg:col-span-6 col-span-12 lg:mb-0 md:mb-0 mb-4 text-center md:text-left'>
          <div className='text-box bg-primary'>
            <h4 className='secondary text-2xl text-center font-bold work-sans-bold'>Smart vault</h4>
          </div>
        </div>
        <div className='md:col-span-8 lg:col-span-8 relative mb-6 shadow rounded-3xl'>
          <img src={vault_mobile} alt='vault'></img>
        </div>
      </div>
      <div className='md:grid md:grid-cols-12 lg:gap-0 d-lg-none d-block'>
        <div className='md:col-span-4 lg:col-span-4 text-center mb-7'>
          <div className='text-box bg-primary'>
            <h4 className='secondary text-2xl text-center font-bold work-sans-bold'>Custom strategies</h4>
          </div>
        </div>
        <div className='md:col-span-4 lg:col-span-4 '>
          {/* White boxes */}
          <div className='grid grid-rows-4 grid-flow-col gap-4'>
            <div className='text-box-white'>
              <h4 className='text-xl text-center azeret'>
                Collect fees and <br></br>reinvest
              </h4>
            </div>
            <div className='text-box-white flex items-center justify-content-center'>
              <h4 className='text-xl text-center azeret'>Rebalance options</h4>
            </div>
            <div className='text-box-white'>
              <h4 className='text-xl text-center azeret'>
                Employ idle liquidity <br></br>in other protocols
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
