import { useState } from 'react';
import RegularSection from './RegularSection';
import ZapSection from './ZapSection';

export default function DepositBox() {
  const [showRegular, setShowRegular] = useState(true);

  return (
    <div className='xs:mt-8 md:mt-0 md:ml-3'>
      <div className='deposit-box xs:ml-0 md:ml-4'>
        <div className='grid grid-cols-2 bg-primary rounded-t'>
          <div className={` rounded-t ` + (showRegular ? ' bg-primary ' : 'bg-white')}>
            <button
              className={
                `purple-btn font-medium work-sans-bold text-lg no-opacity w-full no-outline ` +
                (showRegular ? 'rounded-t primary bg-white rounded-less' : 'rounded-t-r')
              }
              onClick={() => {
                setShowRegular(true);
              }}
            >
              REGULAR
            </button>
          </div>
          <div className='bg-white rounded-t '>
            <button
              className={
                `purple-btn font-medium work-sans-bold text-lg w-full  no-opacity rounded-t rounded-t-l no-outline ` +
                (!showRegular ? ' primary bg-white' : '')
              }
              onClick={() => {
                setShowRegular(false);
              }}
            >
              ZAP
            </button>
          </div>
        </div>
        {showRegular ? <RegularSection /> : <ZapSection />}
      </div>
    </div>
  );
}
